import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
        faChevronRight,
        faChevronLeft, 
        faTh,
        faUserFriends,
        // faChartLine,
        // faCalendar,
        faCode,
        faCog,
        faPlus,
        faCheck,
        faTimes,
        faTrashAlt,
        faEdit,
        faCopy,
        faInfoCircle,
        faBolt,
        faMicrochip,
        faStar,
        faStarHalf,
        faExclamationTriangle,
        faCaretDown,
        faCaretUp,
        faUser,
        faSearch,
        faStream,
        faEllipsisV,
        faSignal,
        // faWifi,
        faLocationArrow,
        faMapPin,
        faMapMarker,
        faMapMarkerAlt,
        faProjectDiagram,
        faSmile,
        faArchive,
        faUserLock,
        faKey,
        faHandPointer,
        faStopwatch,
        faRedo,
        faRecycle,
        faHistory,
        // faClock,
        faLink,
        faCircle,
        faPaperPlane,
        faReply,
        faPause,
        faGlobe,
        faSquare,
        faComment,
        faUsers,
        faBars,
        faSortAmountUp,
        faSortAmountDown,
        faFlag,
        // faBug,
        faPlay,
        faStepForward,
        faStop,
        // faSlidersH,
        faVolumeDown,
        faList,
        faWindowMaximize,
        faWindowRestore,
        faImage,
        faVideo,
        faMusic,
        faPalette,
        // faUpload,
        faFile,
        faPowerOff,
        faTachometerAlt,
        faEye,
        faEyeSlash,
        faIdBadge,
        faBoxes,
        faGamepad,
        faChess,
        faGift,

        faArrowAltCircleRight,
        faArrowAltCircleDown,
        faArrowAltCircleUp,
        faArrowAltCircleLeft,
        faAngleDoubleDown,
        faAngleDoubleLeft,
        faAngleDoubleRight,
        faAngleDoubleUp,
        faSquareFull,
} from "@fortawesome/fontawesome-free-solid";
import {
    // faFloppyDisk,
    faCalendar, faCaretSquareRight,
    // faEye,
    // faClock,
    // faHandshake,
    faLightbulb,
    faPaste,
} from "@fortawesome/free-regular-svg-icons";
// import {faMapMarkerAlt as faMapMArkerAlt_2 } from "@fortawesome/free-regular-svg-icons";
import {
    faFloppyDisk,
    faSliders,
    faTrashCan,
    faPenToSquare,
    faUserGroup,
    faChartLine,
    faBug,
    // faCalendarDays,
    faBoxArchive,
    faClock,
    faWifi,
    faUpload,
    faMagnifyingGlass,
    faHandshake,
    faTachometer,
    faCloud,
    faFilm,
    faArrowCircleRight,
    faPlug,
    faGlasses,
    faDesktop,
    faDesktopAlt,
    faCogs,
    faBinoculars,
    faRobot,
    faCircleExclamation,
    faBullhorn,
    faComments,
    faCommentDots,
    faHashtag,
    faDisplay,
    faHeartbeat,
    faMagic,
    faQuestion,
    faQuestionCircle,
    faQ,
    faLightbulb as faLightbulb_solid,
    faTrophy,
    faFolderPlus,
    faTrashRestore,
    faIdCardClip,
    faPollH,
    faBalanceScaleRight,
    faFont,
    faFileCsv,
    faShare,
    faColumns,
    faEllipsisVertical,
    // faGamepad,
    faFileDownload,
    faFileExcel,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import {
    faSalesforce
} from "@fortawesome/free-brands-svg-icons"

const ICONS = {
    chevronRight: faChevronRight,
    chevronLeft: faChevronLeft,
    chevronDown: faCaretDown,
    chevronUp: faCaretUp,
    
    campaignsNav: faTh,
    usersNav: faUserFriends,
    analyticsNav: faChartLine,
    scheduleNav: faCalendar,
    
    cog: faCog,
    plus: faPlus,
    check: faCheck,
    checkX: faTimes,
    closeX: faTimes,
    oneUser: faUser,
    multiUsers: faUserGroup,
    userAuth: faKey,

    trash: faTrashCan,
    untrash: faTrashRestore,
    undelete: faHistory,
    edit: faPenToSquare,
    copy: faCopy,
    moreInfo: faInfoCircle,

    idCard:faIdCardClip,
    lightingBolt: faBolt,
    lightningBolt: faBolt,
    kokoAcIcon: faCode,
    clientAcIcon: faUser,
    notSetAcIcon: faExclamationTriangle,

    search: faSearch,

    liveStream: faWifi,
    menu: faEllipsisV,

    region: faMapMarkerAlt,
    status: faProjectDiagram,
    client: faStar,

    star: faStar,
    halfStar: faStarHalf,
    circle: faCircle,
    archived: faBoxArchive,

    manual: faHandPointer,
    automatic: faStopwatch,
    interval: faRedo,
    response: faReply,

    clock: faClock,
    link: faLink,

    paperPlane: faPaperPlane,
    pause: faPause,
    play: faPlay,
    stop: faStop,
    skip: faStepForward,

    busy: faSquare,
    chatHistory: faComment,

    gridView: faTh,
    listView: faBars,

    controls: faSliders, // faSlidersH,
    volumeDown: faVolumeDown,

    winMaximize: faWindowMaximize,
    winRestore: faWindowRestore,
    priority: faSortAmountUp,
    bug: faBug,

    list: faList,

    image: faImage,
    video: faVideo,
    music: faMusic,
    animated: faPalette,

    projectPlan: faProjectDiagram,

    upload: faUpload,
    save: faFloppyDisk,
    localStorage: faBoxes,
    magnify: faMagnifyingGlass,

    error: faExclamationTriangle,
    powerOff: faPowerOff,

    handshake: faHandshake,
    salesforce: faSalesforce,

    film: faFilm,

    arrowCircleRight: faArrowCircleRight,
    plug: faPlug,

    preview: faEye,
    bot: faRobot,
    disruptorDefault: faCircleExclamation,
    bullhorn: faBullhorn,
    eye: faEye,
    eyeSlash: faEyeSlash,

    magic:faMagic,
    comments: faComments,
    commentDots: faCommentDots,
    hashtag: faHashtag,

    quizNav: faLightbulb_solid,

    quizColourBlockIcon: faLightbulb,
    leaderboardColourBlockIcon: faTrophy,

    folderPlus: faFolderPlus,

    quizPillIcon: faQuestion,
    pollPillIcon: faPollH,
    tugOfWarPillIcon: faBalanceScaleRight,

    font: faFont,

    importCsv: faFileCsv,

    share: faShare,
    columns: faColumns,

    paste: faPaste,
    snippet: faCode,
    customisationGroup: faEllipsisVertical,

    arcade: faGamepad,
    arcadeAttemps: faChess,
    arcadePrizes: faGift,

    fileDownload: faFileDownload,
    fileExcel: faFileExcel,
    arrowDown: faArrowDown,

    videoCamera: faVideoCamera,
    recordIcon: faCircle,
    recordStopIcon: faStop,

    butIcon_arrowRight1: faArrowAltCircleRight,
    butIcon_arrowRight2: faAngleDoubleRight,
    butIcon_arrowRight3: faArrowRight,
    butIcon_arrowDown1: faArrowAltCircleDown,
    butIcon_arrowDown2: faAngleDoubleDown,
    butIcon_arrowDown3: faArrowDown,
    butIcon_arrowUp1: faArrowAltCircleUp,
    butIcon_arrowUp2: faAngleDoubleUp,
    butIcon_arrowUp3: faArrowUp,
    butIcon_arrowLeft1: faArrowAltCircleLeft,
    butIcon_arrowLeft2: faAngleDoubleLeft,
    butIcon_arrowLeft3: faArrowLeft,
    butIcon_squareFull: faSquareFull,
};

const ICON_STYLES = { 
    nav: {width: '1.8rem', margin: 0}, // {height: '1.2rem', margin: '0 0.4rem',},
    nav_quiz: {height: '1.2rem', margin: '0.2rem 0.4rem 0 0.4rem'}, // {height: '1.2rem', margin: '0 0.4rem',},
    roundPanelButton: {height: '1.2rem',},
    roundPanelButtonSmall: {height: '1rem',},
    roundPanelButtonSmallWithIcon: {height: '1rem', marginRight:"5px"},
    roundFormControl: {height: '1rem',},
    campaignLargeBtn: {height: '5rem', margin: '2rem'},
    campaignWideBtn: {height: '3rem', margin: '1rem'},
    listIcon: {height: '1.4rem'},
    settingsEdit: {height: '1.2rem'},
    streamData: {width: '1.2rem', margin: '0 0.4rem'},
    acPillIcon: {height: '1rem'},
    searchBox: {height: '1rem'},
    standard: {height: '1rem'},
    standardW: {width: '1.5rem'},
    campaignPanelTop: {height: '1.8rem'},
    campaignPanelTopList: {height: '1.4rem'},
    campaignPanelInfoPill: {height: '0.8rem'},
    botControlButton: {height: '1.8rem'},
    streamControlOptions: {height: '1.8rem'},
    chatToggleControlButton: {height: '1.8rem'},
    streamControlConnecting: {height: '1.2rem'},
    streamStatHeader: {height: '3rem'},
    forceStopPopIcon: {height: '3rem'},
    controlMoreInfo: {height: '1.4rem'},
    headedPanelCollapse: {height: '1.4rem', cursor: 'pointer'},
    analyticsColourBlockIcon: {height: '2rem', marginLeft: '0.5rem'},
    analyticsKey: {height: '1.2rem'},
    analyticsClock: {height: '1.8rem'},
    wizardProgressIcon: {height: '1.6rem'},
    uploaderBtn: {height: '1.6rem'},
    customiseTopOptions: {height: '1.6rem'},
    bullet: {height: '0.5rem', marginLeft: '1.5rem', marginRight: '0.5rem', marginBottom: '0.2rem'},
    ratingStar: {height: '2rem'},
    headerBarButtonIcon: {height: '2rem', cursor: 'pointer'},
    squareAddButton: {height: '2rem', cursor: 'pointer'},
};

/**
 * A helper to output our icons. 
 * This way if we decide to switch from using FontAwesome we don't need to trawl through the project looking for FontAwesome,
 * we can just change it here.
 * 
 * ADDING ICONS: Just import them above and add to the ICONS constant above!
 * ADDING STYLES: I was thinking of having smallIcon, medIcon etc. but realised this probably wouldn't work, so when with in-lined more specific styles stored
 *                  in the ICON_STYLES const. You might find these are pretty re-useable...
 * 
 * @param {string | object} icon Either a reference to the icon from the ICONS const above or a string matching a property in that constant
 * @param {string | object} style As above, but should come from / match the ICON_STYLES const above
 * @param {string} className If you really want to use a css class you can pass it in here
 * @returns The jsx code for an icon
 */
const GetIcon = (icon = 'cog', style = 'nav', className = '', onClick = null) => {
    if (typeof icon === 'string') {
        icon = ICONS[icon];
    }
    if (typeof style === 'string') {
        style = ICON_STYLES[style];
    }

    if (icon && style) {
        return (<FontAwesomeIcon style={style} icon={icon} className={className} onClick={onClick} />);
    } else {
        return null;
    }
}

// You should reference everything through this object...
export const IconJsxer = {
    ICONS: ICONS,
    ICON_STYLES: ICON_STYLES,
    GetIcon: GetIcon,
}
