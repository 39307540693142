import "./CampaignOverview.scss";
import { assets } from "../../assets/assets";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CampaignContext } from "../../contexts/CampaignContext";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import {getProfilePicRoute, getCampaignFeatureVarsRoute, updateCampaignFeatureVarsRoute, getCampaignOverviewRoute, deleteCampaignUserRoute, updateCampaignRoute, updatedFeatureListRoute, addHashTagRoute, deleteHashTagRoute, updateHashTagsRoute, getStreamCustomisationVarsRoute, getCampaignCustomisationVarsRoute, getQuizzesByCampaignRoute, removeQuizRoute, getCampaignAnalyticDataRoute, setCampaignArchivedRoute, removeStreamChannelRoute } from "../../api/routes";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { IconJsxer } from "../../helpers/IconHelper";
import { getLongDateString } from "../../helpers/Dates";
import { getRegionDataFromDbString, getRegionsDropDownListData } from "../../helpers/Regions";
import ListedCampaignUser from "../CreateCampaign/ListedCampaignUser";
import FormField from "../../components/forms/FormField/FormField";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
import TabbedInviteUser from "../../components/InviteUserPopup/TabbedInviteUser";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import { findFeature, findUnlinkedChainedFeatures, getCampaignTierFormData } from "../../helpers/CampaignsHelper";
import AddStreamBot from "./AddStreamBot";
import BotsOverview from "./BotsOverview";
import EditStreamBot from "./EditStreamBot";
import ConfirmRemoveBot from "./ConfirmRemoveBot";
import DuplicateStreamBot from "./DuplicateStreamBot";
import EditCampaignOwner from "./EditCampaignOwner";
import AddStream from "./AddStream";
import AddStreamChannel from "./AddStreamChannel";
import StreamsOverview from "./StreamsOverview";
import ConfirmRemoveStream from "./ConfirmRemoveStream";
import ConfirmRemoveQuiz from "./ConfirmRemoveQuiz";

import EditStream from "./EditStream";
import TabbedPanel from "../../components/TabbedPanel/TabbedPanel";
import CampaignDetailsTab from "./OverviewTabs/CampaignDetailsTab";
import CampaignContentTab from "./OverviewTabs/CampaignContentTab";
import CampaignStreamsTab from "./OverviewTabs/CampaignStreamsTab";
import CampaignAnalyticsTab from "./OverviewTabs/CampaignAnalyticsTab";
import AddDisruptor from "./AddDisruptor";
import AddHashtag from "./AddHashtag";

import { POST_MESSAGE_IN_TYPES, POST_MESSAGE_OUT_TYPES } from "../../helpers/FeatureControlsHelper";
import FeaturePreview, { postMessageToPreviewFrame } from "../../components/FeaturePreview/FeaturePreview";
import ConfirmRemoveFeature from "./ConfirmRemoveFeature";
import AddFeature from "./AddFeature";
import SessionAnalytics from "./SessionAnalytics";
import EditStackingOrder from "./StackingOrder/EditStackingOrder";

import { AuthContext } from "../../contexts/AuthContext";
import ArcadeDataPopup from "../../components/ArcadeData/ArcadeDataPopup";
import RestrictedCustomisationPopup from "../CustomiseFeature/RestrictedCustomisationPopup";
import FunctionUnavailableInPreviewPopup from "../CustomiseFeature/themes/FunctionUnavailableInPreviewPopup";

const CampaignOverview = () => {
    const { id, tab } = useParams();
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [quizzes, setQuizzes] = useState([]);
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState(tab === "analytics" ? 3 : tab === "details" ? 1 : tab === "streams" ? 2 : 0);
    const [isProducerList, setIsProducerList] = useState(true)
    const [acTypeToAdd, setAcTypeToAdd] = useState('');
    const [showInviteUserPanel, setShowInviteUserPanel] = useState(null);
    const [authContext, setAuthContext] = useContext(AuthContext);
    const [expandedSections, setExpandedSections] = useState(
        {
            details: true,
            feature: true,
            streams: true,
            analytics: true,
            disrupters: true,
            bot: true,
            producers: true,
            client: true,
            singular: true,
        }
    );

    const [gotLiveStream, setGotLiveStream] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editedCampaignData, setEditedCampaignData] = useState(null);
    const [editedQuizzesData, setEditedQuizzesData] = useState(null);
    const openEditMode = (e) => {
        e.preventDefault();
        setEditedCampaignData({ ...campaignContext.campaignData });
        setEditedQuizzesData(quizzes);
        setEditMode(true);
    }
    const closeEditMode = (e) => {
        e.preventDefault();
        setEditMode(false);
    }
    const changeEditedCampaignData = (field, value) => {
        editedCampaignData[field] = value;
        setEditedCampaignData({ ...editedCampaignData });
    }
    // const changeEditedQuizData = (index, field, value) => {
    //     editedQuizzesData[index][field] = value;
    //     setEditedQuizzesData(editedQuizzesData);
    // }

    const userFilters = {
        producer: [ACCOUNT_TYPES.creative.dbString, ACCOUNT_TYPES.producer.dbString, ACCOUNT_TYPES.twitchSuperUser.dbString, ACCOUNT_TYPES.koko.dbString],
        client: [ACCOUNT_TYPES.streamer.dbString, ACCOUNT_TYPES.agency.dbString, ACCOUNT_TYPES.client.dbString],
    }
    const [userFilter, setUserFilter] = useState(userFilters.client);

    const addClientContacts = (e) => {
        console.log('Add client contacts');
        setAcTypeToAdd(ACCOUNT_TYPES.client);
        setUserFilter(userFilters.client);
        setIsProducerList(false);
        setShowInviteUserPanel(true);
    }
    const removeClientContact = (e, val, i) => {
        console.log('Remove client contact: ', val, i);

        axios
            .post(deleteCampaignUserRoute, { campaignID: id, userID: val._id, withCredentials: true })
            .then((response) => {
                if (response.data.campaignData) {
                    setCampaignContext((oldValues) => {
                        return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
                    })
                }
            })
            .catch(
                console.log('ERROR')
            )


    }
    const addProducers = (e) => {
        console.log('Add producers');
        setAcTypeToAdd(ACCOUNT_TYPES.producer);
        setUserFilter(userFilters.producer);
        setIsProducerList(true);
        setShowInviteUserPanel(true);
    }
    const removeProducer = (e, val, i) => {
        console.log('Remove producer: ', val, i);
        axios
            .post(deleteCampaignUserRoute, { campaignID: id, userID: val._id, withCredentials: true })
            .then((response) => {
                if (response.data.campaignData) {
                    setCampaignContext((oldValues) => {
                        return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
                    })
                }
            })
    }

    const addQuiz = (e) => {
        console.log("Add quiz");
        navigate(TOP_LEVEL_NAV_ROUTES.EDIT_QUIZ, {params: {}});
    }
    const [editingQuiz, setEditingQuiz] = useState();
    const [showConfirmRemoveQuiz, setShowConfirmRemoveQuiz] = useState(false);
    const openConfirmRemoveQuiz = (quiz) => {
        console.log("Remove quiz: ",quiz);
        setEditingQuiz(quiz);
        setShowConfirmRemoveQuiz(true);
    }
    const removeQuiz = (quiz, callback) => {
        axios
            .post(removeQuizRoute, { campaignID: id, quizID: editingQuiz._id, withCredentials: true })
            .then((response) => {
                console.log("remove respone = ",response);
                if(response.data.removedQuiz) {
                    setQuizzes((oldQuizzes) => oldQuizzes.filter(it => it._id != response.data.removedQuiz.quiz._id))
                    callback(true);
                }
            })
    }
    const closeConfirmRemoveQuiz = () => {
        setEditingQuiz(null);
        setShowConfirmRemoveQuiz(false);
    }

    const closeInviteUserPanel = (e) => {
        setShowInviteUserPanel(false);
    }

    const campaignTierData = getCampaignTierFormData();

    const regionListData = getRegionsDropDownListData();
    let originalRegionData = null;
    const [currentRegion, setCurrentRegion] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toggleExpandedSection = (section) => {
        expandedSections[section] = !expandedSections[section];
        setExpandedSections({ ...expandedSections });
    }

    const [showEditCampaignOwner, setShowEditCampaignOwner] = useState(false);
    const editCampaignOwner = (e) => {
        setShowEditCampaignOwner(true);
    }
    const closeEditCampaignOwner = (e) => {
        setShowEditCampaignOwner(false);
    }
    const changeCampaignOwner = (e, newOwner) => {
        console.log('Edit campaign owner: ', newOwner);
        changeEditedCampaignData('campaignOwnerAccount', newOwner);
    }

    // Click function ready to be implemented
    const saveEditedCampaignData = (e, repullDataAfter = true, showToast = true) => {
        console.log('Save edited campaign data: ', editedCampaignData);
        if (!isSubmitting) {
            setIsSubmitting(true);
            axios
                .post(updateCampaignRoute, {
                    campaignID: id,
                    campaignName: editedCampaignData.campaignName,
                    campaignTier: editedCampaignData.campaignTier,
                    selectedRegion: currentRegion.value,
                    clientName: editedCampaignData.client,
                    adBugCopy: editedCampaignData.adBugCopy,
                    campaignOwner: editedCampaignData.campaignOwnerAccount ? editedCampaignData.campaignOwnerAccount._id : null,
                    sfLink: editedCampaignData.sfLink,
                    sfNumber: editedCampaignData.sfNumber,
                    chains: editedCampaignData.chains,
                    // features: editedCampaignData.features,
                    draft: editedCampaignData.draft,
                })
                .then(function (response) {
                    setIsSubmitting(false);
                    if (showToast) {
                        toast.success("Campaign Updated!");
                    }
                    setEditMode(false);
                    
                    // Some small changes mean we can ignore any returned data which is incomplete anyway and requires a complete repull.
                    // Big changes like adding in a new feature require the repull...
                    if (repullDataAfter) {
                        setCampaignContext((oldValues) => {
                            return { ...oldValues, initialising: repullDataAfter, campaignData: response.data.campaignData }
                        })

                        pullInCampaignData();
                    }
                })
                .catch(function (error) {
                    setIsSubmitting(false);
                    toast.error('Error - ' + error.response.data.message);
                    // console.log(error.response)
                });
        }
    }

    const toggleArchived = (e) => {
        e.preventDefault();
        const newValue = editedCampaignData.archived === true ? false : true;
        axios
            .post(setCampaignArchivedRoute, {
                campaignID: id,
                setArchived: newValue,
            }).then(function (response) {
                toast.success(`Campaign ${newValue ? 'Archived' : 'Unarchived'}`);
                setEditedCampaignData({ ...editedCampaignData, archived: newValue });
                setCampaignContext((oldValues) => {
                    return { ...oldValues, initialising: false, campaignData: editedCampaignData }
                })
            })
            .catch(function (error) {
                toast.error('Error - ' + error.response.data.message);
                // console.log(error.response)
            });
    }

    const [gotAnalytics, setGotAnalytics] = useState(tab === 'analytics');
    const checkCampaignAnalyticData = () => {
        axios
        .get(getCampaignAnalyticDataRoute, {
            params: { campaignID: id },
            withCredentials:true
        })
        .then(function (response) {
            console.log('Check for analytics: ', response);
            if (response.data.analyticsData) {
                setGotAnalytics(true);
            } else {
                setGotAnalytics(false);
            }
        })
        .catch(function (error) {
            setGotAnalytics(false);
        });
    }

    const pullInCampaignData = () => {
        if (id) {
            axios
                .get(getCampaignOverviewRoute, { 
                    params: { campaignID: id }, 
                    withCredentials: true 
                })
                .then((response) => {
                    console.log('Got campaign data: ', JSON.parse(JSON.stringify(response.data.campaignData)));
                    setCampaignContext((oldValues) => {
                        return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
                    })

                    // console.log('Looking for current region: ', regionListData, response.data.campaignData);
                    for (let i = 0; i < regionListData.length; i++) {
                        if (regionListData[i].id === response.data.campaignData.region) {
                            originalRegionData = regionListData[i];
                            originalRegionData.index = i;
                            // console.log('Found region: ', originalRegionData);
                            break;
                        }
                    }
                    setCurrentRegion(originalRegionData);

                    // if (editMode) {
                        setEditedCampaignData({ ...response.data.campaignData });
                        checkCampaignAnalyticData();
                        const hashTagsUpdated = sanitiseHashtagData(response.data.campaignData);
                        if (!hashTagsUpdated) {
                            verifyStacking(response.data.campaignData);
                            verifyChains(response.data.campaignData);
                        }
                    // }

                    // Check for live streams
                    if (response.data.campaignData.streams) {
                        for (let i = 0; i < response.data.campaignData.streams.length; i++) {
                            if (response.data.campaignData.streams[i].status === 'live') {
                                setGotLiveStream(true);
                                break;
                            }
                        }
                    }
                })
                .catch((error) => {
                    toast.error("Error fetching campaign data");
                    navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
                });
        } else {
            navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
        }
    }

    const pullInCampaignQuizzes = () => {
        if(id) {
            axios
                .get(getQuizzesByCampaignRoute, {
                    params: {campaignID: id}, 
                    withCredentials: true
                })
                .then(response => {
                    setQuizzes(response.data.quizzes);
                    setEditedQuizzesData(response.data.quizzes);
                })
                .catch((err) => {
                    toast.error("Error fetching campaign quizzes");
                })
        } else {
            navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
        }
    }

    useEffect(() => {
        setCampaignContext((oldValues) => {
            return { ...oldValues, initialising: true }
        });
        pullInCampaignData();
        pullInCampaignQuizzes();
        /*
        if (campaignContext.campaignData && campaignContext.campaignData._id === id) {
            // we already have our campaign data available!
            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: false }
            });
            setTimeout(pullInCampaignData, 20);
        } else {
            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: true }
            });
            setTimeout(pullInCampaignData, 20);
        }*/
    }, []);

    const [editingBot, setEditingBot] = useState();
    const [showEditBot, setShowEditBot] = useState(false);
    const editBot = (bot) => {
        setEditingBot(bot);
        setShowEditBot(true);
    }
    const closeEditBot = () => {
        setShowEditBot(false);
    }
    const [showRemoveBot, setShowRemoveBot] = useState(false);
    const removeBot = (bot) => {
        setEditingBot(bot);
        setShowRemoveBot(true);
    }
    const closeRemoveBot = () => {
        setShowRemoveBot(false);
    }
    const [showDuplicateBot, setShowDuplicateBot] = useState(false);
    const duplicateBot = (bot) => {
        setEditingBot(bot);
        setShowDuplicateBot(true);
    }
    const closeDuplicateBot = () => {
        setShowDuplicateBot(false);
    }
    const [showAddStreamBot, setShowAddStreamBot] = useState(false);

    
    const addStreamBot = (e) => {
        setShowAddStreamBot(true);
    }
    const closeAddStreamBot = (e) => {
        setShowAddStreamBot(false);
    }

    const [showAddStreamChannel, setShowAddStreamChannel] = useState(false);
    const [addChannelStreamID, setAddChannelStreamID] = useState(null);
    const addStreamChannel = (e, streamID) => {
        setAddChannelStreamID(streamID)
        setShowAddStreamChannel(true);
    }
    const closeAddStreamChannel = (e) => {
        setShowAddStreamChannel(false);
    }

    const [showAddStream, setShowAddStream] = useState(false);
    const addStream = (e) => {
        setShowAddStream(true);
    }
    const closeAddStream = (e) => {
        setShowAddStream(false);
    }
    const [editingStream, setEditingStream] = useState();
    const [showEditStream, setShowEditStream] = useState(false);
    const editStream = (stream) => {
        setEditingStream(stream);
        setShowEditStream(true);
    }
    const closeEditStream = () => {
        setShowEditStream(false);
    }
    const [showRemoveStream, setShowRemoveStream] = useState(false);
    const removeStream = (stream) => {
        setEditingStream(stream);
        setShowRemoveStream(true);
    }
    const closeRemoveStream = () => {
        setShowRemoveStream(false);
    }

    const [showAddDistruptor, setShowAddDistruptor] = useState(false);
    const [addingIntro, setAddingIntro] = useState(false);
    const [addingOutro, setAddingOutro] = useState(false);
    const addDisruptorToStartOfChain = useRef(null);
    const addDisruptor = (e = null, intro = false, outro = false, chain = null) => {
        setAddingIntro(intro);
        setAddingOutro(outro);
        currentChainRef.current = chain;
        setShowAddDistruptor(true);
    };
    const closeAddDisruptor = () => {setShowAddDistruptor(false)};

    const setIntroAsOutro = (toggleOn, chain) => {
        if (chain.length > 1 && chain[0] !== chain[1]) {
            if (toggleOn) {
                chain[2] = chain[0];
            } else {
                if (chain.length === 3) {
                    chain.pop();
                }
            }
            saveEditedCampaignData();
        }
    }

    const changeChainOrder = (chainItem, chain, dir = 1) => {
        if ( 
            (dir === 1 && chain.indexOf(chainItem) < chain.length - 1)
            || (dir === -1 && chain.indexOf(chainItem) > 0)
        ) {
            const index = chain.indexOf(chainItem);
            const indexSwap = index + dir;
            const itemSwappingWith = chain[indexSwap];
            chain[index] = itemSwappingWith;
            chain[indexSwap] = chainItem;
            saveEditedCampaignData(null, false, false);
        }
    }

    const duplicatedChainedFeature = (featureId, chain) => {
        const index = chain.indexOf(featureId);
        if (index > -1) {
            chain.splice(index, 0, featureId);
            saveEditedCampaignData(null, false, false);
        }
    }

    const removeFeatureFromChain = (featureId, featureData, index, chain) => {
        if (chain[index] === featureId) {
            chain.splice(index, 1);
            // if (chain.indexOf(featureId) === -1) {
                // We need to completely remove this feature
                // removeSelectedFeatureFromList(featureData);
            // } else {
                // Just assume it's a dupe! It might be in use elsewhere - we will cleanup later.
                saveEditedCampaignData(null, false, false);
            // }
        }
    }

    const removeChannelFromStream = (streamID, channel) => {
       console.log('REMOVE CHANNEL FROM STREAM', streamID, channel)

/*
       campaignID
       streamID,
       twitchHandle
*/

        axios
        .post(removeStreamChannelRoute, {
            campaignID: id,
            streamID: streamID,
            twitchHandle: channel
        })
        .then(function (response) {
            toast.success("Channel "+channel+" Removed From Stream");
            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: true, campaignData: response.data.campaignData }
            })

            pullInCampaignData();
        })
      
    }

    const getProfilePic = (twitchHandle) => {
        console.log('GFet Profile Pic for:', twitchHandle)
        axios
        .get(getProfilePicRoute, {
            params: {    twitchHandle: twitchHandle },
            withCredentials:true
          
        })
    }

    const [showAddFeature, setShowAddFeature] = useState(false);
    const addFeature = () => {setShowAddFeature(true)};
    const closeAddFeature = () => {setShowAddFeature(false)};

    const lastFeatureAddedLabelRef = useRef(null);
    const lastFeatureIdRef = useRef(null);

    const addSelectedFeatureToList = (newFeatureData, completedCallback, addToStartOfChain = null) => {
        console.log('Add feature to list: ', newFeatureData, editedCampaignData.features);
        lastFeatureAddedLabelRef.current = newFeatureData.contentLabel;
        lastFeatureIdRef.current = newFeatureData.feature._id;
        newFeatureData.feature.commands = [];
        const featureList = editedCampaignData.features;
        featureList.push(newFeatureData);
        changeEditedCampaignData('features', featureList);
        addDisruptorToStartOfChain.current = addToStartOfChain;

        const featureData = [];
        for (let i = 0; i < editedCampaignData.features.length; i++) {
            const chained = (i === editedCampaignData.features.length - 1) && (addingIntro || addingOutro);
            const oneFeature = {
                _id: editedCampaignData.features[i]._id, 
                feature: editedCampaignData.features[i].feature._id, 
                contentLabel: editedCampaignData.features[i].contentLabel || editedCampaignData.features[i].feature.featureName, 
                varKey:editedCampaignData.features[i].varKey,
                countIn:editedCampaignData.features[i].countIn || false,
                countInTime:editedCampaignData.features[i].countInTime || 10,
                isChained:chained || editedCampaignData.features[i].isChained || false,
            };
            featureData.push(oneFeature);
        }
        console.log('Sending update: ', featureData);
        
        axios
        .post(updatedFeatureListRoute, {
            campaignID: id,
            features: featureData,
            addingFeature: true,
        })
        .then(function (response) {
            toast.success("Campaign Updated!");

            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: true }
            });
            pullInCampaignData();
            getFeatureVars();

            if (completedCallback) {
                completedCallback(true);
            }
        })
        .catch(function (error) {
            featureList.pop();
            changeEditedCampaignData('features', featureList);

            if (completedCallback) {
                completedCallback(false);
            }
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const saveFeatureUpdates = () => {
        const featureData = [];
        for (let i = 0; i < editedCampaignData.features.length; i++) {
            const oneFeature = {
                _id: editedCampaignData.features[i]._id,
                feature: editedCampaignData.features[i].feature._id,
                contentLabel: editedCampaignData.features[i].contentLabel || editedCampaignData.features[i].feature.featureName,
                varKey: editedCampaignData.features[i].varKey,
                countIn: editedCampaignData.features[i].countIn || false,
                countInTime: editedCampaignData.features[i].countInTime || 10,
                isChained: editedCampaignData.features[i].isChained || false,
                stackingOrder: editedCampaignData.features[i].stackingOrder,
            };
            featureData.push(oneFeature);
        }

        changeEditedCampaignData('features', editedCampaignData.features);

        axios
            .post(updatedFeatureListRoute, {
                campaignID: id,
                features: featureData,
            }) 
            .then(function (response) {
                toast.success("Changes Saved!");
            })
            .catch(function (error) {
                toast.error('Error - ' + error.response.data.message);
            })
    }

    const [showConfirmRemoveFeature, setShowConfirmRemoveFeature] = useState(false);
    const removeFeature = (featureToRemoveData, chain = null) => {
        currentFeatureRef.current = featureToRemoveData;
        currentChainRef.current = chain;
        setCurrentFeature(featureToRemoveData);
        setShowConfirmRemoveFeature(true);
    }
    const closeConfirmRemoveFeature = () => {
        setShowConfirmRemoveFeature(false);
    }

    const removeSelectedFeatureFromList = (featureToRemoveData, completedCallback) => {
        console.log('Remove feature from list: ', featureToRemoveData, editedCampaignData.features);

        const featureList = editedCampaignData.features;
        // featureList.push(featureToRemoveData);
        const featureIndex = featureList.indexOf(featureToRemoveData);
        if (featureIndex >= 0) {
            featureList.splice(featureIndex, 1);
        }
        changeEditedCampaignData('features', featureList);

        const featureData = [];
        for (let i = 0; i < editedCampaignData.features.length; i++) {
            const oneFeature = {
                _id: editedCampaignData.features[i]._id, 
                feature: editedCampaignData.features[i].feature._id, 
                contentLabel: editedCampaignData.features[i].contentLabel || editedCampaignData.features[i].feature.featureName, 
                varKey:editedCampaignData.features[i].varKey,
                countIn: editedCampaignData.features[i].countIn,
                countInTime: editedCampaignData.features[i].countInTime,
                isChained: editedCampaignData.features[i].isChained || false,
            };
            featureData.push(oneFeature);
        }
        console.log('Sending update: ', featureData);
        
        axios
        .post(updatedFeatureListRoute, {
            campaignID: id,
            features: featureData,
        })
        .then(function (response) {
            if (completedCallback) {
                completedCallback(true);
            } else {
                toast.success("Campaign Updated!");
            }
        })
        .catch(function (error) {
            featureList.push(featureToRemoveData);
            changeEditedCampaignData('features', featureList);

            if (completedCallback) {
                completedCallback(false);
            }
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const removeUnlinkedChainedFeatures = (campaignData, customisationData) => {
        if (addingIntro || addingOutro) {
            return;
        }
        const featuresToRemoveIdList = findUnlinkedChainedFeatures(campaignData, customisationData.current);

        if (featuresToRemoveIdList.length > 0) {
            const featureList = campaignData.features;
            for (let i = featureList.length - 1; i >= 0; i--) {
                if (featuresToRemoveIdList.indexOf(featureList[i]._id) >= 0) {
                    featureList.splice(i, 1);
                }
            }
            changeEditedCampaignData('features', featureList);

            const featureData = [];
            for (let i = 0; i < campaignData.features.length; i++) {
                const oneFeature = {
                    _id: campaignData.features[i]._id, 
                    feature: campaignData.features[i].feature._id, 
                    contentLabel: campaignData.features[i].contentLabel || campaignData.features[i].feature.featureName, 
                    varKey:campaignData.features[i].varKey,
                    countIn: campaignData.features[i].countIn,
                    countInTime: campaignData.features[i].countInTime,
                    isChained: campaignData.features[i].isChained || false,
                };
                featureData.push(oneFeature);
            }
            console.log('Sending update (removed features): ', featuresToRemoveIdList, featureData);
            
            axios
            .post(updatedFeatureListRoute, {
                campaignID: id,
                features: featureData,
            })
            .then(function (response) {
                // We'll just do this silently!
            })
            .catch(function (error) {
                // console.log(error.response)
            });
        }
    }

    const findFeatureChain = (featureId) => {
        if (editedCampaignData && editedCampaignData.chains) {
            for (let i = 0; i < editedCampaignData.chains.length; i++) {
                if (editedCampaignData.chains[i].indexOf(featureId) >= 0) {
                    return editedCampaignData.chains[i];
                }
            }
        }
        return null;
    }

    const [showEditStacking, setShowEditStacking] = useState(false);
    const editStacking = () => {setShowEditStacking(true)};
    const closeEditStacking = () => {setShowEditStacking(false)};

    const [showAddHashtag, setShowAddHashtag] = useState(false);
    const editingFeature = useRef(null);
    const addHashtag = (feature) => {editingFeature.current = feature; setShowAddHashtag(true)};
    const closeAddHashtag = () => {setShowAddHashtag(false)};
    const saveHashtag = (hashtagData, completedCallback) => {
        console.log('Save hashtag: ', hashtagData);

        const commandList = editedCampaignData.hashtagCommands;
        let found = false;
        for (let i = 0; i < commandList.length; i++) {
            if (commandList[i].command === hashtagData.command && commandList[i].featureID === hashtagData.featureID && commandList[i].param === hashtagData.param) {
                commandList[i].hashtags.push(hashtagData.hashtag);
                found = true;
                break;
            }
        }
        if (!found) {
            const newCommand = {
                command: hashtagData.command,
                featureID: hashtagData.featureID,
                hashtags: [hashtagData.hashtag],
                param: hashtagData.param,
                commandParam: hashtagData.commandParam,
            }
            commandList.push(newCommand);
        }
        changeEditedCampaignData('hashtagCommands', commandList);
        
        axios
        .post(addHashTagRoute, hashtagData)
        .then(function (response) {
            toast.success("Campaign Updated!");
            if (completedCallback) {
                completedCallback(true);
            }
        })
        .catch(function (error) {
            for (let i = 0; i < commandList.length; i++) {
                if (commandList[i].command === hashtagData.command && commandList[i].featureID === hashtagData.featureID && commandList[i].param === hashtagData.param) {
                    commandList[i].hashtags.pop();
                    break;
                }
            }
            changeEditedCampaignData('hashtagCommands', commandList);

            if (completedCallback) {
                completedCallback(false);
            }
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }
    const removeHashtag = (removeData) => {
        console.log('Remove hashtag: ', removeData);
        axios
        .post(deleteHashTagRoute, removeData)
        .then(function (response) {
            toast.success("Campaign Updated!");
            const commandList = editedCampaignData.hashtagCommands;
            for (let i = 0; i < commandList.length; i++) {
                if (commandList[i].command === removeData.command && commandList[i].featureID === removeData.featureID && commandList[i].param === removeData.param) {
                    console.log('Remove hashtag from command: ', commandList[i], commandList[i].hashtags, removeData.hashtag, commandList[i].hashtags.indexOf(removeData.hashtag));
                    while (commandList[i].hashtags.indexOf(removeData.hashtag) >= 0) {
                        commandList[i].hashtags.splice(commandList[i].hashtags.indexOf(removeData.hashtag), 1);
                    }
                }
            }
            changeEditedCampaignData('hashtagCommands', commandList);
        })
        .catch(function (error) {
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    // Make sure our hashtag data makes sense, eg. we are going to have a cooldown, not just a hashtag string + feature ids might need to be corrected.
    // We should remove this at some point, ie when we are happy all the data is correct.
    // It probably shouldn't find it's way into the live build..?
    const sanitiseHashtagData = (campaignData) => {
        const commandList = campaignData.hashtagCommands;
        console.log('Sanitise hashtag data: ', commandList);
        let changesHaveBeenMade = false;
        const hashtagIndexsForRemoval = [];
        for (let i = 0; i < commandList.length; i++) {
            for (let j = 0; j < commandList[i].hashtags.length; j++) {
                // Fix old string based hashtags...
                if (typeof commandList[i].hashtags[j] === 'string') {
                    console.log('Old hashtag data: ', commandList[i].hashtags[j]);
                    // Old data!
                    const newData = {hashtag: commandList[i].hashtags[j], cooldown: 0};
                    commandList[i].hashtags[j] = newData;
                    changesHaveBeenMade = true;
                }
                // Fix feature ids... Can result in some features losing hashtags, but this is only a temporary measure.
                for (let k = campaignData.features.length - 1; k >= 0; k--) {
                    if (campaignData.features[k].feature._id === commandList[i].featureID) {
                        console.log('Old id: ', commandList[i].featureID);
                        // This is intended for this feature, but has the wrong id!
                        // Let's correct that!
                        commandList[i].featureID = campaignData.features[k]._id;
                        changesHaveBeenMade = true;
                        // This is first come first served + it won't match any others now, so let's break!
                        break;
                    }
                }
                // Now check if this hashtag is related to an old feature (ie. one that has been removed)
                let found = false;
                for (let k = 0; k < campaignData.features.length; k++) {
                    if (campaignData.features[k]._id === commandList[i].featureID) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    // This feature has been removed!
                    console.log('Feature removed, hashtag needs removed: ', commandList[i].featureID, commandList[i]);
                    hashtagIndexsForRemoval.push(i);
                }
            }
        }

        // Now let's check if we have any hashtags that are copies of other hashtags...
        for (let i = commandList.length - 1; i >= 0; i--) {
            if (hashtagIndexsForRemoval.indexOf(i) === -1) {
                const jsonedHashtagData = JSON.stringify(commandList[i].hashtags);
                for (let j = i - 1; j >= 0; j--) {
                    if (commandList[i].command === commandList[j].command && commandList[i].featureID === commandList[j].featureID && jsonedHashtagData === JSON.stringify(commandList[j].hashtags)) {
                        console.log('Duplicate hashtag: ', commandList[i]);
                        hashtagIndexsForRemoval.push(i);
                        break;
                    }
                }
            }
        }

        console.log('--- HASHTAGS TO REMOVE: ', hashtagIndexsForRemoval);

        // Splice out these hashtags...
        for (let i = hashtagIndexsForRemoval.length - 1; i >= 0; i--) {
            commandList.splice(hashtagIndexsForRemoval[i], 1);
            changesHaveBeenMade = true;
        }

        if (changesHaveBeenMade) {
            // Send the updated data back to the server!
            console.log('Changes made to hashtag data! ', commandList);
            axios
                .post(updateHashTagsRoute, {
                    campaignID: id,
                    hashtagCommands: commandList,
                })
                .then(function (response) {
                    pullInCampaignData();
                })
                .catch(function (error) {
                    toast.error('Error - ' + error.response.data.message);
                    // console.log(error.response)
                });
        }
        return changesHaveBeenMade;
    }

    // Verify our chains, as above, we should remove this at some point as I'm only adding it now while I add in the chains functionality.
    // Newly created campaign should have valid data already..?
    const attemptsToUpdateChainCount = useRef(0);
    const MAX_ATTEMPTS_TO_UPDATE_CHAIN = 5;
    const verifyChains = (campaignData) => {
        let featureChainIndex = 0;
        let verifiedFeatureChains = [];
        let madeChanges = false;
        let featuresUpdated = false;
        for (let i = 0; i < campaignData.features.length; i++) {
            if (campaignData.features[i].feature.featureType === 'feature') {
                // We have a feature!
                // Do we have a chain for it?
                let featureChain = null;
                for (let j = 0; j < campaignData.chains.length; j++) {
                    if (campaignData.chains[j].indexOf(campaignData.features[i]._id) >= 0) {
                        // We have a chain!
                        if (featureChain === null) {
                            featureChain = campaignData.chains[j];
                            if (j !== featureChainIndex) {
                                madeChanges = true;
                            }
                            verifiedFeatureChains[featureChainIndex] = featureChain;
                        } else {
                            // We can't have the same feature in more than one chain!
                            madeChanges = true;
                        }
                    }
                }
                if (featureChain === null) {
                    madeChanges = true;
                    featureChain = [campaignData.features[i]._id];
                    verifiedFeatureChains[featureChainIndex] = featureChain;
                }
                if (typeof campaignData.features[i].countIn === 'undefined') {
                    campaignData.features[i].countIn = false;
                    campaignData.features[i].countInTime = 10;
                    featuresUpdated = true;
                    madeChanges = true;
                }

                // Are we adding an intro?
                if (addingIntro && addDisruptorToStartOfChain.current === true) {
                    // is this the feature we want to add an intro to?
                    if (currentChainRef.current && currentChainRef.current.indexOf(campaignData.features[i]._id) >= 0) {
                        // Yes, this is the right one!
                        // is the first id in the chain the latest added feature?
                        /* if (featureChain[0] !== campaignData.features[campaignData.features.length - 1]._id) {
                            // No - we need to update this chain!
                            // In theory we should not have an intro in this chain at this point, but just in case something has gone wrong, I'm going to check...
                            if (featureChain[0] !== campaignData.features[i]._id) {
                                featureChain[0] = campaignData.features[campaignData.features.length - 1]._id;
                            } else {
                                // we need to reconstruct our chain!
                                let newChain = [
                                    campaignData.features[campaignData.features.length - 1]._id,
                                    campaignData.features[i]._id
                                ]
                                if (featureChain[featureChain.length - 1] !== campaignData.features[i]._id) {
                                    newChain.push(featureChain[featureChain.length - 1]);
                                }
                                featureChain = newChain;
                                verifiedFeatureChains[featureChainIndex] = featureChain;
                            }

                            madeChanges = true;
                        } */
                        if (campaignData.features[campaignData.features.length - 1].feature.featureType !== 'feature' && campaignData.features[campaignData.features.length - 1].feature.contentLabel === lastFeatureAddedLabelRef.current && campaignData.features[campaignData.features.length - 1].feature._id === lastFeatureIdRef.current) {
                            featureChain.splice(0, 0, campaignData.features[campaignData.features.length - 1]._id);
                            madeChanges = true;
                        }
                    }
                }

                // Are we adding an outro?
                // This should be easier...?
                if (addingOutro && addDisruptorToStartOfChain.current === false) {
                    // is this the feature we want to add an outro to?
                    if (currentChainRef.current && currentChainRef.current.indexOf(campaignData.features[i]._id) >= 0) {
                        // Yes, this is the right one!
                        // is the last id in the chain the latest added feature?
                        /* if (featureChain[featureChain.length - 1] !== campaignData.features[campaignData.features.length - 1]._id) {
                            // No - we need to update this chain!
                            // In theory we should not have an outro in this chain at this point, but just in case something has gone wrong, I'm going to check...
                            if (featureChain[featureChain.length - 1] !== campaignData.features[i]._id) {
                                featureChain[featureChain.length - 1] = campaignData.features[campaignData.features.length - 1]._id;
                            } else {
                                // we push the new id onto our chain!
                                featureChain.push(campaignData.features[campaignData.features.length - 1]._id);
                            }

                            madeChanges = true;
                        } */
                        if (campaignData.features[campaignData.features.length - 1].feature.featureType !== 'feature' && campaignData.features[campaignData.features.length - 1].contentLabel === lastFeatureAddedLabelRef.current && campaignData.features[campaignData.features.length - 1].feature._id === lastFeatureIdRef.current) {
                            featureChain.push(campaignData.features[campaignData.features.length - 1]._id);
                            madeChanges = true;
                        }
                    }
                }

                if (((addingIntro || addingOutro) && (madeChanges || attemptsToUpdateChainCount.current >= MAX_ATTEMPTS_TO_UPDATE_CHAIN)) || (!addingIntro && !addingOutro)) {
                    currentChainRef.current = null;
                    setAddingIntro(false);
                    setAddingOutro(false);
                    addDisruptorToStartOfChain.current = null;
                    attemptsToUpdateChainCount.current = 0;
                } else {
                    attemptsToUpdateChainCount.current++;
                    console.log('Failed to add feature to chain - data not ready yet!', attemptsToUpdateChainCount.current);
                    setTimeout(() => {
                        pullInCampaignData();
                    }, 200);
                    return;
                }
            }
        }
        // Make sure our chains only contain valid feature Ids
        for (let i = 0; i < verifiedFeatureChains.length; i++) {
            for (let j = verifiedFeatureChains[i].length - 1; j >= 0; j--) {
                let found = false;
                for (let k = 0; k < campaignData.features.length; k++) {
                    if (campaignData.features[k]._id === verifiedFeatureChains[i][j]) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    verifiedFeatureChains[i].splice(j, 1);
                    madeChanges = true;
                }
            }
        }
        if (verifiedFeatureChains.length !== campaignData.chains.length) {
            madeChanges = true;
        }
        if (madeChanges) {
            campaignData.chains = verifiedFeatureChains;
            // Send the update up to the server!
            console.log('Saving chain / count in changes!', campaignData);
            // changeEditedCampaignData('chains', campaignData.chains);
            // if (featuresUpdated) {
                // changeEditedCampaignData('features', campaignData.features);
            // }
            // saveEditedCampaignData(null);

            // secretly save our changes!
            axios
            .post(updateCampaignRoute, {
                campaignID: id,
                campaignName: campaignData.campaignName,
                campaignTier: campaignData.campaignTier,
                selectedRegion: campaignData.region,
                clientName: campaignData.client,
                adBugCopy: campaignData.adBugCopy,
                campaignOwner: campaignData.campaignOwnerAccount? campaignData.campaignOwnerAccount._id : null,
                sfLink: campaignData.sfLink,
                sfNumber: campaignData.sfNumber,
                chains: campaignData.chains,
            })

            if (featuresUpdated) {
                const featureData = [];
                for (let i = 0; i < campaignData.features.length; i++) {
                    const oneFeature = {
                        _id: campaignData.features[i]._id, 
                        feature: campaignData.features[i].feature._id, 
                        contentLabel: campaignData.features[i].contentLabel || campaignData.features[i].feature.featureName, 
                        varKey:campaignData.features[i].varKey,
                        countIn:campaignData.features[i].countIn || false,
                        countInTime:campaignData.features[i].countInTime || 10,
                    };
                    featureData.push(oneFeature);
                }
                console.log('Sending updated feature data: ', featureData);
                
                axios
                .post(updatedFeatureListRoute, {
                    campaignID: id,
                    features: featureData,
                })
            }

            setEditedCampaignData({ ...campaignData });
        }

        // We'll reset these just in case - at least if something has gone wrong it won't have a knock on effect
        setAddingIntro(false);
        setAddingOutro(false);
        currentChainRef.current = null;

        return madeChanges;
    }

    const addExistingFeatureToChain = (campaignData, featureId, addToStart) => {
        for (let i = 0; i < campaignData.features.length; i++) {
            if (campaignData.features[i].feature.featureType === 'feature') {
                // We have a feature!
                // Do we have a chain for it?
                let featureChain = null;
                for (let j = 0; j < campaignData.chains.length; j++) {
                    if (campaignData.chains[j].indexOf(campaignData.features[i]._id) >= 0) {
                        // We have a chain!
                        if (featureChain === null) {
                            featureChain = campaignData.chains[j];
                        }
                    }
                }

                if (featureChain) {
                    if (addToStart) {
                        featureChain.splice(0, 0, featureId);
                    } else {
                        featureChain.push(featureId);
                    }

                    axios
                    .post(updateCampaignRoute, {
                        campaignID: id,
                        campaignName: campaignData.campaignName,
                        campaignTier: campaignData.campaignTier,
                        selectedRegion: campaignData.region,
                        clientName: campaignData.client,
                        adBugCopy: campaignData.adBugCopy,
                        campaignOwner:campaignData.campaignOwnerAccount ? campaignData.campaignOwnerAccount._id : null,
                        sfLink: campaignData.sfLink,
                        sfNumber: campaignData.sfNumber,
                        chains: campaignData.chains,
                    })
                    .then(function (response) {
                        toast.success("Chain Updated!");
                        setEditedCampaignData({ ...campaignData });
                    })
                    .catch(function (error) {
                        toast.error('Error - ' + error.response.data.message);
                        featureChain.splice(featureChain.indexOf(featureId, 1));
                    });
                }
            }
        }
    }

    // Make sure all our features have a stacking order assigned.
    // Not worried about saving this until we make changes because I'll do the same check on the feature page.
    const verifyStacking = (campaignData) => {
        let highestStack = 0;
        for (let i = 0; i < campaignData.features.length; i++) {
            if (typeof campaignData.features[i].stackingOrder !== 'undefined' && campaignData.features[i].stackingOrder > highestStack) {
                highestStack = campaignData.features[i].stackingOrder;
            }
        }
        for (let i = 0; i < campaignData.features.length; i++) {
            if (typeof campaignData.features[i].stackingOrder === 'undefined') {
                highestStack++;
                campaignData.features[i].stackingOrder = highestStack;
            }
        }
    }

    const customisationVars = useRef(null);
    const [gotCustomisation, setGotCustomistation] = useState(false);
    const getCustomisationVars = () => {
        console.log('Get Customisation Vars');
        axios
        .get(getCampaignCustomisationVarsRoute, {
            params: { campaignID: id },
            withCredentials:true
        })
        .then(function (response) {
            if (response.data.customisationVars) {
                customisationVars.current = response.data.customisationVars;
                setGotCustomistation(true);
                console.log('Got customisationVars: ', customisationVars.current);
            }
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }

    const [featureVars, setFeatureVars] = useState({});
    const featureVarsRef = useRef(null);
    const getFeatureVars = () => {
        console.log('Get Campaign Feature Vars');
        axios
        .get(getCampaignFeatureVarsRoute, {
            params: { campaignID: id },
            withCredentials:true
        })
        .then(function (response) {
            console.log('CAMPAIGN STREAM VARS',response.data);
            if (response.data.streamVars) {
                featureVarsRef.current = response.data.streamVars;
                setFeatureVars(featureVarsRef.current);
            }
            getCustomisationVars();
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
            // console.log(error.response)
        });
    }
    const updateFeatureVars = (updatedVars, onComplete = null) => {
        axios
        .post(updateCampaignFeatureVarsRoute, {
            campaignID: id, 
            updatedVars: updatedVars,
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data);
            if (onComplete) {
                onComplete(response.data)
            }
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }
    useEffect(getFeatureVars, []);

    const [previewMode, setPreviewMode] = useState(false);
    const [previewPostFix, setPreviewPostFix] = useState('');
    const [currentFeature, setCurrentFeature] = useState(null);
    const currentFeatureRef = useRef(null);
    const currentChainRef = useRef(null);
    const editFeatureSettings = (f, postFix = "") => {
        currentFeatureRef.current = f;
        setPreviewPostFix(postFix);
        setCurrentFeature(f);
        setPreviewMode(true);
    }

    const reportFeatureVarsChanged = (onlyUpdateDisruptor = false) => {
        if (!onlyUpdateDisruptor) {
            updateFeatureVars(featureVarsRef.current.current);
        }
        console.log('Report vars to preview: ', featureVarsRef.current.current[currentFeatureRef.current._id]);
        postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.SETTINGS_VARS_RECEIVED, featureVarsRef.current.current[currentFeatureRef.current._id]);
    }

    const reportCustomisationVarsChanged = (updatedVars, featureID) => {

        postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.CUSTOMISATION_VARS_RECEIVED, updatedVars.current[featureID]);
         
     }

    /* const [showingRestrictedCustomisationPopup, setShowingRestrictedCustomisationPopup] = useState(false);
    const restrictedCustomisationFeatureId = useRef(null);
    const openRestrictedCustomisationPopup = (featureId) => {
        restrictedCustomisationFeatureId.current = featureId;
        setShowingRestrictedCustomisationPopup(true);
    }
    const closeRestrictedCustomisationPopup = () => {
        setShowingRestrictedCustomisationPopup(false);
    } */

    const [showingFunctionUnavilablePopup, setShowingFunctionUnavilablePopup] = useState(false);
    const openFunctionUnavilablePopup = () => {
        setShowingFunctionUnavilablePopup(true);
    }
    const closeFunctionUnavilablePopup = () => {
        setShowingFunctionUnavilablePopup(false);
    }

    // Special functions that can be triggered by buttons in the feature controls in place of the normal feature commands...
    const BUTTON_FUNCTIONS = {
        reportFeatureVarsChanged: (featureId, param) => reportFeatureVarsChanged(),
        startLivePoll: (featureId, param) => {
            reportFeatureVarsChanged();
            setTimeout(() => {
                sendFeatureCommand(null, 'START_POLL', featureId, param);
                // postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: 'START_POLL', extraData: {username:'CampignStudio', hashtag:'Manual Press', commandParam: param} })
            }, 100);
        },
        createNewLivePoll: (featureId, param) => {
            // openRestrictedCustomisationPopup(featureId);
            openFunctionUnavilablePopup();
        },
        editLiveTextPresets: (featureId, param) => {
            // openRestrictedCustomisationPopup(featureId);
            openFunctionUnavilablePopup();
        },
        selectLiveTextPreset: (featureId, param) => {
            const _featureVars = featureVarsRef.current.current[featureId];
            const _customisationVars = customisationVars.current.current[featureId];
            console.log('Select live text preset: ', featureId, _featureVars, _customisationVars);

            const presetTextIndex = Number(_featureVars.liveTextControls.presetText);
            const presetTextData = _customisationVars.setup.presetText.presets[presetTextIndex];
            if (presetTextData) {
                _featureVars.liveTextControls.presetName = presetTextData.presetName;
                _featureVars.liveTextControls.headerText = presetTextData.headerText;
                _featureVars.liveTextControls.mainContent = presetTextData.mainContent;
                featureVarsRef.current = {current: {...featureVarsRef.current.current, [featureId]: _featureVars}};
                setFeatureVars(featureVarsRef.current);
                reportFeatureVarsChanged();
            }
        }
    }
    const sendFeatureCommand = (e, commandString, featureId, param = null) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }
        if (BUTTON_FUNCTIONS[commandString]) {
            console.log('Running button function: ', commandString, featureId, param);
            BUTTON_FUNCTIONS[commandString](featureId, param);
        } else {
            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: commandString, extraData: {username:'CampignStudio', hashtag:'Manual Press', commandParam: param} });
        }
    }

    const [showingTooltip, setShowingTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [tooltipPos, setTooltipPos] = useState({left: 0, bottom: 0});
    const tooltipTimeout = useRef(null);
    const showTooltip = (text, e) => {
        setTooltipPosToMouse(e, true);
        setTooltipText(text);
        if (text.length > 0 && !showingTooltip && tooltipTimeout.current === null) {
            tooltipTimeout.current = setTimeout(() => setShowingTooltip(true), 300);
        }
    }
    const hideTooltip = (e) => {
        clearTimeout(tooltipTimeout.current);
        tooltipTimeout.current = null;
        setShowingTooltip(false);
    }
    const setTooltipPosToMouse = (e, force = false) => {
        if (showingTooltip || force) {
            const toolTipPos = {
                left: e.pageX + 'px',
                bottom: (window.innerHeight - e.pageY + 10) + 'px'
            }
            setTooltipPos(toolTipPos);
        }
    }

    const [analyticsObj, setAnalyticsObj] = useState({});
    
    const [topLevelAnalytics, setTopLevelAnalytics] = useState({});
    const [viewingSessionAnalytics, setViewingSessionAnalytics] = useState(false);
    const [sessionDataToView, setSessionDataToView] = useState(null);
    const [sessionTwitchHandle, setSessionTwitchHandle] = useState(null);

    const openSessionAnalytics = (sessionData, twitchHandle, overallAnalytics) => {
        setAnalyticsObj(sessionData);
        setSessionDataToView(sessionData);
        setViewingSessionAnalytics(true);
        setSessionTwitchHandle(twitchHandle);
        setTopLevelAnalytics(overallAnalytics)
    }
    const closeSessionAnalytics = () => {
        setAnalyticsObj({});
        setViewingSessionAnalytics(false);
    }


    useEffect(
        () => {
            if (editedCampaignData && customisationVars.current) {
                removeUnlinkedChainedFeatures(editedCampaignData, customisationVars.current);
            }
        },
        [editedCampaignData, customisationVars.current]
    )

    const getTabs = () => {
        const tabData = [
            {
                label: 'Content',
            },
            {
                label: 'Campaign Details',
            },
      
            {
                label: 'Streams',
            }
        ];
        if ((gotAnalytics || tab === 'analytics') && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level) {
            tabData.push({
                label: 'Analytics',
            });
        }
        return tabData;
    }

    const [showArcadeDataPopup, setShowArcadeDataPopup] = useState(false);
    const openArcadeDataPopup = () => {
        setShowArcadeDataPopup(true);
    }
    const closeArcadeDataPopup = () => {
        setShowArcadeDataPopup(false);
    }

    return (
        <div className={`content-page-container${editMode ? ' edit-mode-bg' : ''}`}>
            <div className="content-page-content-container">
                <div className="content-page-main-content">
                    {campaignContext.initialising &&
                        <div className="initialising-container">
                            {/* We can probably do something better with this later (or maybe just leave it blank?) */}
                            <img className="glitch" alt="Twitch Glitch" src={assets.LoadingFeature} />
                        </div>
                    }
                    {!campaignContext.initialising && gotCustomisation &&
                        <>
                            <div className="campaigns-overview-container">
                                <div className={`campaign-overview-panel heading${editMode ? ' edit-mode-heading' : ''}`}>
                               
                               {(!editMode || authContext.userData.userLevel < ACCOUNT_TYPES.producer.level) &&
                                    <h2>{campaignContext.campaignData.campaignName}</h2>
                                }
                                {editMode && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                                    <><FormField className='editCampaignname' label="Campaign Name" type="text" id="editcamapignname" placeholder="Campaign Name" value={editedCampaignData.campaignName} setFunc={(val) => changeEditedCampaignData('campaignName', val)} /><h2> | Edit Mode</h2></>
                                }
                                    <div className="grow"></div>
                                    {!editMode && authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                                        <>
                                            {gotLiveStream &&
                                                <div className="warning-text">You have an active stream - editing is disabled!</div>
                                            }
                                            <div className={`round-button${gotLiveStream ? ' not-allowed' : ''}`} onClick={gotLiveStream ? null : openEditMode}>{IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                        </>
                                    }
                                    {editMode &&
                                        <div className="fl-row">
                                            {/* <h4>Edit Mode</h4> */}
                                            {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                                                <div className="round-button" onClick={toggleArchived}>{IconJsxer.GetIcon(editedCampaignData.archived ? IconJsxer.ICONS.untrash : IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                            }
                                            <div className="form-holder">
                                                <button className="submit-btn" onClick={saveEditedCampaignData}>Save Changes</button>
                                            </div>
                                            <div className="round-button" onClick={closeEditMode}>{IconJsxer.GetIcon(IconJsxer.ICONS.closeX, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                        </div>
                                    }
                                </div>

                                <div className="wide">
                                    <TabbedPanel
                                        tabs={
                                            getTabs()
                                        }
                                        tabWidth={gotAnalytics || tab === 'analytics' ? '' : '25%'}
                                        switchTabCallback={(tabId, tabData) => setCurrentTab(tabId)}
                                        extraPanelClass="co-tabbed-panel"
                                        initialTab={currentTab}
                                    >
                                        <>
                                            {currentTab === 1 &&
                                                <CampaignDetailsTab
                                                    _editMode={editMode}
                                                    _gotLiveStream={gotLiveStream}
                                                    _editedCampaignData={editedCampaignData}
                                                    _changeEditedCampaignData={changeEditedCampaignData}
                                                    _campaignTierData={campaignTierData}
                                                    _currentRegion={currentRegion}
                                                    _setCurrentRegion={setCurrentRegion}
                                                    _regionListData={regionListData}
                                                    _addProducers={addProducers}
                                                    _removeProducer={removeProducer}
                                                    _addClientContacts={addClientContacts}
                                                    _removeClientContact={removeClientContact}
                                                />
                                            }
                                            {currentTab === 0 &&
                                                <CampaignContentTab
                                                    _campaignId={id}
                                                    _editMode={editMode}
                                                    _gotLiveStream={gotLiveStream}
                                                    _editedCampaignData={editedCampaignData}
                                                    _editedQuizzesData={quizzes}
                                                    _addStreamBot={addStreamBot}
                                                    _editBot={editBot}
                                                    _removeBot={removeBot}
                                                    _duplicateBot={duplicateBot}
                                                    _addDisruptor={addDisruptor}
                                                    _addHashtag={addHashtag}
                                                    _removeHashtag={removeHashtag}
                                                    _removeQuiz={openConfirmRemoveQuiz}

                                                    _editFeatureSettings={editFeatureSettings}
                                                    _removeFeature={removeFeature}
                                                    _addFeature={addFeature}
                                                    _editStacking={editStacking}
                                                    _changeChainOrder={changeChainOrder}
                                                    _duplicatedChainedFeature={duplicatedChainedFeature}
                                                    _removeFeatureFromChain={removeFeatureFromChain}

                                                    _findFeatureChainFunc={findFeatureChain}
                                                    _saveFeatureUpdatesFunc={saveFeatureUpdates}
                                                    _setIntroAsOutro={setIntroAsOutro}
                                                    _customisationData={customisationVars}

                                                    _changeEditedCampaignData={changeEditedCampaignData}
                                                    _saveEditedCampaignData={saveEditedCampaignData}

                                                    _showTooltipFunc={showTooltip}
                                                    _hideTooltipFunc={hideTooltip}

                                                    _openArcadeDataPopup={openArcadeDataPopup}
                                                />
                                            }
                                            {currentTab === 2 &&
                                                <CampaignStreamsTab
                                                    _editMode={editMode}
                                                    _addStream={addStream}
                                                    _editStream={editStream}
                                                    _removeStream={removeStream}
                                                    _addStreamChannelFunc={addStreamChannel}
                                                    _removeStreamChannelFunc={removeChannelFromStream}
                                                    _getProfilePicFunc={getProfilePic}
                                                />
                                            }
                                            {currentTab === 3 &&
                                                <CampaignAnalyticsTab
                                                    _openSessionAnalytics={openSessionAnalytics}
                                                />
                                            }
                                        </>
                                    </TabbedPanel>
                                </div>

                            </div>

                            {/* <p>Raw campaign data:</p>
                            {Object.entries(campaignContext.campaignData).map(
                                ([k, v], i) => <p key={k}>{k + ': ' + v}</p>
                            )} */}
                        </>

                    }

                </div>
            </div>
            {showInviteUserPanel &&
                <TabbedInviteUser closePanelFunc={closeInviteUserPanel} campaignId={id} showAcTypeSelector={true} defaultType={acTypeToAdd} acTypeFilter={userFilter} isProducer={isProducerList} />
            }
            {showAddStreamBot &&
                <AddStreamBot closePanelFunc={closeAddStreamBot} campaignId={id} />
            }
            {showEditBot &&
                <EditStreamBot closePanelFunc={closeEditBot} campaignId={id} bot={editingBot} />
            }
            {showRemoveBot &&
                <ConfirmRemoveBot closePanelFunc={closeRemoveBot} campaignId={id} bot={editingBot} />
            }
            {showDuplicateBot &&
                <DuplicateStreamBot closePanelFunc={closeDuplicateBot} campaignId={id} bot={editingBot} />
            }
            {showEditCampaignOwner &&
                <EditCampaignOwner closePanelFunc={closeEditCampaignOwner} owner={campaignContext.campaignData.campaignOwnerAccount} newOwner={editedCampaignData.campaignOwnerAccount} acTypeFilter={userFilters.producer} changeCampaignOwnerFunc={changeCampaignOwner} />
            }

            {showAddStreamChannel &&
                    <AddStreamChannel
                        closePanelFunc={closeAddStreamChannel} 
                        streamID={addChannelStreamID}
                        campaignId={id} 
                    />
                }


            {showAddStream &&
                <AddStream 
                    closePanelFunc={closeAddStream} 
                    campaignId={id} 
                />
            }
            {showRemoveStream &&
                <ConfirmRemoveStream 
                    closePanelFunc={closeRemoveStream} 
                    campaignId={id} 
                    stream={editingStream} 
                />
            }
            {showEditStream &&
                <EditStream 
                    closePanelFunc={closeEditStream} 
                    campaignId={id} 
                    stream={editingStream} 
                />
            }
            {showAddDistruptor &&
                <AddDisruptor
                    closePanelFunc={closeAddDisruptor} 
                    campaignTier={campaignContext.campaignData.campaignTier}
                    campaignId={id} 
                    addSelectedFeatureToListFunc={addSelectedFeatureToList}
                    addExistingDisruptorToListFunc={(featureId, addToStart) => {addExistingFeatureToChain(campaignContext.campaignData, featureId, addToStart)}}
                    isIntro={addingIntro}
                    isOutro={addingOutro}
                    showSelectExistingOption={addingIntro || addingOutro}
                    campaignFeatures={campaignContext.campaignData.features}
                />
            }
            {showAddFeature &&
                <AddFeature
                    closePanelFunc={closeAddFeature} 
                    campaignId={id} 
                    addSelectedFeatureToListFunc={addSelectedFeatureToList}
                    
                />
            }
            {showAddHashtag &&
                <AddHashtag
                    closePanelFunc={closeAddHashtag} 
                    campaignId={id} 
                    saveHashtag={saveHashtag}
                    feature={editingFeature.current}
                    customisationVars={customisationVars}
                />
            }
            {}
            {showConfirmRemoveFeature &&
                <ConfirmRemoveFeature 
                    feature={currentFeature} 
                    chain={currentChainRef.current}

                    _editedCampaignData={editedCampaignData}
                    _changeEditedCampaignData={changeEditedCampaignData}
                    _saveEditedCampaignData={saveEditedCampaignData}

                    removeFeature={removeSelectedFeatureFromList} 
                    closePanelFunc={closeConfirmRemoveFeature} 
                />
            }
            { showConfirmRemoveQuiz && 
                <ConfirmRemoveQuiz quiz={editingQuiz} removeQuiz={removeQuiz} closePanelFunc={closeConfirmRemoveQuiz} />
            }
            {viewingSessionAnalytics &&
                <SessionAnalytics
                    closePanelFunc={closeSessionAnalytics}
                    _analyticsObj={topLevelAnalytics}
                    _analyticsData={analyticsObj}
                    _updateAnalyticsFunc={setAnalyticsObj}
                    _sessionData={sessionDataToView}
                    _streamData={{}}
                    _campaignData={campaignContext.campaignData}
                    _showTooltip={showTooltip}
                    _hideTooltip={hideTooltip}
                    _sessionTwitchHandle={sessionTwitchHandle}
                />
            }
            {previewMode &&
                <FeaturePreview
                    campaignId={id}

                    currentFeature={currentFeature}
                    titlePostFix={previewPostFix}
                    featureVars={featureVars}
                    customisationVars={customisationVars.current}
                    closeFunc={() => setPreviewMode(false)}

                    startControlsExpanded={currentFeature.feature.featureType === 'feature'}
                    startMaximized={true}
                    resizeable={false}

                    _reportFeatureVarsChanged={reportFeatureVarsChanged}
                    _reportCustomisationVarsChanged={reportCustomisationVarsChanged}

                    _sendFeatureCommandFunc={sendFeatureCommand}
                    _showTooltip={showTooltip}
                    _hideTooltip={hideTooltip}
                />
            }
            {showEditStacking &&
                <EditStackingOrder
                    closePanelFunc={closeEditStacking}
                    features={editedCampaignData.features}
                    saveFeatureUpdates={saveFeatureUpdates}
                />
            }
            {showingTooltip &&
                <div className="tw-tooltip" style={tooltipPos} dangerouslySetInnerHTML={{__html: tooltipText}}>
                </div>
            }
            {showArcadeDataPopup &&
                <ArcadeDataPopup closePanelFunc={closeArcadeDataPopup} />
            }
            {/* showingRestrictedCustomisationPopup &&
                <RestrictedCustomisationPopup
                    title="Edit Poll Data"
                    tab="setup"
                    group="pollData"
                    family="polls"
                    closePanelFunc={closeRestrictedCustomisationPopup}
                    featureId={restrictedCustomisationFeatureId.current}
                    campaignData={editedCampaignData}
                    customisationVars={customisationVars.current}
                    saveCustomisationVars={reportCustomisationVarsChanged}
                />
            */}
            {showingFunctionUnavilablePopup &&
                <FunctionUnavailableInPreviewPopup
                    closePanelFunc={closeFunctionUnavilablePopup}
                />
            }
        </div>
    );

}
export default CampaignOverview;