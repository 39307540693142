import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./SlidePuzzleLiveState.scss";
import ToggleCheck from "../../../forms/ToggleCheck/ToggleCheck";
import { convertMSToHMS, convertSecondsToHMS, getShortDateTimeString, getShortTimeString } from "../../../../helpers/Dates";

const DIFFCULTY_STRINGS = ['SIMPLIFIED', 'EASY', 'MEDIUM', 'HARD', 'INSANE'];

const SlidePuzzleLiveState = (props) => {
    // A state variable to cause an update every second so we can show the live time
    const [tick, setTick] = useState(0);

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData?.slidePuzzleState) {
            stateData = props.passedInPreviewData.slidePuzzleState;
        }
        if (stateData === null) {
            stateData = props?.featureVars?.slidePuzzleState;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {
            gameActive: false,
            gamePlayed: false,
            gameStartTime: 0,
            gameEndTime: 0,
            attempts: 0,
            attemptStartTime: 0,
            difficulty: 0,
            moves: 0,
            forceEndingAttempt: false,
            puzzleSolved: false
        };
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTick((tick) => tick + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const stateData = getStateData();
    const endTime = stateData.gameActive ? Date.now() : stateData.gameEndTime;
    const HMSTotalTime = convertMSToHMS(endTime - stateData.gameStartTime, true);
    const attemptTime = convertMSToHMS(Date.now() - stateData.attemptStartTime, true);

    // console.log('SlidePuzzleLiveState', props);

    return (
        <div className="slide-puzzle-status fl-column">

            <div className={`status-main-header ${stateData.gameActive ? 'active' : 'inactive'}`}>
                {stateData.gameActive ? "- Running -" : "- Inactive -"}
            </div>
            {stateData.gameActive &&
                <div className="var-label">Current Game Status:</div>
            }
            {!stateData.gameActive && stateData.gamePlayed &&
                <div className="var-label">Last Game:</div>
            }
            {!stateData.gameActive && !stateData.gamePlayed &&
                <div className="var-label">No puzzles attempted yet...</div>
            }
            {(stateData.gameActive || stateData.gamePlayed) &&
                <>
                    <div className="fl-column no-gap">
                        <div className="fl-row">
                            <div className="form-field-content label shrink">Start Time:</div>
                            <div className="grow"></div>
                            <div className="form-field-content value">{ 
                                getShortDateTimeString(stateData.gameStartTime)
                            }</div>
                        </div>
                        <div className="fl-row">
                            <div className="form-field-content label shrink">{stateData.gameActive ? 'Elapsed Time' : 'Total Time'}</div>
                            <div className="grow"></div>
                            <div className="form-field-content value">
                                {HMSTotalTime.h_str}:{HMSTotalTime.m_str}:{HMSTotalTime.s_str}
                            </div>
                        </div>
                    </div>
                    {props.customisationData?.setup?.settings?.game?.[0]?.autoRetry &&
                        <div className="fl-column no-gap">
                            <div className="fl-row">
                                <div className="form-field-content label shrink">Attempt:</div>
                                <div className="grow"></div>
                                <div className="form-field-content value">{stateData.attempts + 1}/{props.customisationData?.setup?.settings?.game?.[0]?.maxRetries ?? 1}</div>
                            </div>
                            {props.customisationData?.setup?.settings?.game?.[0]?.maxRetries > 1 && props.customisationData?.setup?.settings?.game?.[0]?.autoSimplify &&
                                <div className="fl-row">
                                    <div className="form-field-content label shrink">Auto Simplify At Attempt:</div>
                                    <div className="grow"></div>
                                    <div className="form-field-content value">{props.customisationData?.setup?.settings?.game?.[0]?.simplifyAtRetry ?? 'Not Set'}</div>
                                </div>
                            }
                            <div className="fl-row">
                                <div className="form-field-content label shrink">Attempt Time:</div>
                                <div className="grow"></div>
                                <div className="form-field-content value">
                                    {attemptTime.h_str}:{attemptTime.m_str}:{attemptTime.s_str}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="fl-column no-gap">
                        <div className="fl-row">
                            <div className="form-field-content label shrink">Difficulty:</div>
                            <div className="grow"></div>
                            <div className="form-field-content value">{DIFFCULTY_STRINGS[stateData.difficulty] ?? 'Not Set'}</div>
                        </div>
                        <div className="fl-row">
                            <div className="form-field-content label shrink">Moves:</div>
                            <div className="grow"></div>
                            <div className="form-field-content value">{stateData.moves}/{props.customisationData?.setup?.settings?.game?.[0]?.movesLimit ?? 1}</div>
                        </div>
                    </div>
                    <div className="fl-row">
                        <div className="form-field-content label shrink">Puzzle Solved?</div>
                        <div className="grow"></div>
                        <div className="form-field-content value">{stateData.puzzleSolved ? "Yes" : "No"}</div>
                    </div>
                    {stateData.forceEndingAttempt &&
                        <div className="fl-row">
                            <div className="form-field-content label shrink warning-text">Attempt will be force ended after next move!</div>
                        </div>
                    }
                </>
            }

            {/* <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Game Active:</div>
                <div className="slide-puzzle-live-state__value">{stateData.gameActive ? "Yes" : "No"}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Game Played:</div>
                <div className="slide-puzzle-live-state__value">{stateData.gamePlayed ? "Yes" : "No"}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Game Start Time:</div>
                <div className="slide-puzzle-live-state__value">{stateData.gameStartTime}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Game End Time:</div>
                <div className="slide-puzzle-live-state__value">{stateData.gameEndTime}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Attempts:</div>
                <div className="slide-puzzle-live-state__value">{stateData.attempts}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Attempt Start Time:</div>
                <div className="slide-puzzle-live-state__value">{stateData.attemptStartTime}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Difficulty:</div>
                <div className="slide-puzzle-live-state__value">{stateData.difficulty}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Moves:</div>
                <div className="slide-puzzle-live-state__value">{stateData.moves}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Force Ending Attempt:</div>
                <div className="slide-puzzle-live-state__value">{stateData.forceEndingAttempt ? "Yes" : "No"}</div>
            </div>
            <div className="slide-puzzle-live-state__row">
                <div className="slide-puzzle-live-state__label">Puzzle Solved:</div>
                <div className="slide-puzzle-live-state__value">{stateData.puzzleSolved ? "Yes" : "No"}</div>
            </div> */}
        </div>
    );
}

export default SlidePuzzleLiveState;
