import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./SlidePuzzleSelector.scss";

const SlidePuzzleSelector = (props) => {
    // Build our drop down options from props.customisationData.setup.pollData.polls[i].title
    const puzzleListData = [];
    // const pollTypes = [];
    const puzzlesData = props.customisationData?.setup?.puzzles?.puzzles;
    if (!puzzlesData) {
        puzzleListData.push({
            id: 0,
            label: "No Puzzles Available",
            value: 0,
            val: 0
        });
    } else {
        for (let i = 0; i < props.customisationData.setup.puzzles.puzzles.length; i++) {
            puzzleListData.push({
                id: i,
                label: props.customisationData.setup.puzzles.puzzles[i].name,
                value: i,
                val: i
            });
            // pollTypes.push(props.customisationData.setup.pollData.polls[i].type);
        }
    }

    // onMount - make sure we have these values!
    useEffect(() => {
        /* if (props.featureVars.pollControl.pollRunning !== true && props.featureVars.pollControl.pollRunning !== false) {
            props.changeValFunc('pollControl', 'pollRunning', false, false, false, false);
        }
        if (props.featureVars.pollControl.pollVisible !== true && props.featureVars.pollControl.pollVisible !== false) {
            props.changeValFunc('pollControl', 'pollVisible', false, false, false, false);
        } */
        // Ensure we have a valid poll index
        let correctPuzzleIndex = props.value;
        if (isNaN(correctPuzzleIndex) || correctPuzzleIndex < 0 || correctPuzzleIndex >= puzzleListData.length) {
            correctPuzzleIndex = 0;
        }
        if (correctPuzzleIndex !== props.value) {
            props.setFunc(correctPuzzleIndex);
        }

        // Does the selected poll have any votes?
        /* const featureVars = props.passedInPreviewData ? props.passedInPreviewData : props.featureVars;
        // const pollData = featureVars?.pollControl?.pollsState?.[correctPollIndex]; // props.customisationData?.setup?.pollData?.polls?.[props.value];
        // const pollData = props.customisationData?.setup?.pollData?.polls?.[correctPollIndex];
        let pollData = null;
        if (featureVars?.pollControl?.pollsState) {
            for (let i = 0; i < featureVars.pollControl.pollsState.length; i++) {
                if (featureVars.pollControl.pollsState[i].pollTitle === props.customisationData?.setup.pollData.polls[props.value].title) {
                    pollData = featureVars.pollControl.pollsState[i];
                    break;
                }
            }
        }
        let pollHasVotes = false;
        let correctAnswer = -1;
        if (pollData && pollData.answerState && pollData.answerState.length > 0) {
            for (let i = 0; i < pollData.answerState.length; i++) {
                if (pollData.answerState[i].votePercentage > 0) {
                    pollHasVotes = true;
                }
                if (pollData.answerState[i].correct) {
                    correctAnswer = i;
                }
            }
        } */

        // These false, false, false flags are to prevent the changeValFunc from reporting a change and also rebuilding state data
        // we're doing this because we only need to report changes and update state once all these values have changed.
        // The final true, false, false flag tells the dashboard to report the changes, but still hold off on rebuilding state data
        // this is because the state data will get rebuilt anyway after the data is reported - if we use default behaviour we get some thrashing
        // of the state and our button flickers between states, this ensures we only update the state once.

        // console.log('--- SlidePuzzleSelector, pollData: ', pollData, pollHasVotes, correctAnswer);

        // props.changeValFunc('pollControl', 'pollHasVotes', pollHasVotes, false, false, false);
        // props.changeValFunc('pollControl', 'correctAnswer', correctAnswer, false, false, false);
        // props.changeValFunc('pollControl', 'selectedPollType', pollTypes[correctPuzzleIndex], true, false, true);
    }, []);

    // const featureVars = props.passedInPreviewData ? props.passedInPreviewData : props.featureVars;

    return (
        <>
            <FormDropDown
                label={props.label}
                items={puzzleListData}
                value={props.value}
                selectFunc={
                    (e, id, item) => {
                        props.setFunc(item.index)
                        // props.changeValFunc('pollControl', 'selectedPollType', pollTypes[item.index]);
                    }
                }
                currentIndex={props.value}
                showErrors={false}
                enabled={puzzleListData.length > 1}
            />
            {props?.customisationData?.setup?.puzzles?.puzzles?.[props.value]?.puzzleImage?.srcUrl &&
                <img src={props.customisationData.setup.puzzles.puzzles[props.value].puzzleImage.srcUrl} alt="Puzzle" className="slide-puzzle-image" />
            }
        </>
    )
};

export default SlidePuzzleSelector;
