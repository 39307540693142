import "./Feature.scss";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import axios from "../../api/axios";
import { createArcadeEntriesRoute, filesRoute, getCampaignFeatureVarsRoute, getCampaignOverviewRoute, getFeatureVarsRoute, getQuizzesByIdsRoute, getStreamCustomisationVarsRoute, streamConnectionStatusRoute, updateCampaignFeatureVarsFromFeatureRoute, updateCampaignFeatureVarsRoute, updateFeatureVarsFromFeatureRoute, updateFeatureVarsRoute } from "../../api/routes";
import { toast } from "react-toastify";
import { DATA_SAVE_LEVELS, deepObjectMerge, POST_MESSAGE_IN_TYPES, POST_MESSAGE_OUT_TYPES, resetOnLoadDefaultFeatureVars } from "../../helpers/FeatureControlsHelper";
import { assets } from "../../assets/assets";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { CampaignContext } from "../../contexts/CampaignContext";
import { CUSTOMISED_ASSETS_BASE_URL, CUSTOMISED_URL_MAP, getValFromObject } from "../../config/FeatureDashboardVarMap";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";
import { findFeature } from "../../helpers/CampaignsHelper";
import { injectQuizImageSrcUrls } from "../../helpers/QuizHelper";

/**
 * So what does this do then?
 * 
 * It dynamically creates a bunch of iframes based on the streamVars data for the stream...
 * 
 * The basic flow is this:
 * 
 *  Set some state vars and post message handler.
 *  Pull in the streamVars.
 *  Set up our iframes, we use any properties in streamVars.current with a featureURL to name our iframes.
 *  Connect to the dashboard and set up some socket listeners.
 * 
 * The iframes content will use postMessage do a little handshake to check if they are connected to the dashboard or running locally (allowing test mode in features),
 * then they will inform us when the feature has loaded, at which point we pass in their initial state vars.
 * 
 * Now all we need to do is listen for socket events and post data and commands through to the feature(s).
 * Var updates are only posted to the feature it is meant for, commands and heartbeats are posted to all frames.
 */

function Home() {
    const frontendBaseUrl = process.env.REACT_APP_AXIOS_ORIGIN;
    const backendBaseUrl = process.env.REACT_APP_API_BASE_URL.split('/api')[0];

    const [randomPreviewBG, setRandomPreviewBG] = useState(Math.random());
    // Check if we have "preview" in the query string
    const checkIsPreview = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const isPreview = searchParams.has('preview');
        return isPreview;
    }
    const [showPreviewBg, setShowPreviewBg] = useState(checkIsPreview());

    const { campaignId, streamId, gamePIN } = useParams();
    const [loaded, setLoaded] = useState(false);
    const loaded_ref = useRef(false);

    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const navigate = useNavigate();


    const [cslActivate, setCSLActivate] = useState(false);
    const [isMaster, setIsMaster] = useState(false);

    const streamConnected = useRef(false);
    const checkConnectionStatus = (streamID) => {
        console.log('Checking connection status...', streamId);
        axios
            .get(streamConnectionStatusRoute, {
                params: { streamID: streamId },
                withCredentials: true
            })
            .then(function (response) {
                console.log("Stream is active!", response.data.active);
                streamConnected.current = response.data.active;
            })
            .catch(function (error) {
                streamConnected.current = false;
            });
    }
    useEffect(
        () => {
            checkConnectionStatus();
            if (checkIsPreview()) {
                window.document.body.style.backgroundColor = 'black';
            }
        },
         []
    )

    useEffect(
        () => {
            const CSL = CSLRef.current;
            if(CSL && CSL.contentWindow){
                CSL.contentWindow.document.addEventListener('click', () => {

                if(featureVars?.current?.current?.contentSceneLibrary?.overlayState.currentView == 'configoverlay')
                {   
                    socket.current.emit('setMasterFeature', {streamID: streamId, masterID:randomFeatureID.current});
                    console.log('SET MASTER FEATURE ID',  randomFeatureID.current)
                }

            })
            }
        },
        [loaded]
    )

    const CSLRef = useRef(null);
    const randomFeatureID = useRef(Date.now());
    const masterFeatureID = useRef(0);
    const [pageExpired, setPageExpired] = useState(false);

    const socket = useRef(null);
    const connectToCampaignStudio = () => {
        if (socket.current === null) {
            socket.current = process.env.REACT_APP_NODE_ENV == 'local' ? io(process.env.REACT_APP_SOCKET_IO_BASE_URL) : io.connect(null, { secure: true, port: 8443 });
            console.log(socket.current, process.env.REACT_APP_SOCKET_IO_BASE_URL, process.env.REACT_APP_NODE_ENV);
            socket.current.on('connect', () => {
                if (streamId) {
                    socket.current.emit('featureJoinStream', streamId);
                    console.log('Campaign Studio Feature: Connected');
                }
                else {
                    console.log('Campaign Studio Feature: Not Connected')
                }

            })
            socket.current.on('setMasterFeature', (data) => {
                // console.log('Heartbeat: ', timeObj);
                console.log('NEW MASTER FEATURE:', data.masterID, randomFeatureID.current == data.masterID)
                masterFeatureID.current = data.masterID
                setIsMaster(masterFeatureID.current == randomFeatureID.current)
            });
            socket.current.on('killOldFeatures', (data) => {
                console.log('Killing old features: ', data.keepFeatureIDOpen);
                if (randomFeatureID.current != data.keepFeatureIDOpen) {
                    console.log('Killing old features');
                    // window.location.replace(TOP_LEVEL_NAV_ROUTES.FEATURE_EXPIRED);
                    setPageExpired(true);

                    if (masterFeatureID.current === randomFeatureID.current) {
                        console.log('MASTER FEATURE')
                        socket.current.emit('setMasterFeature', {streamID: streamId, masterID:0});
                    }

                    const timestamp = Date.now();
                    socket.current.emit('featureUnloaded', { streamID: streamId, timestamp: timestamp, featureID: randomFeatureID.current });

                    // Kill the socket!
                    socket.current.disconnect();
                    socket.current = null;
                }
            });

            socket.current.on('heartbeat', (timeObj) => {
                // console.log('Heartbeat: ', timeObj);
                postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.HEARTBEAT, { heartbeat: true });
            });
            socket.current.on('featureCommand', (messageObj) => {
                //console.log('Command Received: ', messageObj.command, messageObj.chatObj);
                // postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: messageObj.command, chatData: messageObj.chatObj });
                console.log('FEATURE COMMAND')
                if (messageObj.featureID === 'GLOBAL') {
                    postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: messageObj.command, extraData: messageObj.extraData });
                } else {
                    postMessageToFrame(messageObj.featureID, POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: messageObj.command, extraData: messageObj.extraData });
                }
            });
            socket.current.on('updateCustomisationVars', (data) => {
                console.log('CV CURRENT', data.updatedVars)
                customisationVars.current = data.updatedVars;
                passFeatureCustomisationsToFeature(data.featureID)
                //console.log('Command Received: ', messageObj.command, messageObj.chatObj);
                // postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: messageObj.command, chatData: messageObj.chatObj });
                //postMessageToFrame(messageObj.featureID, POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: messageObj.command, extraData: messageObj.extraData });
            });
            socket.current.on('updateFeatureVars', (updatedVars) => {
                console.log('Vars received: ', updatedVars);
                featureVars.current.current = updatedVars;
                if (ref_iframesLoaded.current) {
                    for (let prop in updatedVars) {
                        if (ref_iframesLoaded.current[prop]) {
                            // Make sure we have activated and demoMode in there (could prob do this better if we ever need more top level stuff)!
                            // All features can know about demo mode...
                            featureVars.current.current[prop].demoMode = featureVars.current.current.demoMode;
                            // But only csl knows about the top level activated and the advertiser, features will have their own activated var
                            if (prop === 'contentSceneLibrary') {
                                featureVars.current.current[prop].activated = featureVars.current.current.activated;
                                setCSLActivate(featureVars?.current?.current?.contentSceneLibrary?.overlayState.currentView == 'configoverlay');
                              
                                featureVars.current.current[prop].adBugAdvertiser = featureVars.current.current.adBugAdvertiser;
                            }
                            if (updatedVars.fromFeature !== true) {
                                postMessageToFrame(prop, POST_MESSAGE_OUT_TYPES.SETTINGS_VARS_RECEIVED, featureVars.current.current[prop]);
                            }
                        }
                    }
                }
            });
            socket.current.on('stateControl', (newState) => {
                console.log('State received: ', newState);
                // postMessageToFrame(POST_MESSAGE_OUT_TYPES.STATE_UPDATE, { newState: newState });
            });
            socket.current.on('dashboardJoinStream', () => {
                console.log('A dashboard has joined!');
                // let the new dashboard know if we are already loaded
                checkIfFeatureReady();
                // let the new dashboard know if we are audio enabled
                if (audioEnabled.current) {
                    const timestamp = Date.now();
                    socket.current.emit('audioEnabled', { streamID: streamId, timestamp: timestamp });
                }
            });
            /* socket.current.on("reload_page", (err) => {
                console.log('RELOADING FEATURE');
                window.location.reload(true);
            }); */
            socket.current.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
            });

            socket.current.on('streamActivated', function (data) {
                console.log('STREAM ACTIVATED')
                streamConnected.current = true;
            });
            socket.current.on('streamDeactivated', function (data) {
                console.log('STREAM DEACTIVATED')
                streamConnected.current = false;
            });

            // Let a feature that triggered another feature know the triggered feature has ended
            socket.current.on('triggeredFeatureEnded', (updateData) => {
                console.log('Triggered feature ended! ', updateData);
                postMessageToFrame(updateData.waitingFeatureId, POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, { featureId: updateData.featureEndedId })
            });

            socket.current.on('receiveStreamMarkerConfirmation', function (data) {
                console.log('Marker confirmation received: ', data);
                postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.STREAM_MARKER_CONFIRMED, { markerData: data });
            });

            socket.current.on('forceFeatureStop',
                () => { refreshAllIframes(); }
            );

            socket.current.on('refreshFeature',
                (data) => { 
                    console.log('Refresh feature message received: ', data);
                    if (data.featureID === 'ALL' || data.featureID === 'GLOBAL' || data.featureID === null || data.featureID === undefined) {
                        refreshAllIframes();
                    } else {
                        if (typeof data.applyTheme === 'string') {
                            // Force the theme id into this disruptors featureVars as the new feature vars may be received after this refresh message
                            if (featureVars.current?.current?.[data.featureID]) {
                                featureVars.current.current[data.featureID].theme = {id: data.applyTheme};
                                console.log('Theme forced into feature vars: ', JSON.parse(JSON.stringify(featureVars.current.current[data.featureID])));
                            }
                        }
                        refreshSpecificIframe(data.featureID); 
                    }
                }
            );

            // --- Claw machine sockets ---
            socket.current.on('clawSwitchCam', (data) => {
                if(randomFeatureID.current == masterFeatureID.current)
                {
                    console.log('Claw switch cam: ', data);
                    postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.CLAW_SWITCH_CAM, { camId: data });
                }
            });

            socket.current.on('clawMoveEnded', (data) => {
                if(randomFeatureID.current == masterFeatureID.current)
                {
                    console.log('Claw move ended: ', data);
                    postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.CLAW_MOVE_ENDED, { winner: data });
                }
            });
        }
    }

    const [iframesLoaded, setIframesLoaded] = useState({});
    // Our ref is used outside of the render function and our setIframesLoaded function use a callback to reflect the ref
    const ref_iframesLoaded = useRef({});
    const setupIframes = () => {
        for (let prop in featureVars.current.current) {
            if (featureVars.current.current[prop].hasOwnProperty('featureURL')) {
                ref_iframesLoaded.current[prop] = false;
            }
        }

        setIframesLoaded(
            state => {
                const updatedState = { ...ref_iframesLoaded.current };
                return updatedState;
            }
        );

        connectToCampaignStudio();
    }

    const refreshAllIframes = () => {
        document.location.reload(true);
        /*
        setLoaded(false);
        loaded_ref.current = false;
        if (socket.current) {
            const timestamp = Date.now();
            socket.current.emit('featureUnloaded', {streamID: streamId, timestamp: timestamp});
            if (audioEnabled.current === true) {
                socket.current.emit('audioEnabled', {streamID: streamId, timestamp: timestamp});
            }
        }
        for (let prop in ref_iframesLoaded.current) {
            ref_iframesLoaded.current[prop] = false;
            const frameRef = document.getElementById(prop);
            frameRef.contentWindow.location.reload();
        }
        setIframesLoaded(state => { const updatedState = {...ref_iframesLoaded.current}; return updatedState; });*/
    }

    const refreshSpecificIframe = (featureId) => {
        console.log('Refreshing specific iframe: ', featureId);
        if (socket.current) {
            const timestamp = Date.now();
            socket.current.emit('featureUnloaded', {streamID: streamId, retainAudioState: true, timestamp: timestamp});
        }
        setLoaded(false);
        loaded_ref.current = false;
        ref_iframesLoaded.current[featureId] = false;
        const frameRef = document.getElementById(featureId);
        frameRef.contentWindow.location.reload();
    }

    const audioEnabled = useRef(false);
    const checkIfFeatureReady = () => {
        // Have they all loaded?
        let allLoaded = true;
        for (let prop in ref_iframesLoaded.current) {
            if (allLoaded) {
                allLoaded = ref_iframesLoaded.current[prop];
                if (!allLoaded) {
                    break;
                }
            }
        }
        if (allLoaded) {
            const timestamp = Date.now();
            socket.current.emit('featureReady', { streamID: streamId, timestamp: timestamp, featureID: randomFeatureID.current });
            setLoaded(true);
            loaded_ref.current = true;
            console.log('FEATURE LOADED')
            // Make sure our dashboards know who the master is!
            if (masterFeatureID.current === randomFeatureID.current) {
                console.log('MASTER FEATURE')
                socket.current.emit('setMasterFeature', {streamID: streamId, masterID:randomFeatureID.current});
            }
        }
    }

    /** Make sure we have campiagn data - as this is it's own route we can't guarantee we do! */
    const campaignData = useRef(null);
    const pullInCampaignData = () => {
        if (campaignId) {
            axios
                .get(getCampaignOverviewRoute, { params: { campaignID: campaignId }, withCredentials: true })
                .then((response) => {
                    console.log('Got campaign data: ', response.data.campaignData);
                    setCampaignContext((oldValues) => {
                        return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
                    })
                    campaignData.current = response.data.campaignData;
                    getCustomisationVars();
                    getCampaignCustomisationVars(campaignId);
                })
                .catch((error) => {
                    // toast.error("Error fetching campaign data");
                    navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
                });
        } else {
            navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
        }
    }

    const findFeatureData = (campaignData, featureId) => {
        if (campaignData && campaignData.features) {
            for (let i = 0; i < campaignData.features.length; i++) {
                if (campaignData.features[i]._id === featureId) {
                    return campaignData.features[i];
                }
            }
        }
        return null;
    }

    const featureVars = useRef(null);
    const getFeatureVars = () => {
        console.log('Get Feature Vars');
        axios
            .get(getFeatureVarsRoute, {
                params: { campaignID: campaignId, streamID: streamId },
                withCredentials: true
            })
            .then(function (response) {
                if (response.data.streamVars) {
                    // Add in our base urls so our features can access them from the state in bootstrap.js
                    let baseUrls = {
                        frontend: frontendBaseUrl,
                        backend: backendBaseUrl,
                    }
                    for (let p in response.data.streamVars.current) {
                        if (typeof response.data.streamVars.current[p] === 'object') {
                            response.data.streamVars.current[p].baseUrls = baseUrls;
                        }
                    }
                    featureVars.current = response.data.streamVars;
                    console.log('Got streamVars: ', featureVars.current);
                    resetOnLoadDefaultFeatureVarsInternal();
                    getCampaignLevelFeatureVars();
                }
            })
            .catch(function (error) {
                // toast.error('Error - ' + error);
            });
    }

    const resetOnLoadDefaultFeatureVarsInternal = () => {
        // Reset any featureVars that need to be reset on load...
        const _campaignData = campaignData.current || campaignContext.campaignData;
        // console.log('RESET ONLOAD VARS 1: ', _campaignData.features, _campaignData.features.length);
        if (_campaignData && _campaignData.features) {
            for (let i = 0; i < _campaignData.features.length; i++) {
                // console.log('RESET ONLOAD VARS: ', _campaignData.features[i]);
                let madeChanges = resetOnLoadDefaultFeatureVars(featureVars.current, _campaignData.features[i], customisationVars.current);
            }
        } else {
            setTimeout(() => resetOnLoadDefaultFeatureVarsInternal(), 20);
        }
    }

    const campaignLevelFeatureVars = useRef(null);
    const getCampaignLevelFeatureVars = () => {
        console.log('Get Campaign Level Feature Vars');
        axios
            .get(getCampaignFeatureVarsRoute, {
                params: { campaignID: campaignId },
                withCredentials: true
            })
            .then(function (response) {
                if (response.data.streamVars) {
                    campaignLevelFeatureVars.current = response.data.streamVars;
                    console.log('Got campaignLevelFeatureVars: ', campaignLevelFeatureVars.current);

                    // This is where we will pull out multi stream data and inject it into the stream level vars
                    pullOutMultiStreamData();

                    setupIframes();
                }
            })
            .catch(function (error) {
                // toast.error('Error - ' + error);
            });
    }

    const customisationVars = useRef(null);
    const getCustomisationVars = () => {
        console.log('Get Customisation Vars');
        axios
            .get(getStreamCustomisationVarsRoute, {
                params: { campaignID: campaignId, streamID: streamId },
                withCredentials: true
            })
            .then(function (response) {
                if (response.data.customisationVars) {
                    customisationVars.current = response.data.customisationVars;
                    console.log('Got customisationVars: ', customisationVars.current);
                    getFeatureVars();
                }
            })
            .catch(function (error) {
                // toast.error('Error - ' + error);
            });
    }

    const campaignLevelCustomisationVars = useRef(null);
    const getCampaignCustomisationVars = () => {
        console.log('Get Campaign Customisation Vars');
        axios
            .get(getCampaignCustomisationVars, {
                params: { campaignID: campaignId },
                withCredentials: true
            })
            .then(function (response) {
                if (response.data.customisationVars) {
                    campaignLevelCustomisationVars.current = response.data.customisationVars;
                    console.log('Got campaignLevelCustomisationVars: ', campaignLevelCustomisationVars.current);
                }
            })
            .catch(function (error) {
                // toast.error('Error - ' + error);
            });
    }

    useEffect(
        () => {
            // The flow for this will be:
            //      getFeatureVars
            //      setupIframes
            // on frame loaded:
            //      passFeatureVarsToFrame
            // on all frames loaded:
            //      connectToCampaignStudio
            // we probably need to handle unloading as well...
            // getCustomisationVars();
            pullInCampaignData();
        }, [streamId, campaignId]
    )

    /*
        Deal with multi stream data...
    */

    // Multi stream tag is a string derived from the channel list in the stream data, which is already sorted alphabetically.
    // This way we can specify which data needs to be shared between streams (only streams with the same channel list).
    const multiStreamTag = useRef(null);
    const getMultiStreamTag = () => {
        if (multiStreamTag.current === null) {
            const channelList = campaignData.current.streams.find(stream => stream._id === streamId).channels;
            let tag = '';
            for (let i = 0; i < channelList.length; i++) {
                tag += channelList[i].twitchHandle.toLowerCase();
            }
            multiStreamTag.current = tag;
        }
        return multiStreamTag.current;
    }

    const pullOutMultiStreamData = () => {
        const multiStreamTag = getMultiStreamTag();
        for (let prop in campaignLevelFeatureVars.current.current) {
            if (campaignLevelFeatureVars.current.current[prop].multiStreamData && campaignLevelFeatureVars.current.current[prop].multiStreamData[multiStreamTag]) {
                const taggedData = campaignLevelFeatureVars.current.current[prop].multiStreamData[multiStreamTag];
                for (let prop2 in taggedData) {
                    // Deep copy the data into the stream level vars
                    featureVars.current.current[prop][prop2] = JSON.parse(JSON.stringify(campaignLevelFeatureVars.current.current[prop].multiStreamData[multiStreamTag][prop2]));
                    console.log('Multi stream data pulled out: ', prop, campaignLevelFeatureVars.current.current[prop].multiStreamData, multiStreamTag, prop2, featureVars.current.current[prop][prop2]);
                }
            }
        }
    }

    const saveMultiStreamData = (featureId, dataToSave) => {
        // console.log('--- SKIPPING MULTI STREAM DATA SAVE ---', dataToSave);
        // return;
        if (featureId && dataToSave) {
            const multiStreamTag = getMultiStreamTag();
            const campaignLevelFeatureVarsForId = campaignLevelFeatureVars.current.current[featureId];
            if (campaignLevelFeatureVarsForId) {
                if (typeof campaignLevelFeatureVarsForId.multiStreamData === 'undefined') {
                    campaignLevelFeatureVarsForId.multiStreamData = {};
                }
                if (typeof campaignLevelFeatureVarsForId.multiStreamData[multiStreamTag] === 'undefined') {
                    campaignLevelFeatureVarsForId.multiStreamData[multiStreamTag] = {};
                }
                let dataAdded = false;
                for (let prop in dataToSave) {
                    campaignLevelFeatureVarsForId.multiStreamData[multiStreamTag][prop] = dataToSave[prop];
                    dataAdded = true;
                }
                if (dataAdded) {
                    // Save the data to the database
                    axios
                        .post(updateCampaignFeatureVarsFromFeatureRoute, {
                            campaignID: campaignId,
                            streamID: streamId,
                            updatedVars: campaignLevelFeatureVars.current.current,
                            withCredentials: true
                        })
                        .then(function (response) {
                            console.log('Multi stream data saved: ', multiStreamTag, response.data, campaignLevelFeatureVars.current);
                        })
                        .catch(function (error) {
                            // toast.error('Error - ' + error);
                        });
                }
            }
        }
    }

    const passFeatureCustomisationsToFeature = (featureId) => {
        const key = featureId;
        // for (const key in customisationVars.current.current) {
        if (customisationVars.current.current.hasOwnProperty(key)) {
            const featureData = findFeatureData(campaignData.current, key);
            console.log('Feature data: ', campaignData, key, featureData);

            // Make sure are working on a clone of the data so we don't mess up the original
            let themedCustomisationData = JSON.parse(JSON.stringify(customisationVars.current.current[key]));

            if (featureData) {
                // Apply theme data to the customisation vars
                const themeId = featureVars.current.current[key].theme ? featureVars.current.current[key].theme.id : null;
                if (themeId) {
                    const themeData_all = campaignData.current.themes.find(theme => theme._id === themeId);
                    if (themeData_all) {
                        const themeVars = themeData_all.themeData;
                        for (let tabKey in themeVars) {
                            if (themedCustomisationData[tabKey]) {
                                for (let varKey in themeVars[tabKey]) {
                                    if (themedCustomisationData[tabKey][varKey]) {
                                        for (let varName in themeVars[tabKey][varKey]) {
                                            for (let varI = 0; varI < themeVars[tabKey][varKey][varName].length; varI++) {
                                                let themedVal = false;
                                                if (Object.keys(themeVars[tabKey][varKey][varName][varI]).length > 0) {
                                                    // If our theme has more values than the customisation data, we need to add them in
                                                    while (themedCustomisationData[tabKey][varKey][varName].length <= varI) {
                                                        // Apply the default values
                                                        const varSchema = featureData.feature.customisation.find((c) => c.tabKey === tabKey).options.find((o) => o.varKey === varKey).variables.find((v) => v.varName === varName).valSchema;
                                                        const defaultVals = {};
                                                        for (let prop in varSchema) {
                                                            defaultVals[prop] = varSchema[prop].default;
                                                        }
                                                        themedCustomisationData[tabKey][varKey][varName].push(defaultVals);
                                                    }
                                                    // Then apply the theme values
                                                    for (let prop in themeVars[tabKey][varKey][varName][varI]) {
                                                        if (themedCustomisationData[tabKey][varKey][varName]?.[varI]?.[prop]) {
                                                            themedCustomisationData[tabKey][varKey][varName][varI][prop] = themeVars[tabKey][varKey][varName][varI][prop];
                                                        }
                                                        themedVal = true;
                                                    }
                                                    if (themedVal) {
                                                        themedCustomisationData[tabKey][varKey][varName][varI].isDefault = false;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // convert any filenames into urls
                const customisationData = featureData.feature.customisation;
                for (let i = 0; i < customisationData.length; i++) {
                    const rootVal = themedCustomisationData; // customisationVars.current.current[key];
                    const tabKey = customisationData[i].tabKey;
                    for (let j = 0; j < customisationData[i].options.length; j++) {
                        const varKey = customisationData[i].options[j].varKey;
                        for (let k = 0; k < customisationData[i].options[j].variables.length; k++) {
                            const varName = customisationData[i].options[j].variables[k].varName;
                            for (let prop in customisationData[i].options[j].variables[k].valSchema) {
                                const type = customisationData[i].options[j].variables[k].valSchema[prop].type.toLowerCase();
                                if (CUSTOMISED_URL_MAP.hasOwnProperty(type)) {
                                    // We found one we need to map to a url!
                                    const vals = getValFromObject(rootVal[tabKey], varKey, varName);
                                    for (let l = 0; l < vals.length; l++) {
                                        // Reformat legacy string based filenames to be objects...
                                        // SrcUrl has to be part of the object, meaning all feature customisations are going to break...
                                        let filename = vals[l][prop];
                                        if (typeof filename === 'string' || typeof filename === 'undefined' || filename === null) {
                                            filename = { filename: vals[l][prop] };
                                            vals[l][prop] = filename;
                                            filename.isDefault = vals[l].isDefault;
                                        }

                                        const srcUrl =
                                            (filename.isDefault ? getFeatureAssetBaseUrl(true) + featureData.feature.defaultAssetFolder : getFeatureAssetBaseUrl(false) + 'campaigns/' + campaignId + '/' + (key + (streamId && vals[l][prop].isStreamLevel ? ('/' + streamId) : '')))
                                            + '/' + CUSTOMISED_URL_MAP[type].dir + '/' + filename.filename;

                                        filename.srcUrl = srcUrl;
                                        filename.originalFile = filename.filename;
                                        filename.type = CUSTOMISED_URL_MAP[type].dir;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            console.log('Posting customisation data to frame');
            console.log(`${key}: `, customisationVars.current.current[key]);
            console.log('THEMED: ', themedCustomisationData);
            // Before we post the data to the frame, add in the domains we are running from so the feature can use it to build api requests and such
            // customisationVars.current.current[key].domain = window.location.origin;
            // customisationVars.current.current[key].apiDomain = process.env.REACT_APP_API_BASE_URL;
            postMessageToFrame(key, POST_MESSAGE_OUT_TYPES.CUSTOMISATION_VARS_RECEIVED, themedCustomisationData/*customisationVars.current.current[key]*/, false);
        }
        // }
    }

    // Updating customisation data when data is posted from a disruptor / feature
    const reportCustomisationVarsChanged = (updatedVars, featureID, isStreamLevel = true) => {
        console.log('EMIT CUSTOMISATION CHANGES', updatedVars, featureID, socket);
        socket.current.emit('updateCustomisationVarsWithSave', {campaignID:campaignId, streamID: isStreamLevel ? streamId : null, updatedVars: updatedVars, featureID: featureID });
        console.log('EMITTED')
    }

    const addScoreToCustomisationData = (featureId, score, name) => {
        // console.log('Adding score to customisation data: ', featureId, score, name);
        const _campaignData = campaignData.current;
        const _featureData = _campaignData ? findFeatureData(_campaignData, featureId) : null;
        // console.log('Found feature data: ', _featureData);

        if (_campaignData && _featureData) {
            if (name === null || name === undefined || name === '') {
                name = '';
                // find the stream data
                const streamData = _campaignData.streams.find(stream => stream._id === streamId);
                if (streamData) {
                    // The name is based on channels[index].twitchHandle
                    // If there is more than one, seperate them with a comma
                    const channelData = streamData.channels;
                    if (channelData) {
                        for (let i = 0; i < channelData.length; i++) {
                            if (i > 0) {
                                name += ', ';
                            }
                            name += channelData[i].twitchHandle;
                        }
                    }
                }
            }
            // Now find the customisation data for the feature
            const _customisationData = customisationVars.current.current[featureId];
            // console.log('Found customisation data: ', _customisationData);
            if (_customisationData) {
                const entryData = {
                    name: name,
                    score: score,
                    time: Date.now(),
                    showCopyOnZeroScore: false,
                    copy: ''
                }
                // _customisationData.leaderboard.leaderboardData.entries is our scoreboard data array
                // If an entry already exists with our name, then replace it, otherwise add it to the array
                let found = false;
                for (let i = 0; i < _customisationData.leaderboard.leaderboardData.entries.length; i++) {
                    if (_customisationData.leaderboard.leaderboardData.entries[i].name === name) {
                        _customisationData.leaderboard.leaderboardData.entries[i] = entryData;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    _customisationData.leaderboard.leaderboardData.entries.push(entryData);
                }
                // console.log('Score added: ',  _customisationData);
                reportCustomisationVarsChanged(customisationVars.current, featureId);
            }
        }
    }
    // window.addScoreToCustomisationData = addScoreToCustomisationData;

    const leaderboardData = useRef(null);

    // Upodating stream vars when data is posted from a disruptor / feature (stream level persistent storage + 2 way communication)
    const reportUpdatedFeatureVars = (updatedVars) => {
        axios
            .post(updateFeatureVarsFromFeatureRoute, {
                campaignID: campaignId,
                streamID: streamId,
                fromFeature: true,
                updatedVars,
                withCredentials: true
            })
            .then(function (response) {
                console.log(response.data);
                // featureVars.current = response.data.streamVars;
                // setInitialised(true);
            })
            .catch(function (error) {
                // toast.error('Error - ' + error);
                // console.log(error.response)
            });
    }

    const updateFeatureVars = (featureId, updatedVars) => {
        console.log('Updating feature vars: ', featureId, updatedVars);
        if (featureVars.current.current[featureId]) {
            const featureVarsToUpdate = featureVars.current.current[featureId];
            /* let updated = false;
            for (let prop in updatedVars) {
                // Can't just plonk these straight in, we need to iterate through the object and update the values
                if (featureVarsToUpdate.hasOwnProperty(prop)) {
                    for (let prop2 in updatedVars[prop]) {
                        // Changed to just flag that there is something to update!
                        // featureVarsToUpdate[prop][prop2] = updatedVars[prop][prop2];
                        updated = true;
                    }
                } else {
                    // Changed to just flag that there is something to update!
                    // featureVarsToUpdate[prop] = updatedVars[prop];
                    updated = true;
                }
            }
            if (updated) { */
                // Using new deep object merge function as it preserves data already in the object, while updating new values
                deepObjectMerge(featureVarsToUpdate, updatedVars);
                console.log('Feature vars updated: ', featureVarsToUpdate);
                reportUpdatedFeatureVars(featureVars.current.current);
            }
        // }
    }

    // window.updateFeatureVars = updateFeatureVars;

    const locallyTriggeredFeatureIds = [];
    const processPostedMessages = (event) => {
        console.log('Koko Twitch Feature Top Frame received message: ', event.origin, event.data);
        const timestamp = Date.now();
        switch (event.data.type) {
            case POST_MESSAGE_IN_TYPES.HANDSHAKE:
                // respond that we are connected and ready!
                event.source.postMessage(
                    { type: POST_MESSAGE_OUT_TYPES.CONNECTED, detail: 'Howdy!' },
                    event.origin
                );
                passFeatureCustomisationsToFeature(event.data.id);
                break;
            case POST_MESSAGE_IN_TYPES.FEATURE_LOADED:
                if (ref_iframesLoaded.current[event.data.id] !== true) {
                    ref_iframesLoaded.current[event.data.id] = true;
                    setIframesLoaded(state => { const updatedState = { ...ref_iframesLoaded.current }; return updatedState; });
                    // Send feature data to the iframe!
                    // Make sure we have activated and demoMode in there (could prob do this better if we ever need more top level stuff)!
                    // All features can know about demo mode...
                    featureVars.current.current[event.data.id].demoMode = featureVars.current.current.demoMode;
                    // But only csl knows about the top level activated and the advertiser, features will have their own activated var
                    if (event.data.id === 'contentSceneLibrary') {
                        featureVars.current.current[event.data.id].activated = featureVars.current.current.activated;
                        setCSLActivate(featureVars?.current?.current?.contentSceneLibrary?.overlayState.currentView == 'configoverlay')
                        featureVars.current.current[event.data.id].adBugAdvertiser = featureVars.current.current.adBugAdvertiser;
                    }
                    postMessageToFrame(event.data.id, POST_MESSAGE_OUT_TYPES.SETTINGS_VARS_RECEIVED, featureVars.current.current[event.data.id]);

                    // Does this feature require pixi (and therefore a canvas?)
                    // If not remove any canvas elements from the iframe content
                    const featureData = findFeatureData(campaignData.current, event.data.id); // linkedFeatureData.current[event.data.id];
                    const frameRef = document.getElementById(event.data.id);
                    // console.log('Feature loaded (looking for requiresPixi): ', event.data.id, featureData, frameRef);
                    if (frameRef && frameRef.contentWindow && featureData && featureData.feature && featureData.feature.requiresPixi === false) {
                        // Time to remove our canvas elements
                        const canvasElements = frameRef.contentWindow.document.getElementsByTagName('canvas');
                        // console.log('Canvas elements found: ', canvasElements);
                        for (let i = 0; i < canvasElements.length; i++) {
                            canvasElements[i].remove();
                        }
                    }

                    console.log('--- Feature iframe loaded: ', event.data.id, ref_iframesLoaded.current);
                    checkIfFeatureReady();
                }
                break;
            case POST_MESSAGE_IN_TYPES.FEATURE_STARTED:
                console.log('Feature has started! We might need to emit a socket or something here?');
                socket.current.emit('featureStarted', { streamID: streamId, featureID: event.data.id, timestamp: timestamp });
                break;
            case POST_MESSAGE_IN_TYPES.FEATURE_ENDED:
                
                console.log('Feature has ended! We might need to emit a socket or something here?');
                socket.current.emit('featureEnded', { streamID: streamId, featureID: event.data.id, timestamp: timestamp });
                if (locallyTriggeredFeatureIds.indexOf(event.data.id) >= 0) {
                    // Directly tell our iframes this feature has ended...
                    locallyTriggeredFeatureIds.splice(locallyTriggeredFeatureIds.indexOf(event.data.id), 1);
                    postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, { featureId: event.data.id })
                }
                break;
            case POST_MESSAGE_IN_TYPES.CONTINUE_CHAIN:
                console.log('Feature wants to continue the chain! We might need to emit a socket or something here?');
                socket.current.emit('continueChain', { streamID: streamId, featureID: event.data.id, timestamp: timestamp });
                break;
            case POST_MESSAGE_IN_TYPES.AUDIO_ENABLED:
                console.log('We have interacted and therefore enabled audio! ', audioEnabled.current);
                audioEnabled.current = true;
                socket.current.emit('audioEnabled', { streamID: streamId, timestamp: timestamp });
                break;

            case POST_MESSAGE_IN_TYPES.TRIGGER_FEATURE:
                console.log('Feature ' + event.data.id + ' triggering another feature: ', event.data.featureId);
                // Find the correct command
                const triggerFeatureId = event.data.featureId.split(':')[0];
                let triggerCommandParam = 0;
                const featureData = findFeatureData(campaignData.current, triggerFeatureId);
                console.log('Looking for primary command, feature data: ', featureData);
                let commandString = '';
                if (featureData && featureData.feature && featureData.feature.commands) {
                    for (let i = 0; i < featureData.feature.commands.length; i++) {
                        if (featureData.feature.commands[i].isPrimaryTrigger) {
                            commandString = featureData.feature.commands[i].command;
                            if (featureData.feature.commands[i].triggerParam) {
                                triggerCommandParam = event.data.featureId.split(':')[1] || featureData.feature.commands[i].triggerParam.default || 0;
                            }
                        }
                    }
                }
                if (commandString) {
                    if (streamConnected.current) {
                        console.log('Emitting feature command to socket: ', commandString);
                        // We are connected - emit a socket message so our triggered feature gets logged and all that (just click clicking the start button)
                        socket.current.emit('triggerFeature', { streamID: streamId, timestamp: timestamp, featureID: event.data.id, triggerFeatureID: triggerFeatureId, command: commandString, commandParam: triggerCommandParam });
                    } else {
                        console.log('Emitting feature command direct to iframe: ', commandString);
                        // We are not connected, we can't do this with a socket emit, but still want to emulate the correct functionality.
                        // So directly send the command to the frame...
                        postMessageToFrame(triggerFeatureId, POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: commandString, extraData: { username: 'CampaignStudio', hashtag: 'Feature Triggered Command', commandParam: triggerCommandParam } });
                        if (locallyTriggeredFeatureIds.indexOf(triggerFeatureId) === -1) {
                            locallyTriggeredFeatureIds.push(triggerFeatureId);
                        }
                    }
                }
                break;

            case POST_MESSAGE_IN_TYPES.TRIGGER_FEATURE_END:
                console.log('Feature ' + event.data.id + ' triggering another feature to end: ', event.data.featureId);
                // Find the correct command
                const triggerEndFeatureId = event.data.featureId.split(':')[0];
                const featureData_end = findFeatureData(campaignData.current, triggerEndFeatureId);
                console.log('Looking for feature end command, feature data: ', featureData_end);
                let commandString_end = '';
                if (featureData_end) {
                    for (let i = 0; i < featureData_end.feature.commands.length; i++) {
                        if (featureData_end.feature.commands[i].isEndTrigger) {
                            commandString_end = featureData_end.feature.commands[i].command;
                        }
                    }
                }
                if (commandString_end) {
                    if (streamConnected.current) {
                        console.log('Emitting feature end command to socket: ', commandString_end);
                        // We are connected - emit a socket message so our triggered feature gets logged and all that (just click clicking the start button)
                        socket.current.emit('triggerFeatureEnd', { streamID: streamId, timestamp: timestamp, featureID: event.data.id, triggerFeatureID: triggerEndFeatureId, command: commandString_end });
                    } else {
                        console.log('Emitting feature end command direct to iframe: ', commandString_end);
                        // We are not connected, we can't do this with a socket emit, but still want to emulate the correct functionality.
                        // So directly send the command to the frame...
                        postMessageToFrame(triggerEndFeatureId, POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: commandString_end, extraData: { username: 'CampaignStudio', hashtag: 'Feature Triggered Command', commandParam: 0 } });
                    }
                }
                break;

            case POST_MESSAGE_IN_TYPES.REQUEST_QUIZ_DATA:
                console.log('Feature ' + event.data.id + ' requesting quiz data');
                // Axios call to get quiz data by ids
                axios.get(getQuizzesByIdsRoute, { params: { ids: event.data.quizIds } }).then(
                    (res) => {
                        console.log('Got quiz data: ', res);
                        // We need to update the quiz data to include the correct image srcUrls
                        for (let i = 0; i < res.data.quizzes.length; i++) {
                            injectQuizImageSrcUrls(res.data.quizzes[i]);
                        }
                        postMessageToFrame(event.data.id, POST_MESSAGE_OUT_TYPES.QUIZ_DATA, res.data, false);
                    }
                ).catch(
                    (err) => {
                        console.log('Error getting quiz data: ', err);
                    }
                )

                break;

            case POST_MESSAGE_IN_TYPES.LEADERBOARD_DATA:
                console.log('Feature ' + event.data.id + ' sent leaderboard data: ', event.data.leaderboardData, event.data.leaderboardId, event.data.saveLevel, event.data.replaceData, event.data);
                // Stream level save data isn't persistent, we just use the old method of forcing data to the leaderboard
                // NOTE: stream level isn't entirely correct, we still need to deal with event.data.replaceData === false in a different way
                if (event.data.leaderboardId && event.data.saveLevel /* && event.data.saveLevel !== DATA_SAVE_LEVELS.STREAM */) {
                    // We have a leaderboard disruptorId and a saveLevel, so we are going to update the leaderboard's data so it can be used later / carry on to multiple streams
                    if (event.data.saveLevel === DATA_SAVE_LEVELS.STREAM) {
                        // Just prepare the data into the leaderboardData object, no saving required
                        if (leaderboardData.current === null || leaderboardData.current === undefined) {
                            leaderboardData.current = [];
                        }
                        for (let i = 0; i < event.data.leaderboardData.length; i++) {
                            let found = false;
                            const entryData = {
                                name: event.data.leaderboardData[i].name ?? event.data.leaderboardData[i].username,
                                score: event.data.leaderboardData[i].score,
                                time: Date.now(),
                                showCopyOnZeroScore: false,
                                copy: ''
                            }
                            for (let j = 0; j < leaderboardData.current.length; j++) {
                                if (leaderboardData.current[j].name === entryData.name) {
                                    if (event.data.replaceData) {
                                        leaderboardData.current[j] = entryData;
                                    } else {
                                        leaderboardData.current[j].score += entryData.score;
                                    }
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                leaderboardData.current.push(entryData);
                            }
                        }
                        postMessageToFrame(event.data.leaderboardId, POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: 'RECEIVE_LEADERBOARD_DATA', extraData: { forcedScore: leaderboardData.current, forceShowForcedScore: true, forceListLimit: 25 } });
                    } else
                    if (event.data.saveLevel === DATA_SAVE_LEVELS.CHANNEL) {
                        // Channel high scores are stores as multiStreamData in featureVars, the same way that competitive challenge data is stored.
                        // We wil need to pull the data out, update it and then save it back.
                        // Finally we will use the forcedScore data to tell the leaderboard to use this data instead of the normal data from the customisations.
                        // The result is persistent scores across streams on a per-channel basis.
                        const multiStreamTag = getMultiStreamTag();
                        let scoresData = null;
                        for (let prop in campaignLevelFeatureVars.current.current) {
                            if (campaignLevelFeatureVars.current.current[prop].multiStreamData && campaignLevelFeatureVars.current.current[prop].multiStreamData[multiStreamTag]) {
                                const taggedData = campaignLevelFeatureVars.current.current[prop].multiStreamData[multiStreamTag];
                                if (taggedData.hasOwnProperty('leaderboard')) {
                                    scoresData = taggedData.leaderboard;
                                    break;
                                }
                            }
                        }
                        if (scoresData === null) {
                            scoresData = { entries: [] };
                        }
                        // Now we need to update the scores...
                        // Any entry with a matching name will be updated (replaced if event.data.replaceData id true, score increased if not),
                        // otherwise a new entry will be added.
                        for (let i = 0; i < event.data.leaderboardData.length; i++) {
                            let found = false;
                            const entryData = {
                                name: event.data.leaderboardData[i].name ?? event.data.leaderboardData[i].username,
                                score: event.data.leaderboardData[i].score,
                                time: Date.now(),
                                showCopyOnZeroScore: false,
                                copy: ''
                            }
                            for (let j = 0; j < scoresData.entries.length; j++) {
                                if (scoresData.entries[j].name === entryData.name) {
                                    if (event.data.replaceData) {
                                        scoresData.entries[j] = entryData;
                                    } else {
                                        scoresData.entries[j].score += entryData.score;
                                    }
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                scoresData.entries.push(entryData);
                            }
                        }
                        // Now save the data
                        saveMultiStreamData(event.data.leaderboardId, { leaderboard: scoresData });
                        leaderboardData.current = scoresData.entries;
                        postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: 'RECEIVE_LEADERBOARD_DATA', extraData: { forcedScore: leaderboardData.current, forceShowForcedScore: true, forceListLimit: 25 } });
                    } else {
                        // Global high scores are stored in the customisation data.
                        // So we use a similar method to addScoreToCustomisationData, but we are going to update multiple entries in one go.
                        const leaderboardFeatureId = event.data.leaderboardId;
                        if (customisationVars.current.current.hasOwnProperty(leaderboardFeatureId)) {
                            const featureData = findFeatureData(campaignData.current, leaderboardFeatureId);
                            const leaderboardCustomisationVars_stream = customisationVars.current.current[leaderboardFeatureId];
                            // const leaderboardCustomisationVars_campaign = campaignLevelCustomisationVars.current.current[leaderboardFeatureId];
                            console.log('Leaderboard customisation data: ', campaignData, leaderboardFeatureId, featureData, leaderboardCustomisationVars_stream/* , leaderboardCustomisationVars_campaign */);
                            if (featureData && leaderboardCustomisationVars_stream) {
                                // We always update at stream level, but we only update at campaign level if the saveLevel is set to GLOBAL.
                                const streamLevelLeaderboardDataEntries = leaderboardCustomisationVars_stream.leaderboard.leaderboardData.entries;
                                // const campaignLevelLeaderboardDataEntries = leaderboardCustomisationVars_campaign.leaderboard.leaderboardData.entries;
                                for (let i = 0; i < event.data.leaderboardData.length; i++) {
                                    let found = false;
                                    const entryData = {
                                        name: event.data.leaderboardData[i].name ?? event.data.leaderboardData[i].username,
                                        score: event.data.leaderboardData[i].score,
                                        time: Date.now(),
                                        showCopyOnZeroScore: false,
                                        copy: ''
                                    }
                                    for (let j = 0; j < streamLevelLeaderboardDataEntries.length; j++) {
                                        if (streamLevelLeaderboardDataEntries[j].name === entryData.name) {
                                            if (event.data.replaceData) {
                                                streamLevelLeaderboardDataEntries[j] = entryData;
                                            } else {
                                                streamLevelLeaderboardDataEntries[j].score += entryData.score;
                                            }
                                            streamLevelLeaderboardDataEntries[j] = entryData.time;
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        streamLevelLeaderboardDataEntries.push(entryData);
                                    }
                                    /* if (event.data.saveLevel === DATA_SAVE_LEVELS.GLOBAL) {
                                        found = false;
                                        for (let j = 0; j < campaignLevelLeaderboardDataEntries.length; j++) {
                                            if (campaignLevelLeaderboardDataEntries[j].name === event.data.leaderboardData[i].name) {
                                                if (event.data.replaceData) {
                                                    campaignLevelLeaderboardDataEntries[j] = event.data.leaderboardData[i];
                                                } else {
                                                    campaignLevelLeaderboardDataEntries[j].score += event.data.leaderboardData[i].score;
                                                }
                                                found = true;
                                                break;
                                            }
                                        }
                                        if (!found) {
                                            campaignLevelLeaderboardDataEntries.push(event.data.leaderboardData[i]);
                                        }
                                    } */
                                }
                            }
                            // Entries updated, now we need to save the data
                            // Just save stream level for now, I need to figure out if the current medthos is saving stream level or campaign level data
                            reportCustomisationVarsChanged(customisationVars.current, leaderboardFeatureId);
                        }

                    }
                } else {
                    // No leaderboard disruptorId or streamLevel, so we are just going to use this data for this stream only (it won't be persistent)
                    leaderboardData.current = event.data.leaderboardData;
                    postMessageToAllFrames(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: 'RECEIVE_LEADERBOARD_DATA', extraData: { forcedScore: leaderboardData.current } });
                }
                break;

            // Why is this seemingly in here twice? Well the above LEADERBOARD_DATA isn't persistent, this one is.
            // This also comes from the challenge list, the other one is from the quizzes.
            case POST_MESSAGE_IN_TYPES.POST_LEADERBOARD_DATA:
                console.log('Feature ' + event.data.id + ' posted a score: ', event.data.scoreData.score, event.data.scoreData.playerName);
                addScoreToCustomisationData(event.data.id, event.data.scoreData.score, event.data.scoreData.playerName);
                break;

            case POST_MESSAGE_IN_TYPES.REPORT_ARCADE_DATA:
                console.log('Feature ' + event.data.id + ' sent arcade data: ', event.data.arcadeData);
                const arcadeData = event.data.arcadeData;
                const frameId = event.data.id;
                // Insert stream Id and feature Id
                const dataToSend = {
                    streamID: streamId,
                    featureID: findFeatureData(campaignData.current, frameId).feature._id,
                    entryData: arcadeData,
                }
                // Now report it using axios
                axios.post(createArcadeEntriesRoute, dataToSend).then(
                    (res) => {
                        console.log('Arcade data reported: ', res);
                    }
                ).catch(
                    (err) => {
                        console.log('Error reporting arcade data: ', err);
                    }
                )
                break;

            case POST_MESSAGE_IN_TYPES.SET_STREAM_MARKER:
                console.log('Feature ' + event.data.id + ' set a stream marker: ', event.data.description);
                setStreamMarker(event.data.confirmationID, event.data.description);
                break;

            case POST_MESSAGE_IN_TYPES.POST_FEATURE_DATA:
                console.log('Feature ' + event.data.id + ' posted some data: ', event.data.featureData);
                updateFeatureVars(event.data.id, event.data.featureData);

                // Data can be flagged as multi stream data. This flag can exist top level or within an object in the sent data or within an array.
                // A single flag means the entire data is treated as multi stream and needs to be tagged with our channel list and saved on the campaign level.
                // Later when we retrieve the streamVars we will need to pull out of multi stream data and inject it into the stream level data for the feature to use.
                let isMultiStreamData = false;
                if (event.data.featureData.hasOwnProperty('enableMultiStream') && event.data.featureData.enableMultiStream === true) {
                    isMultiStreamData = true;
                }
                if (!isMultiStreamData) {
                    for (let p in event.data.featureData) {
                        if (event.data.featureData[p].hasOwnProperty('enableMultiStream') && event.data.featureData[p].enableMultiStream === true) {
                            isMultiStreamData = true;
                            break;
                        }
                        if (Array.isArray(event.data.featureData[p])) {
                            for (let i = 0; i < event.data.featureData[p].length; i++) {
                                if (event.data.featureData[p][i].hasOwnProperty('enableMultiStream') && event.data.featureData[p][i].enableMultiStream === true) {
                                    isMultiStreamData = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (isMultiStreamData) {
                    saveMultiStreamData(event.data.id, event.data.featureData);
                }
                break;

            case POST_MESSAGE_IN_TYPES.CLAW_PLAY_MOVE:
                if(randomFeatureID.current == masterFeatureID.current)
                {
                    console.log('Feature ' + event.data.id + ' wants to play a claw move: ', event.data.moveData);
                    socket.current.emit('clawPlayMoveSet', { streamID: streamId, xPerc: event.data.moveData.xPerc, yPerc: event.data.moveData.yPerc, machineID: event.data.moveData.machineId, timestamp: timestamp });
                }
              break;

            default:
                break;
        }
    }

    const postMessageToAllFrames = (type, detail) => {
        console.log('POST TO ALL FRAMES')
        if (loaded_ref.current) {
            for (let prop in ref_iframesLoaded.current) {
                postMessageToFrame(prop, type, detail);
            }
        }
    }
    const postMessageToFrame = (frame, type, detail, onlyIfLoaded = true) => {
        console.log('Post to frame called. ', frame, ref_iframesLoaded);
        console.log(detail)
        if (!onlyIfLoaded || ref_iframesLoaded.current[frame]) {
            const frameRef = document.getElementById(frame);
            if (frameRef) {
                console.log('Post to frame: ', frame, frameRef, type, detail);
                frameRef.contentWindow.postMessage({ type: type, detail: detail }, '*');

            }
        }
    }

    const setUpPostMessageListener = () => {
        window.addEventListener(
            "message",
            processPostedMessages
        );
    }

    const setStreamMarker = (confirmationID, description) => {
        if (socket.current) {
            if (typeof confirmationID === 'undefined' || confirmationID === null) {
                confirmationID = 'testID_' + Math.random().toString(36).substr(2, 9) + '_testID';
            }
            if (typeof description === 'undefined' || description === null) {
                description = 'No description provided';
            }
            const timestamp = Date.now();
            const markerData = { streamID: streamId, timestamp: timestamp, confirmationID: confirmationID, description: description };
            console.log('Setting marker: ', markerData);
            socket.current.emit('setStreamMarker', markerData);
        }
    }

    useEffect(
        () => {
            setUpPostMessageListener();
            window.onbeforeunload = () => {
                if (socket.current) {
                    if (masterFeatureID.current === randomFeatureID.current) {
                        console.log('MASTER FEATURE')
                        socket.current.emit('setMasterFeature', {streamID: streamId, masterID:0});
                    }

                    const timestamp = Date.now();
                    socket.current.emit('featureUnloaded', { streamID: streamId, timestamp: timestamp, featureID: randomFeatureID.current });
                }
            }
            window.setStreamMarker = setStreamMarker;
        }, []
    )

    return (
        <div>
            {pageExpired &&
                <div className="feature-expired">
                    <div className="content">
                        <img alt="ICAST Logo" src={assets.icastTopLogo} />
                        <h1>This page has expired</h1>
                    </div>
                </div>
            }

            {!pageExpired && showPreviewBg &&
                <div className={`preview-bg${randomPreviewBG > .5 ? ' preview-random-1' : ' preview-random-2'}`}></div>
            }
            {!pageExpired && !loaded &&
                <div className="feature-loading">
                    <img className="glitch" alt="Twitch Glitch 2" src={assets.LoadingFeature} />
                </div>
            }
            {!pageExpired && iframesLoaded && Object.entries(iframesLoaded).map(
                ([k, v], i) => {
                    return (
                        <div className={`feature-content`} key={k}>
                            {/* The csl class sets our contentSceneLibrary frame to a high z-index (999) meaning it will always appear over the top of evrything else */}
                            <iframe
                                ref={k === 'contentSceneLibrary' ? CSLRef : null}
                                title={k}
                                id={k}
                                name={k}
                                 className={k === 'contentSceneLibrary' ? 'csl' : ''}
                                src={featureVars.current.current[k].featureURL}
                                style={k !== 'contentSceneLibrary' ? { zIndex: 450 + (findFeature(k, campaignContext.campaignData).stackingOrder || 0) } : {}}
                            />
                        </div>
                    );
                }
            )}

           
            {!pageExpired && cslActivate &&          
                <div className={`${isMaster ? ' masterBox' : ' slaveBox'}`}>
                    {isMaster ? 'PRIMARY' : 'SECONDARY'}
                </div> 
            }

        </div>
        
    );
}

export default Home;
