export const FEATURE_TYPES = [
    { label: 'Feature', value: 'feature' },
    { label: 'Disruptor', value: 'disruptor' },
]

export const COLOUR_CATEGORIES = [
    { label: 'Use Var Key / Default', value: '' },
    { label: 'Image', value: 'image' },
    { label: 'Video', value: 'video' },
    { label: 'Audio', value: 'audio' },
    { label: 'Animated', value: 'animated' },
    { label: 'Mini Feature', value: 'miniFeature' },
    { label: 'Quiz', value: 'quiz_list' },
    { label: 'Leaderboard', value: 'leaderboard_list' },
]

export const EVAL_CONDITION_ITEMS = [
    { value: '==', label: '==' },
    { value: '!=', label: '!=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'OR', label: 'OR' },
];

export const ICONS_OPTIONS = [
    { value: '', label: 'Manual Entry' },
    { value: 'play', label: 'Play' },
    { value: 'stop', label: 'Stop' },
    { value: 'pause', label: 'Pause' },
    { value: 'skip', label: 'Skip' },
    { value: 'plus', label: 'Plus' },
    { value: 'check', label: 'Check' },
    { value: 'checkX', label: 'Cross' },
    { value: 'cog', label: 'Cog' },
    { value: 'star', label: 'Star' },
    { value: 'halfStar', label: 'Half Star' },
    { value: 'circle', label: 'Circle' },
    { value: 'trash', label: 'Trash' },
    { value: 'interval', label: 'Interval' },
    { value: 'automatic', label: 'Stopwatch' },
    { value: 'clock', label: 'Clock' },
    { value: 'manual', label: 'Hand' },
    { value: 'response', label: 'Reply' },
    { value: 'link', label: 'Link' },
    { value: 'paperPlane', label: 'Paper Plane' },
    { value: 'image', label: 'Image' },
    { value: 'video', label: 'Video' },
    { value: 'music', label: 'Audio' },
    { value: 'film', label: 'Film' },
    { value: 'error', label: 'Error' },
    { value: 'powerOff', label: 'Power Off' },
    { value: 'handshake', label: 'Handshake' },
    { value: 'heart', label: 'Heart' },
    { value: 'eye', label: 'Eye' },
    { value: 'eyeSlash', label: 'Eye Slash' },
    { value: 'plug', label: 'Plug' },
    { value: 'bot', label: 'Bot' },
    { value: 'bullhorn', label: 'Bullhorn' },
    { value: 'list', label: 'List' },
    { value: 'magic', label: 'Magic' },
    { value: 'lightningBolt', label: 'Lightning Bolt' },
    { value: 'comments', label: 'Comments' },
    { value: 'butIcon_arrowRight1', label: 'Arrow Right 1' },
    { value: 'butIcon_arrowRight2', label: 'Arrow Right 2' },
    { value: 'butIcon_arrowRight3', label: 'Arrow Right 3' },
    { value: 'butIcon_arrowUp1', label: 'Arrow Up 1' },
    { value: 'butIcon_arrowUp2', label: 'Arrow Up 2' },
    { value: 'butIcon_arrowUp3', label: 'Arrow Up 3' },
    { value: 'butIcon_arrowDown1', label: 'Arrow Down 1' },
    { value: 'butIcon_arrowDown2', label: 'Arrow Down 2' },
    { value: 'butIcon_arrowDown3', label: 'Arrow Down 3' },
    { value: 'butIcon_arrowLeft1', label: 'Arrow Left 1' },
    { value: 'butIcon_arrowLeft2', label: 'Arrow Left 2' },
    { value: 'butIcon_arrowLeft3', label: 'Arrow Left 3' },
    { value: 'butIcon_squareFull', label: 'Square Full' },
];

export const FORCE_WIDTH_OPTIONS = [
    { value: '', label: 'Manual Entry' },
    { value: 'min', label: 'Min' },
    { value: 18, label: '1/5' },
    { value: 22, label: '1/4' },
    { value: 31, label: '1/3' },
    { value: 48, label: '1/2' },
    { value: 64, label: '2/3' },
    { value: 58, label: '3/5' },
    { value: 73, label: '3/4' },
    { value: 100, label: '100%' },
    { value: 'full', label: 'Fill Space' },
];

export const BUTTON_WIDTH_OPTIONS = [
    { value: '', label: 'Manual Entry' },
    { value: 30, label: 'Default' },
    { value: 17, label: '1/5' },
    { value: 22, label: '1/4' },
    { value: 30, label: '1/3' },
    { value: 48, label: '1/2' },
    { value: 64, label: '2/3' },
    { value: 73, label: '3/4' },
    { value: 100, label: '100%' },
];

export const BUTTON_COLOUR_OPTIONS = [
    { value: '', label: 'Auto' },
    { value: 'purple', label: 'Purple' },
    { value: 'green', label: 'Green' },
    { value: 'red', label: 'Red' },
    { value: 'orange', label: 'Orange' },
];

export const BOX_TYPES = [
    { id: 'none', value: '', label: 'None' },
    { id: 'box', value: 'box', label: 'Box' },
    { id: 'outlinebox', value: 'outlineBox', label: 'Outline Box' },
];

export const PANEL_STYLE_PRESETS = {
    dummy: {
        name: 'Select Style',
        style: {}
    },
    bigPurpleOutline: {
        name: 'Big Purple Outline',
        style: {
            addBox: true,
            addOutlineBox: false,
            addVarBox: true,
            addVarBoxOutline: false,
            boxColour: '#DCD3FF',
            varBoxColour: '#FFFFFF',
        }
    },
    bigGreyOutline: {
        name: 'Big Grey Outline',
        style: {
            addBox: true,
            addOutlineBox: false,
            addVarBox: true,
            addVarBoxOutline: false,
            boxColour: '#EFF0F6',
            varBoxColour: '#FFFFFF',
        }
    },
    outlineBoxOnly: {
        name: 'Outline Box Only',
        style: {
            addBox: false,
            addOutlineBox: true,
            addVarBox: false,
            addVarBoxOutline: false,
            boxColour: '#EFF0F6',
            varBoxColour: '#EFF0F6',
        }
    },
    outLineBoxWithGreyFill: {
        name: 'Outline with Grey Inner',
        style: {
            addBox: false,
            addOutlineBox: true,
            addVarBox: true,
            addVarBoxOutline: false,
            boxColour: '#EFF0F6',
            varBoxColour: '#EFF0F6',
        }
    },
    greyInnerBox: {
        name: 'Grey Inner Box',
        style: {
            addVarBox: true,
            varBoxColour: '#EFF0F6',
        }
    },
    whiteInnerBox: {
        name: 'White Inner Box',
        style: {
            addVarBox: true,
            varBoxColour: '#FFFFFF',
        }
    },
    noBox: {
        name: 'No Box',
        style: {
            addBox: false,
            addOutlineBox: false,
            addVarBox: false,
            addVarBoxOutline: false,
        }
    },
    defaultColours: {
        name: 'Default Colours',
        style: {
            boxColour: '#EFF0F6',
            varBoxColour: '#EFF0F6',
        }
    }
}

export const PREDEFINED_VAR_SCHEMA_BLOCKS = {
    toggle: {
        "toggle": {
            "label": "Toggle",
            "type": "toggle",
            "default": true,
        }
    },
    radioButtonH: {
        "radio": {
            "label": "Select One",
            "type": "radio",
            "default": "option1",
            "labelNote": "",
            "valOptions": [
                {
                    "label": "Option 1",
                    "value": "option1"
                },
                {
                    "label": "Option 2",
                    "value": "option2"
                },
                {
                    "label": "Option 3",
                    "value": "option3"
                }
            ]
        }
    },
    dropDown: {
        "dropdown": {
            "label": "Select One",
            "type": "dropdown",
            "default": "option1",
            "labelNote": "",
            "valOptions": [
                {
                    "label": "Option 1",
                    "value": "option1"
                },
                {
                    "label": "Option 2",
                    "value": "option2"
                },
                {
                    "label": "Option 3",
                    "value": "option3"
                },
                {
                    "label": "Option 4",
                    "value": "option4"
                },
                {
                    "label": "Option 5",
                    "value": "option5"
                }
            ]
        }
    },
    slider: {
        "slider": {
            "type": "slider",
            "showCurrentValue": true,
            "minVal": 0,
            "maxVal": 100,
            "default": 50,
            "label": "Slider",
            "displayCondition": [],
        }
    },
    colourPickerPurple: {
        "colour": {
            "label": "Choose Colour",
            "type": "colour",
            "default": "#9146FF",
        }
    },
    imageUploader: {
        "image": {
            "label": "Upload Image",
            "type": "image",
            "default": "",
        }
    },
    columnBreak: {
        "columnBreak": {
            "type": "column-break",
            "label": "Column Break",
            "default": true,
        }
    },
    columnBreakSubTitle: {
        "columnBreak": {
            "type": "column-break",
            "label": "Column Break",
            "default": true,
        },
        "subTitle": {
            "label": "Sub Title",
            "type": "subtitle",
            "default": "",
        }
    },
    subTitle: {
        "subTitle": {
            "label": "Sub Title",
            "type": "subtitle",
            "default": "",
        }
    },
    sectionBreak: {
        "sectionBreak": {
            "type": "section-break",
            "label": "Section Break",
            "default": true,
        }
    },
    fontSettings: {
        "font": {
            "label": "Font",
            "type": "font",
            "default": "Roboto",
        },
        "fontSize": {
            "type": "slider",
            "showCurrentValue": true,
            "minVal": 8,
            "maxVal": 72,
            "default": 16,
            "label": "Font Size",
            "displayCondition": [],
        },
        "fontColour": {
            "label": "Colour",
            "type": "colour",
            "default": "#000000",
        },
        "isBold": {
            "label": "Is Bold",
            "type": "toggle",
            "default": true,
        },
    },
    animateSettings:
    {
        "type": {
            "type": "dropdown",
            "label": "Tween",
            "default": "fade",
            "valOptions": [
                {
                    "label": "Fade",
                    "value": "fade"
                },
                {
                    "label": "Pop",
                    "value": "pop"
                },
                {
                    "label": "Slide From Left",
                    "value": "slide-left"
                },
                {
                    "label": "Slide From Right",
                    "value": "slide-right"
                },
                {
                    "label": "Slide From Top",
                    "value": "slide-top"
                },
                {
                    "label": "Slide From Bottom",
                    "value": "slide-bottom"
                }
            ],
            "description": "Choose the animation type",
        },
        "time": {
            "type": "Number",
            "default": 500,
            "label": "Time (ms)",
            "description": "The amount of time it takes to animate in",
            "advancedMode": true,
        },
        "delay": {
            "type": "Number",
            "default": 200,
            "label": "Delay (ms)",
            "description": "How many milliseconds to wait until the animation starts. Use this to offset animations",
            "advancedMode": true,
        },
        "ease": {
            "type": "dropdown",
            "label": "Easing",
            "default": "circ",
            "valOptions": [
                {
                    "label": "Circ",
                    "val": "circ"
                },
                {
                    "label": "Back",
                    "val": "back"
                },
                {
                    "label": "Bounce",
                    "val": "bounce"
                },
                {
                    "label": "No Easing",
                    "val": "none"
                }
            ],
            "description": "Choose the easing type",
            "advancedMode": true,
        }
    },
    positionSettings: {
        "position": {
            "type": "dropdown",
            "label": "Position",
            "default": "top-center",
            "valOptions": [
                {
                    "label": "Top Left",
                    "value": "top-left",
                },
                {
                    "label": "Top Center",
                    "value": "top-center",
                },
                {
                    "label": "Top Right",
                    "value": "top-right"
                },
                {
                    "label": "Middle Left",
                    "value": "middle-left"
                },
                {
                    "label": "Middle Center",
                    "value": "middle-center"
                },
                {
                    "label": "Middle Right",
                    "value": "middle-right"
                },
                {
                    "label": "Bottom Left",
                    "value": "bottom-left"
                },
                {
                    "label": "Bottom Center",
                    "value": "bottom-center"
                },
                {
                    "label": "Bottom Right",
                    "value": "bottom-right"
                }
            ],
        },
        "xOffset": {
            "label": "X Offset (px)",
            "type": "slider",
            "default": 0,
            "minVal": -960,
            "maxVal": 960,
            "useTextLabels": false,
            "showCurrentValue": true,
            "advancedMode": true
        },
        "yOffset": {
            "label": "Y Offset (px)",
            "type": "slider",
            "default": 0,
            "minVal": -540,
            "maxVal": 540,
            "useTextLabels": false,
            "showCurrentValue": true,
            "advancedMode": true
        }
    },
    imageBgSettings: {
        "bgTitle": {
            "label": "Background",
            "type": "subtitle",
            "default": ""
        },
        "bgType": {
            "label": "Type",
            "type": "dropdown",
            "default": "solidColour",
            "valOptions": [
                {
                    "label": "Solid Colour",
                    "val": "solidColour",
                    "index": 0,
                    "value": "solidColour"
                },
                {
                    "label": "Gradient",
                    "val": "",
                    "value": "gradient",
                    "index": 1
                },
                {
                    "label": "Image",
                    "val": "",
                    "value": "image",
                    "index": 2
                },
                {
                    "label": "None",
                    "val": "",
                    "value": "none",
                    "index": 3
                }
            ]
        },
        "bgColour": {
            "label": "Choose Colour",
            "type": "colour",
            "default": "#999999",
            "displayCondition": [
                {
                    "var": "bgType",
                    "evalCondition": "==",
                    "val": "solidColour"
                }
            ]
        },
        "bgGradientFrom": {
            "label": "Gradient From",
            "type": "colour",
            "default": "#333333",
            "displayCondition": [
                {
                    "var": "bgType",
                    "evalCondition": "==",
                    "val": "gradient"
                }
            ]
        },
        "bgGradientTo": {
            "label": "Gradient To",
            "type": "colour",
            "default": "#DDDDDD",
            "displayCondition": [
                {
                    "var": "bgType",
                    "evalCondition": "==",
                    "val": "gradient"
                }
            ]
        },
        "bgGradientAngle": {
            "label": "Gradient Angle",
            "type": "slider",
            "default": 0,
            "minVal": -180,
            "maxVal": 180,
            "showCurrentValue": true,
            "useTextLabels": false,
            "displayCondition": [
                {
                    "var": "bgType",
                    "evalCondition": "==",
                    "val": "gradient"
                }
            ]
        },
        "bgImage": {
            "label": "Background Image",
            "type": "image",
            "default": "",
            "displayCondition": [
                {
                    "var": "bgType",
                    "evalCondition": "==",
                    "val": "image"
                }
            ]
        },
        "bgOpacity": {
            "label": "Opacity",
            "type": "slider",
            "default": "100",
            "minVal": 0,
            "maxVal": 100,
            "showCurrentValue": true,
            "useTextLabels": true,
            "minLabel": "0%",
            "maxLabel": "100%",
            "midLabel": "50%",
            "displayCondition": [
                {
                    "var": "bgType",
                    "evalCondition": "!=",
                    "val": "none"
                }
            ]
        }
    },
    pippets: {
        "showPippets": {
            "label": "Show Pippets",
            "type": "toggle",
            "default": true
        },
        "pippetInactiveColour": {
            "label": "Inactive Colour",
            "type": "colour",
            "default": "#DDDDDD",
            "displayCondition": [
                {
                    "var": "showPippets",
                    "evalCondition": "==",
                    "val": true
                }
            ]
        },
        "pippetActiveColour": {
            "label": "Active Colour",
            "type": "colour",
            "default": "#9146FF",
            "displayCondition": [
                {
                    "var": "showPippets",
                    "evalCondition": "==",
                    "val": true
                }
            ]
        },
        "pippetBorderColour": {
            "label": "Border Colour",
            "type": "colour",
            "default": "#FFFFFF",
            "displayCondition": [
                {
                    "var": "showPippets",
                    "evalCondition": "==",
                    "val": true
                }
            ]
        },
        "pippetBorderThickness": {
            "label": "Border Thickness",
            "type": "slider",
            "default": "2",
            "minVal": 0,
            "maxVal": 10,
            "useTextLabels": true,
            "minLabel": "0px",
            "midLabel": "5px",
            "maxLabel": "10px",
            "displayCondition": [
                {
                    "var": "showPippets",
                    "evalCondition": "==",
                    "val": true
                }
            ]
        },
        "pippetsInfoText": {
            "label": "",
            "type": "description-string",
            "default": "",
            "displayCondition": [
                {
                    "var": "showPippets",
                    "evalCondition": "==",
                    "val": false
                }
            ],
            "description": "Progress pippets display the current<br /> page of information."
        }
    }
}

export const PREDEFINED_GROUPS = {
    ProgressBar: {
        "groupTitle": "Progress Bar",
        "varKey": "appearance",
        "forceWidth": 100,
        "variables": [
            {
                "label": "Position",
                "description": "",
                "varName": "position",
                "forceWidth": 18.4,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": false,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "position": {
                        "type": "dropdown",
                        "label": "Position",
                        "default": "bottom-center",
                        "valOptions": [
                            {
                                "label": "Top Left",
                                "value": "top-left"
                            },
                            {
                                "label": "Top Center",
                                "value": "top-center"
                            },
                            {
                                "label": "Top Right",
                                "value": "top-right"
                            },
                            {
                                "label": "Middle Left",
                                "value": "middle-left"
                            },
                            {
                                "label": "Middle Center",
                                "value": "middle-center"
                            },
                            {
                                "label": "Middle Right",
                                "value": "middle-right"
                            },
                            {
                                "label": "Bottom Left",
                                "value": "bottom-left"
                            },
                            {
                                "label": "Bottom Center",
                                "value": "bottom-center",
                                "index": 7
                            },
                            {
                                "label": "Bottom Right",
                                "value": "bottom-right"
                            }
                        ]
                    },
                    "xOffset": {
                        "label": "X Offset (px)",
                        "type": "slider",
                        "default": 0,
                        "minVal": -960,
                        "maxVal": 960,
                        "useTextLabels": false,
                        "showCurrentValue": true,
                        "advancedMode": false
                    },
                    "yOffset": {
                        "label": "Y Offset (px)",
                        "type": "slider",
                        "default": -100,
                        "minVal": -540,
                        "maxVal": 540,
                        "useTextLabels": false,
                        "showCurrentValue": true,
                        "advancedMode": false
                    }
                },
                "vals": [
                    {
                        "position": "bottom-center",
                        "xOffset": 0,
                        "yOffset": -100,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [],
                "addVarBoxOutline": false
            },
            {
                "label": "Shape",
                "description": "Choose the shape of the progress bar.",
                "varName": "shape",
                "forceWidth": 18.4,
                "valSchema": {
                    "subTitle_56": {
                        "label": "Sub Title",
                        "type": "line-spacer",
                        "default": ""
                    },
                    "shape": {
                        "type": "dropdown",
                        "label": "Choose Shape",
                        "default": "rounded",
                        "valOptions": [
                            {
                                "label": "Square Corners",
                                "value": "square",
                                "index": 0
                            },
                            {
                                "label": "Rounded Corners",
                                "value": "rounded",
                                "index": 1
                            },
                            {
                                "label": "Skewed",
                                "value": "angled",
                                "index": 2
                            }
                        ]
                    },
                    "roundness": {
                        "label": "Roundness (%)",
                        "type": "slider",
                        "minVal": 0,
                        "maxVal": 100,
                        "default": 20,
                        "displayCondition": [
                            {
                                "var": "shape",
                                "val": "rounded",
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "skew": {
                        "label": "Skew",
                        "type": "slider",
                        "minVal": 0,
                        "maxVal": 100,
                        "default": 35,
                        "displayCondition": [
                            {
                                "var": "shape",
                                "val": "angled",
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "length": {
                        "label": "Length (%)",
                        "type": "slider",
                        "minVal": 0,
                        "maxVal": 100,
                        "default": 50,
                        "advancedMode": true
                    },
                    "thickness": {
                        "label": "Thickness",
                        "type": "slider",
                        "minVal": 20,
                        "maxVal": 120,
                        "default": 40,
                        "showCurrentValue": true,
                        "useTextLabels": true,
                        "minLabel": "Thinner",
                        "maxLabel": "Thicker",
                        "advancedMode": true
                    }
                },
                "vals": [
                    {
                        "shape": "rounded",
                        "roundness": 20,
                        "skew": 35,
                        "length": 50,
                        "thickness": 40,
                        "isDefault": true
                    }
                ],
                "addOutlineBox": true,
                "addVarBox": false,
                "addBox": false,
                "addVarBoxOutline": false,
                "boxColour": "#EFF0F6",
                "varBoxColour": "#EFF0F6"
            },
            {
                "label": "Outline",
                "description": "Style the outline of your progress bar.",
                "varName": "outline",
                "addBox": false,
                "forceWidth": 18.35,
                "valSchema": {
                    "subTitle_5": {
                        "label": "Sub Title",
                        "type": "line-spacer",
                        "default": ""
                    },
                    "addBorder": {
                        "label": "Add Outline",
                        "type": "toggle",
                        "default": true
                    },
                    "bgPanelInfoText17184": {
                        "label": "",
                        "type": "description-string",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "addBorder",
                                "evalCondition": "!=",
                                "val": true
                            }
                        ],
                        "description": "No Border Shown"
                    },
                    "colour": {
                        "type": "colour",
                        "label": "Outline Colour",
                        "default": "#000000",
                        "displayCondition": [
                            {
                                "var": "addBorder",
                                "evalCondition": "==",
                                "val": true
                            }
                        ]
                    },
                    "thickness": {
                        "type": "slider",
                        "label": "Outline Thickness",
                        "default": 3,
                        "advancedMode": true,
                        "valOptions": [
                            {
                                "label": "1 pixel",
                                "value": 1
                            },
                            {
                                "label": "2 pixels",
                                "value": 2
                            },
                            {
                                "label": "3 pixels",
                                "value": 3
                            },
                            {
                                "label": "4 pixels",
                                "value": 4
                            },
                            {
                                "label": "5 pixels",
                                "value": 5
                            }
                        ],
                        "minVal": 1,
                        "maxVal": 10,
                        "displayCondition": [
                            {
                                "var": "addBorder",
                                "evalCondition": "==",
                                "val": true
                            }
                        ]
                    }
                },
                "vals": [
                    {
                        "addBorder": true,
                        "colour": "#000000",
                        "thickness": 3,
                        "isDefault": true
                    }
                ],
                "addOutlineBox": true
            },
            {
                "label": "Background Fill ",
                "description": "Style the fill of your progress bar.",
                "varName": "background",
                "addBox": false,
                "forceWidth": 18.4,
                "valSchema": {
                    "lineSpacer": {
                        "type": "line-spacer",
                        "default": ""
                    },
                    "fillType": {
                        "type": "dropdown",
                        "label": "Choose Fill Type",
                        "default": "solid",
                        "valOptions": [
                            {
                                "label": "Solid Colour",
                                "value": "solid"
                            },
                            {
                                "label": "Striped",
                                "value": "striped"
                            },
                            {
                                "label": "Image",
                                "value": "image"
                            }
                        ]
                    },
                    "stripeDirection": {
                        "type": "dropdown",
                        "label": "Stripe Direction",
                        "default": "diagonal",
                        "valOptions": [
                            {
                                "label": "Diagonal",
                                "value": "diagonal"
                            },
                            {
                                "label": "Straight",
                                "value": "straight"
                            }
                        ],
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "stripeColour1": {
                        "type": "colour",
                        "label": "Colour 1",
                        "default": "#4c3939",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "stripeColour2": {
                        "type": "colour",
                        "label": "Colour 2",
                        "default": "#240f0f",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "animateStripes": {
                        "type": "toggle",
                        "label": "Animate Stripes",
                        "default": true,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "reverseStripeAnim": {
                        "type": "toggle",
                        "label": "Reverse Animation",
                        "default": false,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            },
                            {
                                "var": "animateStripes",
                                "val": true,
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "solidColour": {
                        "type": "colour",
                        "label": "Colour",
                        "default": "#14142B",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "solid",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "tileImage": {
                        "type": "toggle",
                        "label": "Tile Image",
                        "default": false,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "filename": {
                        "type": "Image",
                        "label": "Image File",
                        "labelNote": "Recommended size: 65px x 130px",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "animateImage": {
                        "type": "toggle",
                        "label": "Animate Image",
                        "default": true,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            },
                            {
                                "var": "tileImage",
                                "val": true,
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "reverseImageAnim": {
                        "type": "toggle",
                        "label": "Reverse Animation",
                        "default": true,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            },
                            {
                                "var": "animateImage",
                                "val": true,
                                "evalCondition": "=="
                            },
                            {
                                "var": "tileImage",
                                "val": true,
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    }
                },
                "vals": [
                    {
                        "fillType": "solid",
                        "stripeDirection": "diagonal",
                        "stripeColour1": "#4c3939",
                        "stripeColour2": "#240f0f",
                        "animateStripes": true,
                        "reverseStripeAnim": false,
                        "solidColour": "#14142B",
                        "tileImage": false,
                        "filename": "",
                        "animateImage": true,
                        "reverseImageAnim": true,
                        "isDefault": true
                    }
                ],
                "addOutlineBox": true
            },
            {
                "label": "Foreground Fill ",
                "description": "Style the fill of your progress bar.",
                "varName": "fill",
                "addBox": false,
                "forceWidth": 18.4,
                "valSchema": {
                    "lineSpacer": {
                        "type": "line-spacer",
                        "default": ""
                    },
                    "fillType": {
                        "type": "dropdown",
                        "label": "Choose Fill Type",
                        "default": "solid",
                        "valOptions": [
                            {
                                "label": "Solid Colour",
                                "value": "solid"
                            },
                            {
                                "label": "Striped",
                                "value": "striped"
                            },
                            {
                                "label": "Image",
                                "value": "image"
                            }
                        ]
                    },
                    "stripeDirection": {
                        "type": "dropdown",
                        "label": "Stripe Direction",
                        "default": "diagonal",
                        "valOptions": [
                            {
                                "label": "Diagonal",
                                "value": "diagonal"
                            },
                            {
                                "label": "Straight",
                                "value": "straight"
                            }
                        ],
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "stripeColour1": {
                        "type": "colour",
                        "label": "Colour 1",
                        "default": "#BCA4FF",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "stripeColour2": {
                        "type": "colour",
                        "label": "Colour 2",
                        "default": "#9146ff",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "animateStripes": {
                        "type": "toggle",
                        "label": "Animate Stripes",
                        "default": true,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "reverseStripeAnim": {
                        "type": "toggle",
                        "label": "Reverse Animation",
                        "default": false,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "striped",
                                "evalCondition": "=="
                            },
                            {
                                "var": "animateStripes",
                                "val": true,
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "solidColour": {
                        "type": "colour",
                        "label": "Colour",
                        "default": "#9146FF",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "solid",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "tileImage": {
                        "type": "toggle",
                        "label": "Tile Image",
                        "default": false,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "filename": {
                        "type": "Image",
                        "label": "Image File",
                        "labelNote": "Recommended size: 65px x 130px",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            }
                        ]
                    },
                    "animateImage": {
                        "type": "toggle",
                        "label": "Animate Image",
                        "default": true,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            },
                            {
                                "var": "tileImage",
                                "val": true,
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "reverseImageAnim": {
                        "type": "toggle",
                        "label": "Reverse Animation",
                        "default": true,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            },
                            {
                                "var": "animateImage",
                                "val": true,
                                "evalCondition": "=="
                            },
                            {
                                "var": "tileImage",
                                "val": true,
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    },
                    "imageEdgeBuffer": {
                        "label": "Edge Buffer (pixels)",
                        "type": "slider",
                        "minVal": 0,
                        "maxVal": 500,
                        "default": 0,
                        "displayCondition": [
                            {
                                "var": "fillType",
                                "val": "image",
                                "evalCondition": "=="
                            },
                            {
                                "var": "animateImage",
                                "val": false,
                                "evalCondition": "=="
                            },
                            {
                                "var": "tileImage",
                                "val": false,
                                "evalCondition": "=="
                            }
                        ],
                        "advancedMode": true
                    }
                },
                "vals": [
                    {
                        "fillType": "solid",
                        "stripeDirection": "diagonal",
                        "stripeColour1": "#BCA4FF",
                        "stripeColour2": "#9146ff",
                        "animateStripes": true,
                        "reverseStripeAnim": false,
                        "solidColour": "#9146FF",
                        "tileImage": false,
                        "filename": "",
                        "animateImage": true,
                        "reverseImageAnim": true,
                        "imageEdgeBuffer": 0,
                        "isDefault": true
                    }
                ],
                "addOutlineBox": true
            }
        ],
        "displayCondition": [],
        "collapsible": true
    },
    RadialProgressBar: {
        "groupTitle": "Circular Progress Bar",
        "varKey": "radialAppearance",
        "forceWidth": 100,
        "padEnd": "",
        "displayCondition": [],
        "advancedMode": false,
        "variables": [
            {
                "label": "Position",
                "description": "",
                "varName": "position",
                "forceWidth": 18.4,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": false,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "position": {
                        "type": "dropdown",
                        "label": "Position",
                        "default": "bottom-center",
                        "valOptions": [
                            {
                                "label": "Top Left",
                                "value": "top-left"
                            },
                            {
                                "label": "Top Center",
                                "value": "top-center"
                            },
                            {
                                "label": "Top Right",
                                "value": "top-right"
                            },
                            {
                                "label": "Middle Left",
                                "value": "middle-left"
                            },
                            {
                                "label": "Middle Center",
                                "value": "middle-center"
                            },
                            {
                                "label": "Middle Right",
                                "value": "middle-right"
                            },
                            {
                                "label": "Bottom Left",
                                "value": "bottom-left"
                            },
                            {
                                "label": "Bottom Center",
                                "value": "bottom-center",
                                "index": 7
                            },
                            {
                                "label": "Bottom Right",
                                "value": "bottom-right"
                            }
                        ]
                    },
                    "xOffset": {
                        "label": "X Offset (px)",
                        "type": "slider",
                        "default": 0,
                        "minVal": -960,
                        "maxVal": 960,
                        "useTextLabels": false,
                        "showCurrentValue": true,
                        "advancedMode": false
                    },
                    "yOffset": {
                        "label": "Y Offset (px)",
                        "type": "slider",
                        "default": -100,
                        "minVal": -540,
                        "maxVal": 540,
                        "useTextLabels": false,
                        "showCurrentValue": true,
                        "advancedMode": false
                    }
                },
                "vals": [
                    {
                        "position": "bottom-center",
                        "xOffset": 0,
                        "yOffset": -100,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [],
                "addVarBoxOutline": false
            },
            {
                "label": "Shape / Dimensions",
                "description": "",
                "varName": "shape",
                "forceWidth": 18.4,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": false,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "subTitle_53": {
                        "label": "Sub Title",
                        "type": "line-spacer",
                        "default": ""
                    },
                    "roundness": {
                        "label": "Roundness",
                        "type": "slider",
                        "default": 51,
                        "minVal": 0,
                        "maxVal": 100,
                        "showCurrentValue": false,
                        "useTextLabels": true,
                        "minLabel": "Square",
                        "maxLabel": "Round",
                        "displayCondition": []
                    },
                    "size": {
                        "label": "Size (px)",
                        "type": "slider",
                        "default": 75,
                        "minVal": 50,
                        "maxVal": 400,
                        "showCurrentValue": true,
                        "useTextLabels": true,
                        "minLabel": "Small",
                        "maxLabel": "Large",
                        "displayCondition": []
                    }
                },
                "vals": [
                    {
                        "roundness": 51,
                        "size": 75,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [],
                "addVarBoxOutline": false
            },
            {
                "label": "Outline",
                "description": "",
                "varName": "outline",
                "forceWidth": 18.35,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": false,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "subTitle_55": {
                        "label": "Sub Title",
                        "type": "line-spacer",
                        "default": ""
                    },
                    "addBorder": {
                        "label": "Add Outline",
                        "type": "toggle",
                        "default": false
                    },
                    "bgPanelInfoText1718": {
                        "label": "",
                        "type": "description-string",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "addBorder",
                                "evalCondition": "!=",
                                "val": true
                            }
                        ],
                        "description": "No Border Shown"
                    },
                    "colour": {
                        "label": "Outline Colour",
                        "type": "colour",
                        "default": "#9146FF",
                        "displayCondition": [
                            {
                                "var": "addBorder",
                                "evalCondition": "==",
                                "val": true
                            }
                        ]
                    },
                    "thickness": {
                        "label": "Outline Thickness",
                        "type": "slider",
                        "default": 5,
                        "minVal": 0,
                        "maxVal": 20,
                        "displayCondition": [
                            {
                                "var": "addBorder",
                                "evalCondition": "==",
                                "val": true
                            }
                        ]
                    }
                },
                "vals": [
                    {
                        "addBorder": false,
                        "colour": "#9146FF",
                        "thickness": 5,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [],
                "addVarBoxOutline": false
            },
            {
                "label": "Background Fill",
                "description": "",
                "varName": "background",
                "forceWidth": 18.4,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": false,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "subTitle_58": {
                        "label": "Sub Title",
                        "type": "line-spacer",
                        "default": ""
                    },
                    "bgType": {
                        "label": "Type",
                        "type": "dropdown",
                        "default": "image",
                        "valOptions": [
                            {
                                "label": "Solid Colour",
                                "val": "solidColour",
                                "index": 0,
                                "value": "solidColour"
                            },
                            {
                                "label": "Gradient",
                                "val": "",
                                "value": "gradient",
                                "index": 1
                            },
                            {
                                "label": "Image",
                                "val": "",
                                "value": "image",
                                "index": 2
                            }
                        ],
                        "displayCondition": []
                    },
                    "bgColour": {
                        "label": "Choose Colour",
                        "type": "colour",
                        "default": "#BCA4FF",
                        "displayCondition": [
                            {
                                "var": "bgType",
                                "evalCondition": "==",
                                "val": "solidColour"
                            }
                        ]
                    },
                    "bgGradientFrom": {
                        "label": "Gradient From",
                        "type": "colour",
                        "default": "#333333",
                        "displayCondition": [
                            {
                                "var": "bgType",
                                "evalCondition": "==",
                                "val": "gradient"
                            }
                        ]
                    },
                    "bgGradientTo": {
                        "label": "Gradient To",
                        "type": "colour",
                        "default": "#DDDDDD",
                        "displayCondition": [
                            {
                                "var": "bgType",
                                "evalCondition": "==",
                                "val": "gradient"
                            }
                        ]
                    },
                    "bgGradientAngle": {
                        "label": "Gradient Angle",
                        "type": "slider",
                        "default": "0",
                        "minVal": -180,
                        "maxVal": 180,
                        "showCurrentValue": true,
                        "useTextLabels": false,
                        "minLabel": "-",
                        "maxLabel": "Horizontal",
                        "midLabel": "Vertical",
                        "displayCondition": [
                            {
                                "var": "bgType",
                                "evalCondition": "==",
                                "val": "gradient"
                            }
                        ]
                    },
                    "bgImage": {
                        "label": "Background Image",
                        "type": "image",
                        "default": "circletimerdesat.png",
                        "displayCondition": [
                            {
                                "var": "bgType",
                                "evalCondition": "==",
                                "val": "image"
                            }
                        ]
                    },
                    "bgOpacity": {
                        "label": "Opacity",
                        "type": "slider",
                        "default": 50,
                        "minVal": 0,
                        "maxVal": 100,
                        "showCurrentValue": true,
                        "useTextLabels": true,
                        "minLabel": "0%",
                        "maxLabel": "100%",
                        "midLabel": "50%",
                        "displayCondition": [
                            {
                                "var": "bgType",
                                "evalCondition": "!=",
                                "val": "none"
                            }
                        ]
                    }
                },
                "vals": [
                    {
                        "bgType": "image",
                        "bgColour": "#BCA4FF",
                        "bgGradientFrom": "#333333",
                        "bgGradientTo": "#DDDDDD",
                        "bgGradientAngle": "0",
                        "bgImage": "circletimerdesat.png",
                        "bgOpacity": 50,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [],
                "addVarBoxOutline": false
            },
            {
                "label": "Foreground Fill",
                "description": "",
                "varName": "foreground",
                "forceWidth": 18.4,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": false,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "subTitle_58": {
                        "label": "Sub Title",
                        "type": "line-spacer",
                        "default": ""
                    },
                    "fgType": {
                        "label": "Type",
                        "type": "dropdown",
                        "default": "image",
                        "valOptions": [
                            {
                                "label": "Solid Colour",
                                "val": "solidColour",
                                "index": 0,
                                "value": "solidColour"
                            },
                            {
                                "label": "Gradient",
                                "val": "",
                                "value": "gradient",
                                "index": 1
                            },
                            {
                                "label": "Image",
                                "val": "",
                                "value": "image",
                                "index": 2
                            }
                        ]
                    },
                    "fgColour": {
                        "label": "Choose Colour",
                        "type": "colour",
                        "default": "#9146FF",
                        "displayCondition": [
                            {
                                "var": "fgType",
                                "evalCondition": "==",
                                "val": "solidColour"
                            }
                        ]
                    },
                    "fgGradientFrom": {
                        "label": "Gradient From",
                        "type": "colour",
                        "default": "#333333",
                        "displayCondition": [
                            {
                                "var": "fgType",
                                "evalCondition": "==",
                                "val": "gradient"
                            }
                        ]
                    },
                    "fgGradientTo": {
                        "label": "Gradient To",
                        "type": "colour",
                        "default": "#DDDDDD",
                        "displayCondition": [
                            {
                                "var": "fgType",
                                "evalCondition": "==",
                                "val": "gradient"
                            }
                        ]
                    },
                    "fgGradientAngle": {
                        "label": "Gradient Angle",
                        "type": "slider",
                        "default": 0,
                        "minVal": -180,
                        "maxVal": 180,
                        "showCurrentValue": true,
                        "useTextLabels": false,
                        "displayCondition": [
                            {
                                "var": "fgType",
                                "evalCondition": "==",
                                "val": "gradient"
                            }
                        ]
                    },
                    "fgImage": {
                        "label": "Background Image",
                        "type": "image",
                        "default": "circletimercolour.png",
                        "displayCondition": [
                            {
                                "var": "fgType",
                                "evalCondition": "==",
                                "val": "image"
                            }
                        ]
                    },
                    "fgOpacity": {
                        "label": "Opacity",
                        "type": "slider",
                        "default": "100",
                        "minVal": 0,
                        "maxVal": 100,
                        "showCurrentValue": true,
                        "useTextLabels": true,
                        "minLabel": "0%",
                        "maxLabel": "100%",
                        "midLabel": "50%",
                        "displayCondition": [
                            {
                                "var": "fgType",
                                "evalCondition": "!=",
                                "val": "none"
                            }
                        ]
                    }
                },
                "vals": [
                    {
                        "fgType": "image",
                        "fgColour": "#9146FF",
                        "fgGradientFrom": "#333333",
                        "fgGradientTo": "#DDDDDD",
                        "fgGradientAngle": 0,
                        "fgImage": "circletimercolour.png",
                        "fgOpacity": "100",
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [],
                "addVarBoxOutline": false
            }
        ],
        "collapsible": true
    },
    Countdown: {
        "groupTitle": "Countdown",
        "varKey": "countdown",
        "forceWidth": "full",
        "padEnd": "",
        "displayCondition": [],
        "advancedMode": false,
        "variables": [
            {
                "label": "Layout",
                "description": "",
                "varName": "template",
                "forceWidth": 18,
                "groupWidth": "",
                "padEnd": "",
                "addBox": true,
                "addOutlineBox": false,
                "boxColour": "#DCD3FF",
                "addVarBox": true,
                "addVarOutlineBox": false,
                "varBoxColour": "#FFFFFF",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "countdownStyle": {
                        "label": "Countdown Style",
                        "type": "dropdown",
                        "default": "style1",
                        "valOptions": [
                            {
                                "label": "Style 1",
                                "val": "style1",
                                "index": 0,
                                "value": "style1"
                            },
                            {
                                "label": "Style 2",
                                "val": "",
                                "value": "style2",
                                "index": 1
                            }
                        ]
                    },
                    "style1Info": {
                        "label": "",
                        "type": "description-string",
                        "default": "",
                        "description": "Style 1 presents the words \"Hours,\" \"Mins,\" and \"Secs\" beneath the relative time display.",
                        "displayCondition": [
                            {
                                "var": "countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "style3Info": {
                        "label": "",
                        "type": "description-string",
                        "default": "",
                        "description": "Style 2 is a shown in a plain 00:00:00 format.",
                        "displayCondition": [
                            {
                                "var": "countdownStyle",
                                "evalCondition": "==",
                                "val": "style2"
                            }
                        ]
                    },
                    "showHeading": {
                        "label": "Show Heading",
                        "type": "toggle",
                        "default": false
                    },
                    "cdownPosTitle": {
                        "label": "Countdown Position",
                        "type": "subtitle",
                        "default": "",
                        "displayCondition": []
                    },
                    "position": {
                        "type": "dropdown",
                        "label": "Position",
                        "default": 4,
                        "valOptions": [
                            {
                                "label": "Top Left",
                                "value": "top-left"
                            },
                            {
                                "label": "Top Center",
                                "value": "top-center"
                            },
                            {
                                "label": "Top Right",
                                "value": "top-right"
                            },
                            {
                                "label": "Middle Left",
                                "value": "middle-left"
                            },
                            {
                                "label": "Middle Center",
                                "value": "middle-center"
                            },
                            {
                                "label": "Middle Right",
                                "value": "middle-right"
                            },
                            {
                                "label": "Bottom Left",
                                "value": "bottom-left"
                            },
                            {
                                "label": "Bottom Center",
                                "value": "bottom-center"
                            },
                            {
                                "label": "Bottom Right",
                                "value": "bottom-right"
                            }
                        ],
                        "displayCondition": []
                    },
                    "xOffset": {
                        "label": "X Offset (px)",
                        "type": "slider",
                        "default": 0,
                        "minVal": -960,
                        "maxVal": 960,
                        "useTextLabels": false,
                        "showCurrentValue": true,
                        "advancedMode": true,
                        "displayCondition": []
                    },
                    "yOffset": {
                        "label": "Y Offset (px)",
                        "type": "slider",
                        "default": 0,
                        "minVal": -540,
                        "maxVal": 540,
                        "useTextLabels": false,
                        "showCurrentValue": true,
                        "advancedMode": true,
                        "displayCondition": []
                    }
                },
                "vals": [
                    {
                        "countdownStyle": "style1",
                        "showHeading": false,
                        "position": 4,
                        "xOffset": 0,
                        "yOffset": 0,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [],
                "addVarBoxOutline": false
            },
            {
                "label": "Heading",
                "description": "",
                "varName": "heading",
                "forceWidth": 18,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": false,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "subTitle_56": {
                        "label": "Sub Title",
                        "type": "line-spacer",
                        "default": ""
                    },
                    "copy": {
                        "label": "Copy",
                        "type": "string",
                        "default": "TIME LEFT"
                    },
                    "copyFont": {
                        "label": "Font",
                        "type": "font",
                        "default": "Roboto"
                    },
                    "copyFontSize": {
                        "type": "slider",
                        "showCurrentValue": true,
                        "minVal": 8,
                        "maxVal": 36,
                        "default": 10,
                        "label": "Font Size",
                        "displayCondition": []
                    },
                    "copyColour": {
                        "label": "Colour",
                        "type": "colour",
                        "default": "#000000"
                    },
                    "isBold": {
                        "label": "Is Bold",
                        "type": "toggle",
                        "default": true
                    }
                },
                "vals": [
                    {
                        "copy": "TIME LEFT",
                        "copyFont": "Roboto",
                        "copyFontSize": 10,
                        "copyColour": "#000000",
                        "isBold": true,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": [
                    {
                        "var": "countdown.template.showHeading",
                        "val": true,
                        "evalCondition": "=="
                    }
                ],
                "addVarBoxOutline": false
            },
            {
                "label": "Timer",
                "description": "",
                "varName": "timer",
                "forceWidth": 59.9,
                "groupWidth": "",
                "padEnd": "",
                "addBox": false,
                "addOutlineBox": true,
                "boxColour": "#EFF0F6",
                "addVarBox": true,
                "addVarOutlineBox": false,
                "varBoxColour": "#EFF0F6",
                "preventDelete": false,
                "allowMultiple": false,
                "maxMultiples": -1,
                "chainedMaxMultiples": -1,
                "valSchema": {
                    "style1Title": {
                        "label": "Countdown Style 1",
                        "type": "subtitle",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "style2Title": {
                        "label": "Countdown Style 2",
                        "type": "subtitle",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style2"
                            }
                        ]
                    },
                    "timerFont": {
                        "label": "Font",
                        "type": "font",
                        "default": "Roboto"
                    },
                    "timerFontSize": {
                        "type": "slider",
                        "showCurrentValue": true,
                        "minVal": 8,
                        "maxVal": 36,
                        "default": 10,
                        "label": "Font Size",
                        "displayCondition": []
                    },
                    "timerColour": {
                        "label": "Colour",
                        "type": "colour",
                        "default": "#000000"
                    },
                    "timerIsBold": {
                        "label": "Is Bold",
                        "type": "toggle",
                        "default": true
                    },
                    "col2": {
                        "label": "",
                        "type": "column-break",
                        "default": ""
                    },
                    "hmsCopy": {
                        "label": "HMS Copy",
                        "type": "subtitle",
                        "default": "",
                        "displayCondition": []
                    },
                    "hoursCopy": {
                        "label": "Hours Copy",
                        "type": "string",
                        "default": "HOURS",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "minsCopy": {
                        "label": "Mins Copy",
                        "type": "string",
                        "default": "MINS",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "secsCopy": {
                        "label": "Secs Copy",
                        "type": "string",
                        "default": "SECS",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "style3info": {
                        "label": "The style does not include any graphical embellishments; it simply displays the time as 00:00:00",
                        "type": "description-string",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "!=",
                                "val": "style1"
                            }
                        ],
                        "description": "The style does not include any extra copy;<br />it simply displays the time as 00:00:00"
                    },
                    "columnBreak_25": {
                        "type": "column-break",
                        "label": "Column Break",
                        "default": true
                    },
                    "subTitle_24": {
                        "label": "HMS Copy Font",
                        "type": "spacer",
                        "default": "",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "hmsFont": {
                        "label": "Font",
                        "type": "font",
                        "default": "Roboto",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "hmsFontSize": {
                        "type": "slider",
                        "showCurrentValue": true,
                        "minVal": 8,
                        "maxVal": 36,
                        "default": 10,
                        "label": "Font Size",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "hmsColour": {
                        "label": "Colour",
                        "type": "colour",
                        "default": "#000000",
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    },
                    "hmsIsBold": {
                        "label": "Is Bold",
                        "type": "toggle",
                        "default": true,
                        "displayCondition": [
                            {
                                "var": "countdown.template.countdownStyle",
                                "evalCondition": "==",
                                "val": "style1"
                            }
                        ]
                    }
                },
                "vals": [
                    {
                        "timerFont": "Roboto",
                        "timerFontSize": 10,
                        "timerColour": "#000000",
                        "timerIsBold": true,
                        "hoursCopy": "HOURS",
                        "minsCopy": "MINS",
                        "secsCopy": "SECS",
                        "hmsFont": "Roboto",
                        "hmsFontSize": 10,
                        "hmsColour": "#000000",
                        "hmsIsBold": true,
                        "isDefault": true
                    }
                ],
                "presets": [],
                "displayCondition": []
            }
        ],
        "collapsible": true
    },
    InformationBox: {
        "varKey": "instructions",
        "groupTitle": "Information Box",
        "variables": [
            {
                "varName": "autoShow",
                "description": "If enabled the information box will automatically show when the feature starts",
                "label": "Auto Show",
                "defaultVal": true,
                "demoModeOnly": false,
                "varType": "Boolean",
                "dashboardDisplayType": "toggle",
                "displayCondition": []
            },
            {
                "varName": "autoShowDelay",
                "description": "The number of seconds to wait after the feature starts before the information box is shown",
                "label": "Auto Show Delay",
                "defaultVal": 0,
                "demoModeOnly": false,
                "varType": "Number",
                "dashboardDisplayType": "ms-time",
                "displayCondition": [
                    {
                        "var": "autoShow",
                        "val": true,
                        "evalCondition": "=="
                    }
                ]
            },
            {
                "varName": "autoHide",
                "description": "If enabled the information box will automatically hide when the feature ends",
                "label": "Auto Hide",
                "defaultVal": false,
                "demoModeOnly": false,
                "varType": "Boolean",
                "dashboardDisplayType": "toggle",
                "displayCondition": []
            },
            {
                "varName": "autoHideDelay",
                "description": "The number of seconds to wait after the feature ends before the information box is hidden",
                "label": "Auto Hide Delay",
                "defaultVal": 10,
                "demoModeOnly": false,
                "varType": "Number",
                "dashboardDisplayType": "ms-time",
                "displayCondition": [
                    {
                        "var": "autoHide",
                        "val": true,
                        "evalCondition": "=="
                    }
                ]
            }
        ],
        "commandButtons": [
            {
                "label": "Show",
                "command": "SHOW_INSTRUCTIONS",
                "index": 5,
                "relatedButton": {
                    "label": "",
                    "command": ""
                },
                "addRelatedButton": false
            },
            {
                "label": "Hide",
                "command": "HIDE_INSTRUCTIONS",
                "index": 7,
                "relatedButton": {
                    "label": "",
                    "command": ""
                },
                "addRelatedButton": false
            }
        ]
    }
}
