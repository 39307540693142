import FormField from "../../components/forms/FormField/FormField";
import Toggle from "../../components/forms/Toggle/Toggle";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";
import CheckBox from "../../components/forms/CheckBox/CheckBox";
import { FEATURE_CONTROLS_TYPES } from "../../helpers/FeatureControlsHelper";
import "./CustomisationControls.scss";

import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { CUSTOMISED_ASSETS_BASE_URL, CUSTOMISED_URL_MAP, VAR_TYPES, checkAndUpdateObjectVar, getObjectRef, getValFromObject } from "../../config/FeatureDashboardVarMap";
import { IconJsxer } from "../../helpers/IconHelper";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import RangeSlider from "../../components/forms/RangeSlider/RangeSlider";
import ColorPicker from "../../components/forms/ColorPicker/ColorPicker";
import VideoUploader from "../forms/VideoUploader/VideoUploader";
import ImageUploader2 from "../forms/ImageUpload/ImageUploader2";
import AudioUploader from "../forms/AudioUploader/AudioUploader";
import { filesRoute } from "../../api/routes";
import { toast } from "react-toastify";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";
import LeftColorBlockBox, { LEFT_COLOR_BLOCK_STYLES } from "../LeftColorBlockBox/LeftColorBlockBox";
import TextArea from "../forms/TextArea/TextArea";
import DateSelect from "../forms/DateSelect/DateSelect";
import DateTimeTimezoneSelect from "../forms/DateTimeTimezoneSelect/DateTimeTimezoneSelect";
import CollapsibleHeadedSection from "../CollapsibleHeadedSection/CollapsibleHeadedSection";

export const validateValAtIndex = (valsArray, valSchema, index) => {
    for (let prop in valsArray[index]) {
        let varSettings = null;
        for (let prop2 in FEATURE_CONTROLS_TYPES) {
            if (valSchema[prop] && FEATURE_CONTROLS_TYPES[prop2].type === valSchema[prop].type.toLowerCase()) {
                varSettings = FEATURE_CONTROLS_TYPES[prop2];
            }
        }
        if (varSettings && !varSettings.noVal && !varSettings.isCosmetic) {
            if (valsArray[index][prop] === null) {
                return false;
            }
            // Check subProp (used by uploaders as the data is now an object with a filename prop)
            if (varSettings.validateSubProp) {
                if (valsArray[index][prop][varSettings.validateSubProp] === null || typeof valsArray[index][prop][varSettings.validateSubProp] === 'undefined' || valsArray[index][prop][varSettings.validateSubProp] === '') {
                    console.log('Sub prop invalid: ', valsArray, index, prop, varSettings.validateSubProp, valsArray[index][prop][varSettings.validateSubProp]);
                    return false;
                }
            }
        }
    }
    return true;
}

export const findFeatureControlTypeData = (typeString) => {
    for (let prop in FEATURE_CONTROLS_TYPES) {
        if (FEATURE_CONTROLS_TYPES[prop].type === typeString) {
            return FEATURE_CONTROLS_TYPES[prop];
        }
    }
    return null;
}

export const validateLastVal = (valsArray, valSchema) => {
    return validateValAtIndex(valsArray, valSchema, valsArray.length - 1);
}

const CustomisationControls = (props) => {
    const tabId = props.tabId;
    const controlsData = props.controlsData;
    const customisationVars = props.customisationVars;
    const featureId = props.featureId;
    const varTypes = useRef({});

    const advancedMode = props.advancedMode;

    // console.log('Controls Data: ', controlsData);
    // console.log('Customisation Vars: ', customisationVars);

    // This state is really only used to trigger re-renders, we are actually pulling the values from props.streamVars
    const [featureState, setFeatureState] = useState(props.customisationVars ? { ...props.customisationVars } : {});
    useEffect(
        () => {
            if (props.customisationVars) {
                setFeatureState({ ...props.customisationVars });
            }
            // console.log('[CustomisationControls] props.themeData : ', props.themeData);
        },
        [props.customisationVars]
    )
    const valsUpdatedCallback = () => {
        setFeatureState({ ...props.customisationVars });
        console.log('Vals updated: ', props.customisationVars);
    }
    useEffect(
        props.refreshPreview ? props.refreshPreview : () => {},
        [featureState]
    )

    const changeVal = (valsArray, varSchema, index, valKey, newData, itemData, fullControlData) => {
        console.log('Change val: ', valsArray, varSchema, index, valKey, newData, itemData, fullControlData);
        switch (varSchema.type.toLowerCase()) {
            case 'video':
            case 'image':
            case 'audio':
                // File upload types!
                /* Old style!
                valsArray[index][valKey] = newData ? newData.name : null;
                valsArray[index].newFile = newData;
                valsArray[index].srcUrl = newData ? URL.createObjectURL(newData) : null;
                valsArray[index].isDefault = false;
                */
                const fileNameSplit = newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                fileNameSplit[0] += '__' + Date.now();
                valsArray[index][valKey].filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                newData.uniqueFilename = fileNameSplit.join('.');
                valsArray[index][valKey].newFile = newData;
                valsArray[index][valKey].srcUrl = newData ? URL.createObjectURL(newData) : null;
                valsArray[index][valKey].isDefault = false;
                valsArray[index].isDefault = false;
                valsArray[index].updated = true;
                break;
            default:
                // Everything else!
                valsArray[index][valKey] = newData;
                valsArray[index].updated = true;
                if (typeof valsArray[index].isDefault === "undefined") {
                    valsArray[index].isDefault = true;
                }
                break;
        }
        console.log('Val changed: ', valKey, valsArray, props.customisationVars);

        if (!props.advancedMode && itemData && typeof itemData.basicModePreset !== 'undefined' && fullControlData) {
            setBasicModePreset(valsArray, varSchema, index, valKey, newData, itemData, fullControlData);
        }
        setFeatureState({ ...props.customisationVars });
    }

    const setBasicModePreset = (valsArray, varSchema, index, valKey, newData, itemData, fullControlData) => {
        console.log('Set basic mode preset: ', valsArray, varSchema, index, valKey, newData, itemData, fullControlData);
        let preset = null;
        if (fullControlData.presets) {
            if (typeof itemData.basicModePreset === 'number') {
                preset = fullControlData.presets[itemData.basicModePreset];
            } else
            if (typeof itemData.basicModePreset === 'string') {
                for (let i = 0; i < fullControlData.presets.length; i++) {
                    if (fullControlData.presets[i].id === itemData.basicModePreset || fullControlData.presets[i].name === itemData.basicModePreset) {
                        preset = fullControlData.presets[i];
                        break;
                    }
                }
            } else {
                // Assume it's an object!
                preset = {vals:[itemData.basicModePreset]};
            }
        }
        if (preset) {
            console.log('Found preset: ', preset, valsArray[index]);
            for (let prop in valsArray[index]) {
                console.log('Setting preset prop: ', prop);
                if (preset.vals[index]) {
                    if (typeof preset.vals[index][prop] !== 'undefined' && preset.vals[index][prop] !== null) {
                        console.log('Setting preset vals: ', preset.vals[index]);
                        valsArray[index][prop] = preset.vals[index][prop];
                    }
                } else
                if (preset.vals[0] && typeof preset.vals[0][prop] !== 'undefined' && preset.vals[0][prop] !== null) {
                    console.log('Setting preset vals: ', preset.vals[index]);
                    valsArray[index][prop] = preset.vals[index][prop];
                }
            }
        }
    }

    const addVal = (valsArray, varSchema) => {
        console.log('--- Adding vals: ', valsArray, varSchema);
        // if (validateLastVal(valsArray)) {
            const newValObj = {};
            for (let prop in varSchema) {
                const type = varSchema[prop].type.toLowerCase();
                switch (type) {
                    case 'video':
                    case 'image':
                    case 'audio':
                        // These need formatting properly!
                        const filename = (varSchema[prop].hasOwnProperty('default') || varSchema[prop].hasOwnProperty('defaultVal')) ? (varSchema[prop].default ?? varSchema[prop].defaultVal) : null;
                        if (props.isFullPreview === true) {
                            // In full preview mode the filename has already been formatted to an object
                            newValObj[prop] = filename;
                        } else {
                            const fileObj = {
                                filename: filename,
                                isDefault: true,
                                originalFile: filename,
                                type: type,
                                srcUrl: (typeof filename === 'undefined' || filename === null || filename === '') ? null :
                                    getFeatureAssetBaseUrl()
                                    + props.featureData.feature.defaultAssetFolder 
                                    + '/' + CUSTOMISED_URL_MAP[type].dir + '/' 
                                    + filename,
                                
                                /* filesRoute + '/' + CUSTOMISED_ASSETS_BASE_URL + '/' 
                                        + props.featureData.feature.defaultAssetFolder 
                                        + '/' + CUSTOMISED_URL_MAP[type].dir + '/' 
                                        + filename */
                            };
                            newValObj[prop] = fileObj;
                        }
                        break;
                    default:
                        newValObj[prop] = (varSchema[prop].hasOwnProperty('default') || varSchema[prop].hasOwnProperty('defaultVal')) ? (varSchema[prop].default ?? varSchema[prop].defaultVal) : null;
                }
            }
            valsArray.push(newValObj);
            console.log('Added val: ', valsArray, props.customisationVars);
            setFeatureState({ ...props.customisationVars });
        // }
    }

    const removeVal = (valsArray, index, objRef) => {
        console.log('Remove val: ', valsArray, index, objRef);
        if (valsArray.length > 1) {
            if (props.handleRemovedFile) {
                props.handleRemovedFile(valsArray[index]);
            }
            // valsArray.splice(index, 1);
            
            // create a new array instance so we can make sure we see the correct data next render
            const oldArray = objRef.propRef[objRef.prop];
            const newArray = [];
            for (let i = 0; i < oldArray.length; i++) {
                if (i !== index) {
                    newArray.push({...oldArray[i]});
                }
            }
            // Lets force this stubborn component to update!
            // The component should be using useEffect really, but what if it isn't implemented very well in it?
            objRef.propRef[objRef.prop] = [];
            setFeatureState({ ...props.customisationVars });
            // Above forces a re-render where there are no vals, so the components will vanish for a split second
            // The timeout below brings em back (looking correct)
            setTimeout(
                () => {
                    objRef.propRef[objRef.prop] = newArray;
                    setFeatureState({ ...props.customisationVars });
                },
                10
            )
            console.log('Val removed: ', valsArray, index, props.customisationVars);
        }
    }

    const duplicateVal = (valsArray, index, objRef) => {
        console.log('Duplicate val: ', valsArray, index, objRef);

        // Deep copy objRef
        const newObj =  structuredClone(valsArray[index]);
        console.log('Dupe object: ', valsArray[index], newObj);
        objRef.propRef[objRef.prop].push(newObj);
        setFeatureState({ ...props.customisationVars });
    }

    const copyVals = (copyFromLocation, copyToValsObject, index, familyKey) => {
        // The localation is a string representing the path to the valsArray we want to copy from
        console.log('Copying vals input: ', copyFromLocation, copyToValsObject, index, props.customisationVars);
        // const copyFrom = getObjectRef(copyFromLocation, props.customisationVars);
        let copyFrom = props.customisationVars;
        if (copyFrom.current) {
            copyFrom = copyFrom.current;
        }
        let locationSplit = copyFromLocation.split('.');
        if (!copyFrom.hasOwnProperty(locationSplit[0])) {
            for (let prop in copyFrom) {
                if (copyFrom[prop].hasOwnProperty(locationSplit[0])) {
                    copyFrom = copyFrom[prop];
                    break;
                }
            }
        }
        for (let i = 0; i < locationSplit.length; i++) {
            copyFrom = copyFrom?.[locationSplit[i]];
            if (!copyFrom) {
                console.log('Copy from not found: ', copyFromLocation, locationSplit[i], copyFrom);
                return;
            }
        }
        if (!Array.isArray(copyFrom) && familyKey) {
            if (Array.isArray(copyFrom[familyKey])) {
                copyFrom = copyFrom[familyKey];
            }
        }
        console.log('Copy vals: ', copyFrom[index], copyToValsObject);
        for (let prop in copyFrom[index]) {
            if (copyToValsObject.hasOwnProperty(prop)) {
                copyToValsObject[prop] = copyFrom[index][prop];
            }
        }
        setFeatureState({ ...props.customisationVars });
    }

    const findVal = (valsObject, varName, tabKey = null, altValsObject = null) => {
        // console.log('--- Finding val: ', valsObject, varName);
        const varNameParts = varName.split('.');
        let ousideTabVal = null;
        for (let tab in valsObject) {
            for (let group in valsObject[tab]) {
                for (let subGroup in valsObject[tab][group]) {
                    for (let i = 0; i < valsObject[tab][group][subGroup].length; i++) {
                        for (let valName in valsObject[tab][group][subGroup][i]) {
                            if (valName === varNameParts[varNameParts.length - 1]) {
                                // console.log('Found match!');
                                // The names match!
                                if (varNameParts.length === 1) {
                                    // We only supplied the val name, so let's assume it is unique
                                    return valsObject[tab][group][subGroup][i][valName];
                                }
                                // check if the path to the value (or part path) matches what was supplied
                                let pathMatchCheck = "";
                                if (varNameParts.length === 4) {
                                    pathMatchCheck += tab + '.';
                                }
                                if (varNameParts.length >= 3) {
                                    pathMatchCheck += group + '.';
                                }
                                if (varNameParts.length >= 2) {
                                    pathMatchCheck += subGroup + '.';
                                }
                                pathMatchCheck += valName;
                                // console.log('Path to match: ', pathMatchCheck);
                                if (pathMatchCheck === varName) {
                                    if (tab === tabKey) {
                                        console.log('Val in same tab!');
                                        return valsObject[tab][group][subGroup][i][valName];
                                    } else {
                                        // Remember as a first possible match, but keep looking for a match in the same tab
                                        if (ousideTabVal === null) {
                                            ousideTabVal = valsObject[tab][group][subGroup][i][valName];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (ousideTabVal !== null) {
            // console.log('Val outside tab: ', ousideTabVal);
            return ousideTabVal;
        }

        if (altValsObject) {
            return findVal(altValsObject, varName, tabKey);
        }

        return undefined;
    }

    const findValSchema = (valKey) => {
        console.log('Find val schema: ', valKey, controlsData);
        for (let i = 0; i < controlsData.length; i++) {
            for (let opt = 0; opt < controlsData[i].options.length; opt++) {
                for (let oVar = 0; oVar < controlsData[i].options[opt].variables.length; oVar++) {
                    if (controlsData[i].options[opt].variables[oVar].valSchema.hasOwnProperty(valKey)) {
                        return controlsData[i].options[opt].variables[oVar].valSchema;
                    }
                }
            }
        }
        return null;
    }

    const findOptionIndex = (options, val) => {
        for (let i = 0; i < options.length; i++) {
            // console.log('Find option index: ', i, options[i], val);
            if (options[i].value === val || options[i].val === val) {
                return i;
            }
        }
        return 0;
    }

    const getPreviewSrc = (varSchema, valsArray, index) => {
        if (varSchema.previewFor && valsArray[index][varSchema.previewFor]) {
            const valSchema = findValSchema(varSchema.previewFor);
            if (valSchema) {
                const optionsArray = valSchema[varSchema.previewFor].valOptions;
                if (optionsArray) {
                    let previewFile = null;
                    for (let i = 0; i < optionsArray.length; i++) {
                        if (optionsArray[i].value === valsArray[index][varSchema.previewFor] || optionsArray[i].val === valsArray[index][varSchema.previewFor]) {
                            previewFile = optionsArray[i].previewFile;
                            // break;
                        }
                        if (previewFile) {
                            const srcUrl = '/customise-previews/' + previewFile;
                            console.log('Return preview src: ', srcUrl);
                            return srcUrl;
                        }
                    }
                }
            }
        }
        return null;
    }
    
    //ADD DISRUPTOR FUNCTIONS
    const disruptor_varKey = useRef(null);
    const disruptor_valsArray = useRef(null);
    const disruptor_index = useRef(null);
    const selectDisruptor = (varKey, valsArray, index, isLeaderboard, disruptorLabel) => {
        if (props.addDisruptor) {
            console.log('Select disruptor: ', varKey, valsArray, index);
            disruptor_varKey.current = varKey;
            disruptor_valsArray.current = valsArray;
            disruptor_index.current = index;
            props.addDisruptor(disruptorAddedCallback, addExistingDisruptorToList, isLeaderboard, disruptorLabel);
        }
    }
    const addExistingDisruptorToList = (addedDisruptorId) => {
        disruptor_valsArray.current[disruptor_index.current][disruptor_varKey.current] = addedDisruptorId;
        valsUpdatedCallback();
    }
    const disruptorAddedCallback = (success, response, addedDisruptorData, disruptorListSent) => {
        console.log('Disruptor Added: ', success, response, addedDisruptorData, disruptorListSent);
        if (success) {
            // Check the newly added disruptor (the last one in the list), matches what we just tried to add...
            if (response.data.campaignData.features.length === disruptorListSent.length 
                && response.data.campaignData.features[response.data.campaignData.features.length - 1].feature._id === addedDisruptorData.feature._id
                && response.data.campaignData.features[response.data.campaignData.features.length - 1].contentLabel === addedDisruptorData.contentLabel) {
            
                    const addedDisruptorId = response.data.campaignData.features[response.data.campaignData.features.length - 1]._id;
                    disruptor_valsArray.current[disruptor_index.current][disruptor_varKey.current] = addedDisruptorId;
                    props.logAddedDisruptor(addedDisruptorId);
                    valsUpdatedCallback();
            } else {
                // Some error occurred
                toast.error("There was a problem adding your disruptor, please try again.");
            }
        }
    }
    const removeDisruptor = (varVal, varKey, valsArray, index) => {
        props.logRemovedDisruptor(varVal);
        valsArray[index][varKey] = "";
        valsUpdatedCallback();
    }

       //ADD QUIZ FUNCTIONS
       const quiz_varKey = useRef(null);
       const quiz_valsArray = useRef(null);
       const quiz_index = useRef(null);
       const selectQuiz = (varKey, valsArray, index) => {
            if (props.addQuiz) {
                console.log('Select Quiz: ', varKey, valsArray, index);
                quiz_varKey.current = varKey;
                quiz_valsArray.current = valsArray;
                quiz_index.current = index;
                props.addQuiz(quizAddedCallback, addExistingQuizToList);
            }
       }
       const addExistingQuizToList = (adddedQuizData) => {
            
            const quizDataToStore = {
                quizName: adddedQuizData.name,
                quizType: adddedQuizData.type,
                _id: adddedQuizData._id,
            }
            quiz_valsArray.current[quiz_index.current][quiz_varKey.current] = {quiz: quizDataToStore};
            console.log('ADDED QUIZ DATA', adddedQuizData)
            valsUpdatedCallback();
       }
       const quizAddedCallback = (success, response, addedQuizData, quizListSent) => {
           console.log('Quiz Added: ', success, response, addedQuizData, quizListSent);
           if (success) {
               // Check the newly added disruptor (the last one in the list), matches what we just tried to add...
               if (response.data.campaignData.features.length === quizListSent.length 
                   && response.data.campaignData.features[response.data.campaignData.features.length - 1].feature._id === addedQuizData.feature._id
                   && response.data.campaignData.features[response.data.campaignData.features.length - 1].contentLabel === addedQuizData.contentLabel) {
               
                       const addedQuizId = response.data.campaignData.features[response.data.campaignData.features.length - 1]._id;
                       quiz_valsArray.current[quiz_index.current][quiz_varKey.current] = addedQuizId;
                       props.logAddedQuiz(addedQuizId);
                       valsUpdatedCallback();
               } else {
                   // Some error occurred
                   toast.error("There was a problem adding your quiz, please try again.");
               }
           }
       }
       const removeQuiz = (varVal, varKey, valsArray, index) => {
           // props.logRemovedQuiz(varVal);
           valsArray[index][varKey] = "";
           valsUpdatedCallback();
       }

    const font_varKey = useRef(null);
    const font_valsArray = useRef(null);
    const font_index = useRef(null);
    const selectFont = (font, varKey, valsArray, index) => {
        if (props.openFontSelectorFunc) {
            font_varKey.current = varKey;
            font_valsArray.current = valsArray;
            font_index.current = index;
            props.openFontSelectorFunc(font, fontSelectedCallback);
        }
    }
    const fontSelectedCallback = (newFont) => {
        font_valsArray.current[font_index.current][font_varKey.current] = newFont;
        valsUpdatedCallback();
    }

    const findFeatureData = (featureId) => {
        let featureData = null;
        const featuresArray = props.campaignData.features;
        for (let i = 0; i < featuresArray.length; i++) {
            if (featuresArray[i]._id === featureId) {
                featureData = featuresArray[i];
            }
        }
        return featureData;
    }

    const getFeatureTitleElement = (featureId) => {
        const featureData = findFeatureData(featureId);
        if (featureData) {
            return (
                <div className="fl-row compact">
                    <div className="bold-text">{featureData.contentLabel}</div>
                    <div>({featureData.feature.featureName})</div>
                </div>
            )
        } else {
            return null;
        }
    }

    const updateTriggerParam = (paramVal, varSchema, varVal, valsArray, index, valKey) => {
        const varValSplit = varVal.split(':');
        varValSplit[1] = paramVal;
        while (varValSplit.length > 2) {
            varValSplit.pop();
        }
        const newVarVal = varValSplit.join(':');
        changeVal(valsArray, varSchema, index, valKey, newVarVal);
    }

    const getFeatureParamControl = (varSchema, varVal, valsArray, index, valKey) => {
        const featureId = varVal.split(':')[0];
        const featureData = findFeatureData(featureId);
        if (featureData) {
            let triggerCommandData = null;
            for (let i = 0; i < featureData.feature.commands.length; i++) {
                if (featureData.feature.commands[i].isPrimaryTrigger) {
                    triggerCommandData = featureData.feature.commands[i];
                    break;
                }
            }
            if (triggerCommandData && triggerCommandData.triggerParam) {
                const paramVal = varVal.split(':')[1] || triggerCommandData.triggerParam.default;
                return (
                    <div className='fl-row list-view-content'>
                        {getControl(triggerCommandData.triggerParam, paramVal, valsArray, index, valKey, true, (val) => {updateTriggerParam(val, varSchema, varVal, valsArray, index, valKey)})}
                    </div>
                )
            }
        }
        return null;
    }

    const getControl = (varSchema, varVal, valsArray, index, valKey, inlineLabel = false, updateFunction = null, fullControlData = null) => {
        // console.log('Get Customisation Control: ', varSchema, varVal);
        let controlLabel = varSchema.label || varVal;
        if (controlLabel && controlLabel.replace) {
            controlLabel = controlLabel.replace('[i]', index);
            controlLabel = controlLabel.replace('[i+]', index + 1);
            controlLabel = controlLabel.replace('[-i]', (valsArray.length - 1) - index);
            controlLabel = controlLabel.replace('[-i+]', valsArray.length - index);
        }
        let labelNote = ((varSchema.type.toLowerCase() !== FEATURE_CONTROLS_TYPES.DescriptionString.type && varSchema.type.toLowerCase() !== FEATURE_CONTROLS_TYPES.LightDescriptionString.type && varSchema.type.toLowerCase() !== FEATURE_CONTROLS_TYPES.WarningDescriptionString.type && varSchema.type.toLowerCase() !== FEATURE_CONTROLS_TYPES.ConfirmDescriptionString.type && varSchema.type.toLowerCase() !== FEATURE_CONTROLS_TYPES.AttentionDescriptionString.type) ? varSchema.labelNote : varSchema.description);
        if (labelNote) {
            labelNote = labelNote.replace ? labelNote.replace('\n', '<br />') : labelNote;
        } else {
            labelNote = '';
        }

        switch (varSchema.type.toLowerCase()) {
            case FEATURE_CONTROLS_TYPES.Toggle.type:
                return (
                    <>
                        <div className="fl-row">
                            <div className="form-field-content label">{controlLabel}</div>
                            <div className="grow-100"></div>
                            <Toggle
                                id={controlLabel}
                                currentState={varVal}
                                toggleFunc={(e, id) => { updateFunction ? updateFunction(!varVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, !varVal) }}
                            />
                            {/*varVal.description && varVal.description !== '' &&
                                <div className="more-info end" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                </div>
                            */}
                        </div>
                        {labelNote != null && labelNote !== '' &&
                            <div className="form-field-content label-note">
                                {labelNote}
                            </div>
                        }
                    </>
                )
            case FEATURE_CONTROLS_TYPES.ToggleCheck.type:
                return (
                    <>
                        <div className="fl-row">
                            <div>{controlLabel}</div>
                            <div className="grow-100"></div>
                            <ToggleCheck
                                id={controlLabel}
                                currentState={varVal}
                                toggleFunc={(e, id) => { updateFunction ? updateFunction(!varVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, !varVal) }}
                            />
                            {/*varVal.description && varVal.description !== '' &&
                                <div className="more-info end" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                </div>
                            */}
                        </div>
                        {labelNote != null && labelNote !== '' &&
                            <div className="form-field-content label-note">
                                {labelNote}
                            </div>
                        }
                    </>
                )
            case FEATURE_CONTROLS_TYPES.CheckBox.type:
                return (
                    <>
                        <div className="fl-row">
                            <div>{controlLabel}</div>
                            <div className="grow-100"></div>
                            <CheckBox
                                id={controlLabel}
                                currentState={varVal}
                                toggleFunc={(e, id) => { updateFunction ? updateFunction(!varVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, !varVal) }}
                            />
                            {/*varVal.description && varVal.description !== '' &&
                                <div className="more-info end" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                </div>
                            */}
                        </div>
                        {labelNote != null && labelNote !== '' &&
                            <div className="form-field-content label-note">
                                {labelNote}
                            </div>
                        }
                    </>
                )
            case FEATURE_CONTROLS_TYPES.String.type:
                return (
                    <div className="fl-row grow">
                        <FormField
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newVal) }}
                            confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newVal) }}
                            type='text'
                            maxLength={varSchema.maxLength}
                            value={varVal || ''/*getValFromObject(customisationVars, featureId, groupVal.varKey + '.' + varVal.varName)*/}
                            showError={false}
                            placeholder={varVal || ''/*String(varVal.defaultVal)*/}
                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Number.type:
                return (
                    <div className="fl-row">
                        <FormField
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            type='number'
                            min={varSchema.minVal || 0}
                            max={varSchema.maxVal || 9999999999}
                            value={varVal || 0}
                            showError={false}
                            placeholder={String(varVal) || 0}
                            inlineLabel={inlineLabel}
                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Input.type:
                return (
                    <div className="fl-row">
                        <FormField
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newVal) }}
                            confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newVal) }}
                            type={varSchema.varType === VAR_TYPES.numberType ? 'number' : 'text'}
                            min={varSchema.minVal || 0}
                            max={varSchema.maxVal || 9999999}
                            maxLength={varSchema.maxLength}
                            value={varVal}
                            showError={false}
                            placeholder={String(varVal ?? (varSchema.varType === VAR_TYPES.numberType ? 0 : ''))}
                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.TextArea.type:
                return (
                    <div className="fl-row">
                        <TextArea
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newVal) }}
                            confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newVal) }}
                            type={varSchema.varType === VAR_TYPES.numberType ? 'number' : 'text'}
                            min={varSchema.minVal || 0}
                            max={varSchema.maxVal || 9999999}
                            maxLength={varSchema.maxLength}
                            value={varVal}
                            showError={false}
                            placeholder={String(varVal)}
                        />
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Range.type:
                return (
                    <div className="fl-row">
                        <RangeSlider
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            min={varSchema.minVal || 0}
                            max={varSchema.maxVal || 9999999}
                            value={varVal}
                            showCurrentValue={varSchema.showCurrentValue === false ? varSchema.showCurrentValue : true}
                            useTextLabels={varSchema.useTextLabels || false}
                            minLabel={String(varSchema.minLabel ?? 'min') || ''}
                            midLabel={String(varSchema.midLabel ?? '') || ''}
                            maxLabel={String(varSchema.maxLabel ?? 'max') || ''}

                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info-2" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.DropDown.type:
                return (
                    <div className="fl-row">
                        <FormDropDown
                            className={'wide-select'}
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            selectFunc={(e, id, item) => { updateFunction ? updateFunction(item.value, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, item.value, item, fullControlData) }}
                            items={varSchema.valOptions}
                            currentIndex={findOptionIndex(varSchema.valOptions, varVal)}
                            showErrors={false}
                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Radio.type:
                return (
                    <div className="fl-row">
                        <RadioButtonGroup
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            selectFunc={(e, id, newValue) => { updateFunction ? updateFunction(newValue, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newValue) }}
                            options={varSchema.valOptions}
                            horizontal={true}
                            selectedOption={varVal}
                            fillWidth={true}
                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info-2" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.RadioVertical.type:
                return (
                    <div className="fl-row">
                        <RadioButtonGroup
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            selectFunc={(e, id, newValue) => { updateFunction ? updateFunction(newValue, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newValue) }}
                            options={varSchema.valOptions}
                            horizontal={false}
                            selectedOption={varVal}
                            fillWidth={true}
                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info start" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.HmsTime.type:
                return (
                    <div className="fl-row">
                        <HMSTimeField
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newValue) => { updateFunction ? updateFunction(newValue, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newValue)) }}
                            showError={false}
                            value={varVal}
                            centered={false}
                            relativeTime={false}
                        />
                        <div className="grow-100"></div>
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.MsTime.type:
                return (
                    <div className="fl-row">
                        <HMSTimeField
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newValue) => { updateFunction ? updateFunction(newValue, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newValue)) }}
                            showHours={false}
                            showError={false}
                            value={varVal}
                            centered={false}
                        />
                        <div className="grow-100"></div>
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.RelHmsTime.type:
                // console.log('Add Time field... ', varVal);
                return (
                    <div className="fl-row">
                        <HMSTimeField
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newValue) => { updateFunction ? updateFunction(newValue, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newValue)) }}
                            relativeTime={true}
                            showError={false}
                            value={varVal}
                            centered={false}
                        />
                        <div className="grow-100"></div>
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.DatePicker.type:
                return (
                    <div className="fl-row">
                        <DateSelect
                            label={controlLabel}
                            labelNote={labelNote}
                            showLabel={true}
                            id={controlLabel}
                            selectFunc={(id, newValue) => { updateFunction ? updateFunction(Number(newValue), valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newValue)) }}
                            showError={false}
                            value={varVal}
                            allowPast={true}
                        />
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.DateTimePicker.type:
                return (
                    <div className="fl-row">
                        <DateTimeTimezoneSelect
                            label={controlLabel}
                            labelNote={labelNote}
                            showLabel={true}
                            id={controlLabel}
                            selectFunc={(id, newValue) => { updateFunction ? updateFunction(newValue, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newValue) }}
                            showError={false}
                            value={varVal}
                            allowPast={true}
                        />
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.ColourPicker.type:
                return (
                    <div className="fl-row">
                        <ColorPicker
                            label={controlLabel}
                            labelNote={labelNote}
                            id={controlLabel}
                            setFunc={(newValue) => { updateFunction ? updateFunction(newValue, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, newValue) }}
                            value={varVal}
                        />
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        */}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.VideoUpload.type:
                return (
                    <div className="fl-row">
                        <VideoUploader
                            id={controlLabel}
                            currentFile={varVal.srcUrl}
                            fileSelectedCallback={(data) => {if (data.originalEvent !== 'removed') updateFunction ? updateFunction(data.file, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, data.file)}}
                            // removedCallback={() => removeVal(valsArray, index)}
                            showRemoveOption={false}
                            label={controlLabel}
                            labelNote={labelNote}
                        />
                    </div>
                )

            case FEATURE_CONTROLS_TYPES.ImageUpload.type:
                return (
                    <div className="fl-row">
                        <ImageUploader2
                            id={controlLabel}
                            currentFile={varVal.srcUrl}
                            fileSelectedCallback={(data) => { if (data.originalEvent !== 'removed') updateFunction ? updateFunction(data.file, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, data.file) }}
                            // removedCallback={() => removeVal(valsArray, index)}
                            showZoomOption={true}
                            zoomFunction={props.zoomFunction}
                            showRemoveOption={false}
                            showLabel={true}
                            label={controlLabel}
                            labelNote={labelNote}
                        />
                    </div>
                )

            case FEATURE_CONTROLS_TYPES.ImagePreview.type:
                return (
                    <div className="fl-row">
                        <ImageUploader2
                            id={controlLabel}
                            currentFile={getPreviewSrc(varSchema, valsArray, index)}
                            previewOnly={true}
                            label={controlLabel}
                            labelNote={labelNote}
                        />
                    </div>
                )

            case FEATURE_CONTROLS_TYPES.AudioUpload.type:
                return (
                    <div className="fl-row">
                        <AudioUploader
                            id={controlLabel}
                            label={controlLabel}
                            labelNote={labelNote}
                            currentFile={varVal.srcUrl}
                            currentFileName={varVal.filename}
                            fileSelectedCallback={(data) => { if (data.originalEvent !== 'removed') updateFunction ? updateFunction(data.file, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, data.file) }}
                            // removedCallback={() => removeVal(valsArray, index)}
                            showRemoveOption={false}
                        />
                    </div>
                )

            case FEATURE_CONTROLS_TYPES.SubTitle.type:
                return (
                    <div className="var-label">
                        {controlLabel || varVal}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.DescriptionString.type:
                return (
                    <div className="label" dangerouslySetInnerHTML={{__html: labelNote}}>
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.LightDescriptionString.type:
                return (
                    <div className="label light-text" dangerouslySetInnerHTML={{__html: labelNote}}>
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.WarningDescriptionString.type:
                return (
                    <div className="label warning-text" dangerouslySetInnerHTML={{__html: labelNote}}>
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.ConfirmDescriptionString.type:
                return (
                    <div className="label confirm-text" dangerouslySetInnerHTML={{__html: labelNote}}>
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.AttentionDescriptionString.type:
                return (
                    <div className="label attention-text" dangerouslySetInnerHTML={{__html: labelNote}}>
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Spacer.type:
                return (
                    <div className="var-spacer"></div>
                )

            case FEATURE_CONTROLS_TYPES.LineSpacer.type:
                return (
                    <div className="var-spacer-line"></div>
                )

            case FEATURE_CONTROLS_TYPES.Font.type:
                return (
                    <div>
                        <div className="label">{controlLabel}</div>
                        {labelNote != null && labelNote !== '' &&
                            <div className="form-field-content label-note">
                                {labelNote}
                            </div>
                        }
                        <div className="fl-row">
                            <div className="font-val-holder grow"
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        selectFont(varVal, valKey, valsArray, index);
                                    }
                                }
                            >
                                {varVal}
                            </div>
                            <div className="shrink">
                                <button className="standard-button tight" 
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            selectFont(varVal, valKey, valsArray, index);
                                        }
                                    }
                                >
                                    Aa
                                </button>
                            </div>
                        </div>
                    </div>
                )

            case FEATURE_CONTROLS_TYPES.Disruptor.type:
                // console.log('Disruptor type: ', varVal);
                const featureId = varVal ? varVal.split(':')[0] : '';
                const featureData = featureId ? findFeatureData(featureId) : null;
                let boxStyle = 'disruptor';
                if (featureData && LEFT_COLOR_BLOCK_STYLES['disruptor_' + featureData.feature.varKey]) {
                    boxStyle = 'disruptor_' + featureData.feature.varKey;
                }
                if (featureData && LEFT_COLOR_BLOCK_STYLES['disruptor_' + featureData.feature.colourCategory]) {
                    boxStyle = 'disruptor_' + featureData.feature.colourCategory;
                }
                if (featureData && LEFT_COLOR_BLOCK_STYLES['category_' + featureData.feature.colourCategory]) {
                    boxStyle = 'category_' + featureData.feature.colourCategory;
                }

                return (
                    <div className="fl-column compact">
                        {controlLabel !== '' && controlLabel !== null && controlLabel !== undefined && controlLabel !== varVal &&
                            <div className="label">{controlLabel}</div>
                        }
                        {labelNote != null && labelNote !== '' &&
                            <div className="form-field-content label-note">
                                {labelNote}
                            </div>
                        }
                        <div className="fl-row">
                            {(typeof varVal === 'undefined' || featureId === '' || !featureData) &&
                                <button className="standard-button tight"
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            if(varSchema.subType && varSchema.subType == 'leaderboard') 
                                            {
                                                console.log('ADD LEADERBOARD');
                                                selectDisruptor(valKey, valsArray, index, true, varSchema.label);
                                            }
                                            else
                                            {
                                                selectDisruptor(valKey, valsArray, index, false);
                                            }


                                          
                                        }
                                    }
                                >
                                    {varSchema.subType ? '' : 'Select Disruptor'}
                                    {varSchema.subType == 'leaderboard' ? 'Add Leaderboard' : ''}
                                </button>
                            }
                            {varVal && featureData &&
                                <LeftColorBlockBox style={boxStyle}>
                                    <div className="disruptor-column">
                                        <div className='fl-row list-view-content grow'>

                                            <div className="grow">
                                               {featureData.contentLabel}

                                                <span className={`bot-time-dark`}>
                                                    &nbsp;({featureData.feature.featureName})
                                                </span>
                                            </div>

                                            

                                            {!restrictedCustomisation &&
                                                <>
                                                    <div className="edit-icon shrink" onClick={(e) => props.showFeaturePreview(featureData)}>
                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.preview, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                    </div>
                                                    <div className="edit-icon shrink" onClick={(e) => props.customiseFeature(featureId)}>
                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                    </div>
                                                </>
                                            }
                                                    <div className="edit-icon" onClick={(e) => removeDisruptor(featureId, valKey, valsArray, index)}>
                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                    </div>
                                        </div>
                                        
                                        {getFeatureParamControl(varSchema, varVal, valsArray, index, valKey)}
                                    </div>
                                </LeftColorBlockBox>

                            }
                        </div>
                    </div>
                )

                case FEATURE_CONTROLS_TYPES.Quiz.type:
                  // let quizID = '';     
                   

                   const quizID = varVal != undefined && varVal.quiz ? varVal.quiz._id : '';
                    return (
                        <div className="fl-column compact">
                            {controlLabel !== '' && controlLabel !== null && controlLabel !== undefined && controlLabel !== varVal &&
                                <div className="label">{controlLabel}</div>
                            }
                            {labelNote != null && labelNote !== '' &&
                                <div className="form-field-content label-note">
                                    {labelNote}
                                </div>
                            }
                            <div className="fl-row">
                                {(typeof varVal === 'undefined' ||  quizID === '') &&
                                    <button className="standard-button tight"
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                selectQuiz(valKey, valsArray, index);
                                        }}>
                                        Select Quiz
                                    </button>
                                }
                                
                            </div>
                            { quizID != '' &&
                                <LeftColorBlockBox style="quiz">
                                    <div className='fl-row list-view-content'>
                                        <div>
                                            {varVal.quiz.quizName}
                                            <span className={`bot-time-dark`}>
                                                &nbsp;({varVal.quiz.quizType})
                                            </span>
                                        </div>
                                        <div className="grow"></div>
                                        <div className="edit-icon" onClick={(e) => props.customiseQuiz(varVal.quiz._id)}>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                        </div>
                                        <div className="edit-icon" onClick={(e) => removeQuiz(varVal.quiz._id, valKey, valsArray, index)}>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                        </div>
                                    </div>
                                </LeftColorBlockBox>
                            }
                        </div>
                    )

                case FEATURE_CONTROLS_TYPES.ColumnBreak.type:
                    if (props.isPreview) {
                        return (
                            <div className="fl-row purple small-text">
                                <div>--- Column Break ---</div>
                                <div className="grow"></div>
                            </div>
                        )
                    }
                    return null;

                case FEATURE_CONTROLS_TYPES.SectionBreak.type:
                    if (props.isPreview) {
                        return (
                            <div className="fl-row purple small-text">
                                <div>--- Row Break ---</div>
                                <div className="grow"></div>
                            </div>
                        )
                    }
                    return null;

                case FEATURE_CONTROLS_TYPES.CopySettingsButton.type:
                    return (
                        <div className="fl-row">
                            <button className="standard-button tight"
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        console.log('Copy settings: ', varSchema.default, valKey, valsArray, index, fullControlData);
                                        copyVals(varSchema.default, valsArray[index], index, fullControlData?.varName);
                                    }
                                }
                            >
                                {controlLabel}
                            </button>
                        </div>
                    );
            default:
                return (null);
        }
    }

    const getControlBlock = (vals, displaySettings, rootVals = null, objRef = null, varBoxWidthStyle = null, updateFunction = null, highlightKey = '') => {
        if (varBoxWidthStyle === null) {
            varBoxWidthStyle = {};
            varBoxWidthStyle.minWidth = '100%';
            varBoxWidthStyle.maxWidth = '100%';
            varBoxWidthStyle.width = '100%';
            varBoxWidthStyle.display = 'flex';
        }

        const isPreview = (rootVals === null && objRef === null) || props.isPreview;

        console.log('Render control block: ', vals, displaySettings, rootVals, objRef, varBoxWidthStyle, updateFunction, props);

        return (
            <div className="customisation-controls-holder">
                {
                    vals.map(
                        (varVal, varIndex, varArr) => {
                            // console.log('Render var: ', varVal, varIndex, varArr);
                            return (
                                <div className={`cc-column fl-start${displaySettings.forceWidth === 'full' || displaySettings.addBox ? ' grow' : ''}${(displaySettings.addVarBox || displaySettings.allowMultiple) ? ' group-box' : ''}${displaySettings.addVarOutlineBox ? ' group-outline-box' : ''}`} style={{ backgroundColor: (displaySettings.addBox || displaySettings.addVarBox) ? displaySettings.varBoxColour : null, ...varBoxWidthStyle }} key={'var_' + varIndex}>
                                    {
                                        Object.keys(displaySettings.valSchema).map(
                                            (schemaKey, schemaIndex) => {
                                                // console.log('Render schema: ', schemaKey, schemaIndex);

                                                let varSettings = null;
                                                for (let prop2 in FEATURE_CONTROLS_TYPES) {
                                                    if (displaySettings.valSchema[schemaKey] && FEATURE_CONTROLS_TYPES[prop2].type === displaySettings.valSchema[schemaKey].type.toLowerCase()) {
                                                        varSettings = FEATURE_CONTROLS_TYPES[prop2];
                                                    }
                                                }

                                                let displayControl = true;
                                                if (!isPreview) {
                                                    if (!advancedMode && displaySettings.valSchema[schemaKey].advancedMode) {
                                                        displayControl = false;
                                                    }
                                                    if (displayControl && displaySettings.valSchema[schemaKey].displayCondition) {
                                                        displayControl = evaluateDisplayConditions(displaySettings.valSchema[schemaKey].displayCondition, 
                                                            (condVal) => {
                                                                if (typeof (vals[varIndex][condVal.var]) !== 'undefined') {
                                                                    return vals[varIndex][condVal.var]
                                                                } else {
                                                                    // Toggle with an undefined type returns false
                                                                    if (displaySettings.valSchema[condVal.var] && displaySettings.valSchema[condVal.var].type === FEATURE_CONTROLS_TYPES.Toggle.type) {
                                                                        return false;
                                                                    }
                                                                    // Otherwise search for the val elsewhere
                                                                    return findVal(rootVals, condVal.var);
                                                                }
                                                            }
                                                        );
                                                        /* displaySettings.valSchema[schemaKey].displayCondition.forEach(
                                                            (condVal, condI, condArr) => {
                                                                if (displayControl) {
                                                                    const displayConditionVal = typeof (vals[varIndex][condVal.var]) !== 'undefined' ? vals[varIndex][condVal.var] : findVal(rootVals, condVal.var);
                                                                    if (typeof displayConditionVal !== 'undefined') {
                                                                        // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                                                        const evalStr = typeof condVal === 'number' && typeof displayConditionVal === 'number' ?
                                                                            String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                                            "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                                                        displayControl = eval(evalStr);
                                                                    }
                                                                }
                                                            }
                                                        ) */
                                                    }
                                                }

                                                let advancedHighlight = false;
                                                if (isPreview && displaySettings.valSchema[schemaKey].advancedMode) {
                                                    advancedHighlight = true;
                                                }
                                                let displayConditionsHighlight = false;
                                                if (isPreview && displaySettings.valSchema[schemaKey].displayCondition && displaySettings.valSchema[schemaKey].displayCondition.length) {
                                                    displayConditionsHighlight = true;
                                                }
                                                const nodeHighlight = schemaKey === highlightKey;

                                                const highlightUndefined = (isPreview && props.highlightUndefined && typeof varVal[schemaKey] === 'undefined') || false;
                                                const showUndefinedToggle = (isPreview && props.showUndefinedToggle && props.toggleUndefinedFunc && !varSettings.noVal && !varSettings.isCosmetic) || false;
                                                const highlightNoValSet = (isPreview && props.highlightNoValSet && typeof varVal[schemaKey] === 'string' && varVal[schemaKey].length === 0 && !varSettings.noVal && !varSettings.isCosmetic) || false;

                                                return (
                                                    <div className={`customisation-control-holder${displayControl ? ' cc-expanded' : ''}${advancedHighlight ? ' advanced-highlight' : ''}${displayConditionsHighlight ? ' display-conditions-highlight' : ''}${nodeHighlight ? ' node-highlight' : ''}${highlightUndefined ? ' undefined-highlight' : ''}`} key={'valsc_' + schemaIndex}>
                                                        {isPreview &&
                                                            <div className="fl-row grow">
                                                                {showUndefinedToggle &&
                                                                    <div className="shrink">
                                                                        <ToggleCheck currentState={typeof varVal[schemaKey] !== 'undefined'} toggleFunc={(e, id) => { props.toggleUndefinedFunc(varVal, displaySettings.valSchema[schemaKey].default, schemaKey) }} />
                                                                    </div>
                                                                }
                                                                {highlightNoValSet &&
                                                                    <div className="shrink error">
                                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.error, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                                    </div>
                                                                }
                                                                <div className="grow">
                                                                    {getControl(displaySettings.valSchema[schemaKey], typeof varVal[schemaKey] !== 'undefined' ? varVal[schemaKey] : displaySettings.valSchema[schemaKey].default, vals, varIndex, schemaKey, null, updateFunction, displaySettings)}
                                                                </div>
                                                            </div>
                                                        }
                                                        {!isPreview &&
                                                            getControl(displaySettings.valSchema[schemaKey], typeof varVal[schemaKey] !== 'undefined' ? varVal[schemaKey] : displaySettings.valSchema[schemaKey].default, vals, varIndex, schemaKey, null, updateFunction, displaySettings)
                                                        }
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                    {!isPreview && !displaySettings.preventDuplicate && (displaySettings.maxMultiples === -1 || displaySettings.maxMultiples === undefined || vals.length < displaySettings.maxMultiples) && (!props.featureData.isChained || displaySettings.chainedMaxMultiples === undefined || vals.length < displaySettings.chainedMaxMultiples) && displaySettings.allowMultiple &&
                                        <div className={!displaySettings.preventDelete && varArr.length > 1 ? 'copy-icon' : 'del-icon'} onClick={(e) => duplicateVal(vals, varIndex, objRef)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                    }
                                    {!isPreview && !displaySettings.preventDelete && varArr.length > 1 &&
                                        <div className='del-icon' onClick={(e) => removeVal(vals, varIndex, objRef)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                    }
                                </div>
                            )
                        }
                    )
                }
            </div>
        )
    }

    const evaluateDisplayConditions = (displayConditions, getValFunc) => {
        let displayControl = true;
        if (displayConditions && displayConditions.length > 0) {
            // console.log('>>>> EVALING CONDITIONS: ', displayConditions);
            const condArr = displayConditions;
            for (let condI = 0; condI < displayConditions.length; condI++) {
                const condVal = displayConditions[condI];
                // console.log('--->>>> EVALIING CONDITION: ', condVal);
            // displayConditions.forEach(
                // (condVal, condI, condArr) => {
                    if (displayControl) {
                        // We hit an OR, so we can skip the rest of the conditions if we already evaluated to true
                        if (condVal.evalCondition === 'OR' && displayConditions.length > condI + 1) {
                            // console.log('HIT OR! Return ', displayControl);
                            return displayControl;
                        }
                        const displayConditionVal = getValFunc(condVal);
                        if (typeof displayConditionVal !== 'undefined') {
                            // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                            const evalStr = typeof condVal.val === 'number' && typeof displayConditionVal === 'number' ?
                                String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                            // console.log('Eval string: ', evalStr, eval(evalStr));
                            displayControl = eval?.(evalStr);
                        }
                    } else {
                        // We have evaluated to false, but we hit an OR, so we need to reset the display control to true if there are more conditions to evaluate
                        if (condVal.evalCondition === 'OR' && displayConditions.length > condI + 1) {
                            // console.log('HIT OR! Reset displayControl to true and evaluate next block...')
                            displayControl = true;
                        }
                    }
                //}
            // );
            }
        }
        return displayControl;
    }

    if (props.renderSingleControlBlock) {
        return getControlBlock(props.vals, props.displaySettings, props.rootVals, props.objRef, props.varBoxWidthStyle, props.updateFunction, props.highlightKey);
    }

    const restrictedCustomisation = props?.restrictedCustomisation ? true : false ?? false;
    const restrictGroupTo = props?.restrictedCustomisation?.group ? props?.restrictedCustomisation?.group : null;
    const restrictFamilyTo = props?.restrictedCustomisation?.family ? props?.restrictedCustomisation?.family : null;

    return (
        <>
            <div className="customisation-controls-holder no-panel-shadow form-holder">
                {customisationVars.current && controlsData.map(
                    (val, index, arr) => {
                        const rootVals = customisationVars.current[featureId] ? customisationVars.current[featureId] : null;
                        if (val.tabKey === tabId) {
                            return (
                                <div className="customisation-controls-holder" key={'cd_' + index}>
                                    {
                                        val.options.map(
                                            (val2, index2, arr2) => {
                                                if (restrictedCustomisation && restrictGroupTo && restrictGroupTo !== val2.varKey) {
                                                    return null;
                                                }

                                                // console.log('Group data: ', val2);
                                                let displayGroup = true;
                                                if (val2.displayCondition) {
                                                    displayGroup = evaluateDisplayConditions(val2.displayCondition, (condVal) => {return (props.editingTheme ? findVal(props.themeData, condVal.var, null, rootVals) : findVal(rootVals, condVal.var))});
                                                    /* val2.displayCondition.forEach(
                                                        (condVal, condI, condArr) => {
                                                            if (displayGroup) {
                                                                const displayConditionVal = findVal(rootVals, condVal.var);
                                                                if (typeof displayConditionVal !== 'undefined') {
                                                                    // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                                                    const evalStr = typeof condVal === 'number' && typeof displayConditionVal === 'number' ?
                                                                        String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                                        "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                                                    displayGroup = eval(evalStr);
                                                                }
                                                            }
                                                        }
                                                    ) */
                                                }
                                                if (!advancedMode && val2.advancedMode) {
                                                    displayGroup = false;
                                                }

                                                const gWidthStyle = {};
                                                if (typeof val2.forceWidth === 'number') {
                                                    gWidthStyle.width = val2.forceWidth + "%";
                                                    gWidthStyle.minWidth = val2.forceWidth + "%";
                                                    gWidthStyle.maxWidth = val2.forceWidth + "%";
                                                }
                                                if (val2.bgColour) {
                                                    gWidthStyle.backgroundColor = val2.bgColour;
                                                }

                                                const controlGroupStyle = {};
                                                if (typeof val2.padEnd === 'number') {
                                                    controlGroupStyle.paddingRight = val2.padEnd;
                                                }

                                                if (!displayGroup) {
                                                    return null;
                                                }

                                                return (
                                                    <div className={`fl-column fl-equal ${val2.forceWidth === 'full' ? 'fill-width' : (val2.forceWidth === 'min' ? 'shrink-width' : 'group-width')} cc-group-holder${displayGroup ? ' cc-expanded' : ''}`} style={gWidthStyle} key={'c_' + index2}>
                                                        {/* <div className="underlined-panel-heading">
                                                            <h4>{val2.groupTitle}</h4>
                                                            <div className="grow"></div>
                                                            {props.isFullPreview && props.getGroupLayoutSettings &&
                                                                <>{props.getGroupLayoutSettings(val2, index2)}</>
                                                            }
                                                        </div> */}
                                                        {(!val2.variables || val2.variables.length === 0) &&
                                                            <div className="header-group">
                                                                <h4>{val2.groupTitle}</h4>
                                                                {props.isFullPreview && props.addVarFamily && advancedMode &&
                                                                <>
                                                                    <div className="shrink">
                                                                        <div className="smalltext button-active" 
                                                                            onClick={
                                                                                (e) => {
                                                                                    e.preventDefault();
                                                                                    props.addVarFamily(e, index, index2);
                                                                                }
                                                                            }
                                                                        >
                                                                            [ + ]
                                                                        </div>
                                                                    </div>
                                                                    <div className="shrink">
                                                                        <div className="smalltext button-active" 
                                                                            onClick={
                                                                                (e) => {
                                                                                    e.preventDefault();
                                                                                    props.pasteVarFamily(e, index, index2);
                                                                                }
                                                                            }
                                                                        >
                                                                            [ Paste ]
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                }
                                                                <div className="grow"></div>
                                                                {props.isFullPreview && props.getGroupLayoutSettings &&
                                                                    <>{props.getGroupLayoutSettings(val2, index2, index)}</>
                                                                }
                                                            </div>
                                                        }
                                                        {val2.variables && val2.variables.length > 0 &&
                                                        <CollapsibleHeadedSection 
                                                            title={val2.groupTitle} open={val2.startExpanded === true || restrictedCustomisation} canCollapse={!restrictedCustomisation && val2.collapsible === true} 
                                                            extraHeaderContentLeft={
                                                                <>
                                                                {props.isFullPreview && props.addVarFamily && advancedMode &&
                                                                <>
                                                                    <div className="shrink">
                                                                        <div className="smalltext button-active" 
                                                                            onClick={
                                                                                (e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    props.addVarFamily(e, index, index2);
                                                                                }
                                                                            }
                                                                        >
                                                                            [ + ]
                                                                        </div>
                                                                    </div>
                                                                    <div className="shrink">
                                                                        <div className="smalltext button-active" 
                                                                            onClick={
                                                                                (e) => {
                                                                                    e.preventDefault();
                                                                                    props.pasteVarFamily(e, index, index2);
                                                                                }
                                                                            }
                                                                        >
                                                                            [ Paste ]
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                }
                                                                </>
                                                            }
                                                            extraHeaderContentRight={
                                                                <>
                                                                    {props.isFullPreview && props.getGroupLayoutSettings &&
                                                                        <>{props.getGroupLayoutSettings(val2, index2, index)}</>
                                                                    }
                                                                </>
                                                            }
                                                            preHeaderContent={
                                                                val2.collapsible === true ?
                                                                    (<div className="group-icon">
                                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.customisationGroup, IconJsxer.ICON_STYLES.standard)}
                                                                    </div>) : null
                                                            }
                                                            bgColour={val2.headerBgColour}
                                                        >
                                                        <div className="control-group" style={controlGroupStyle}>
                                                            {
                                                                val2.variables.map(
                                                                    (val3, index3, arr3) => {
                                                                        if (restrictedCustomisation && restrictFamilyTo && restrictFamilyTo !== val3.varName) {
                                                                            return null;
                                                                        }

                                                                        // console.log('Getting val: ', val, val2, val3, rootVals);
                                                                        const vals = rootVals ? getValFromObject(rootVals[val.tabKey], val2.varKey, val3.varName) : null;
                                                                        let valsToMap = vals;
                                                                        if (props.editingTheme) {
                                                                            valsToMap = props.themeData?.[tabId]?.[val2.varKey]?.[val3.varName] ?? vals;
                                                                        }
                                                                        const objRef = props.editingTheme ? getObjectRef(props.themeData?.[tabId], val2.varKey, val3.varName) : getObjectRef(rootVals[val.tabKey], val2.varKey, val3.varName);
                                                                        // console.log('ourVals: ', vals);

                                                                        let displaySubGroup = true;
                                                                        if (val3.displayCondition) {
                                                                            displaySubGroup = evaluateDisplayConditions(val3.displayCondition, (condVal) => {return (props.editingTheme ? findVal(props.themeData, condVal.var, val.tabKey, rootVals) : findVal(rootVals, condVal.var, val.tabKey))/*findVal(rootVals, condVal.var, val.tabKey)*/});
                                                                            /* val3.displayCondition.forEach(
                                                                                (condVal, condI, condArr) => {
                                                                                    if (displaySubGroup) {
                                                                                        const displayConditionVal = findVal(rootVals, condVal.var, val.tabKey);
                                                                                        if (typeof displayConditionVal !== 'undefined') {
                                                                                            // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                                                                            const evalStr = typeof condVal === 'number' && typeof displayConditionVal === 'number' ?
                                                                                                String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                                                                "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                                                                            displaySubGroup = eval(evalStr);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            ) */
                                                                        }
                                                                        if (!advancedMode && val3.advancedMode) {
                                                                            displaySubGroup = false;
                                                                        }

                                                                        const vWidthStyle = {marginBottom: displaySubGroup ? '3rem' : 0};
                                                                        if (displaySubGroup && typeof val3.groupWidth === 'number') {
                                                                            vWidthStyle.minWidth = val3.groupWidth + "%";
                                                                            vWidthStyle.maxWidth = val3.groupWidth + "%";
                                                                            vWidthStyle.width = val3.groupWidth + "%";
                                                                            vWidthStyle.display = 'flex';
                                                                        } else
                                                                        if (displaySubGroup && typeof val3.forceWidth === 'number' && (val3.addBox || !val3.allowMultiple)) {
                                                                         //   vWidthStyle.width = val3.forceWidth + "%";
                                                                            vWidthStyle.minWidth = val3.forceWidth + "%";
                                                                            vWidthStyle.maxWidth = val3.forceWidth + "%";
                                                                            vWidthStyle.width = val3.forceWidth + "%";
                                                                            vWidthStyle.display = 'flex';
                                                                        } else if (!val3.addBox) {
                                                                            vWidthStyle.minWidth = '100%';
                                                                            vWidthStyle.maxWidth = '100%';
                                                                            vWidthStyle.width = '100%';
                                                                        }

                                                                        if (typeof val3.padEnd === 'number') {
                                                                            vWidthStyle.paddingRight = val3.padEnd;
                                                                        }

                                                                        const varBoxWidthStyle = {};
                                                                        if (displaySubGroup && typeof val3.forceWidth === 'number' && val3.allowMultiple) {
                                                                            //   vWidthStyle.width = val3.forceWidth + "%";
                                                                            varBoxWidthStyle.minWidth = val3.forceWidth + "%";
                                                                            varBoxWidthStyle.maxWidth = val3.forceWidth + "%";
                                                                            varBoxWidthStyle.width = val3.forceWidth + "%";
                                                                            varBoxWidthStyle.border = "2px dotted #BCA4FF";
                                                                            varBoxWidthStyle.display = 'flex';
                                                                        } else if (val3.addBox  || !val3.allowMultiple) {
                                                                            varBoxWidthStyle.minWidth = '100%';
                                                                            varBoxWidthStyle.maxWidth = '100%';
                                                                            varBoxWidthStyle.width = '100%';
                                                                        }

                                                                        if (!displaySubGroup) {
                                                                            return null;
                                                                        }

                                                                        return (
                                                                            <Fragment key={'col_' + index3}>

                                                                                {val3.displayOnNewRow &&
                                                                                    <div style={{margin: 0, padding: 0, width: "100%", height: "1px"}}></div>
                                                                                }

                                                                                <div className={`${displaySubGroup ? '' : 'cc-var-group-hidden'}${displaySubGroup && val3.forceWidth === 'full' ? ' grow' : ''}`} style={vWidthStyle}>
                                                                                    <div className={`fl-column grow${val3.addBox ? ' group-box' : ''}${val3.addOutlineBox ? ' group-outline-box' : ''}`} style={{ backgroundColor: val3.addBox ? val3.boxColour : null }}>
                                                                                        <div className="fl-row">
                                                                                            <div className="var-label">
                                                                                                {val3.label}
                                                                                            </div>

                                                                                            {val3.description && val3.description !== '' &&
                                                                                                <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(val3.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                                                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                                                                                </div>
                                                                                            }

                                                                                            {!restrictedCustomisation &&
                                                                                                <div className="h-btn button-active" onClick={(e) => props.openResetPresetsPanel(val3, vals, valsUpdatedCallback)}>
                                                                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.magic, IconJsxer.ICON_STYLES.uploaderBtn)}
                                                                                                </div>
                                                                                            }

                                                                                            {(val3.maxMultiples === -1 || val3.maxMultiples === undefined || vals.length < val3.maxMultiples) && (!props.featureData.isChained || val3.chainedMaxMultiples === -1 || val3.chainedMaxMultiples === undefined || vals.length < val3.chainedMaxMultiples) && val3.allowMultiple &&
                                                                                                <div className={`round-button add-button${/*validateLastVal(vals) ? '' : ' add-button-inactive'*/''}`}
                                                                                                    onClick={
                                                                                                        (e) => props.editingTheme ?
                                                                                                            props.addValueToTheme(tabId, val2.varKey, val3.varName, props.themeData?.[tabId]?.[val2.varKey]?.[val3.varName]?.length ?? vals.length, 'ADD_DEFAULTS', null, val3.valSchema)
                                                                                                            : addVal(vals, val3.valSchema)
                                                                                                    }
                                                                                                >
                                                                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                                                                </div>
                                                                                            }

                                                                                            <div className="grow"></div>

                                                                                            {props.isFullPreview && props.getVarFamilyLayoutSettings &&
                                                                                                <>{props.getVarFamilyLayoutSettings(val3, index3, val, val2, index, index2, index3)}</>
                                                                                            }

                                                                                        </div>
                                                                                        <div className="customisation-controls-holder">
                                                                                            {
                                                                                                valsToMap && valsToMap.map(
                                                                                                    (varVal, varIndex, varArr) => {
                                                                                                        const sections = [];
                                                                                                        let columns = [{}];
                                                                                                        Object.keys(val3.valSchema).map(
                                                                                                            (schemaKey, schemaIndex) => {
                                                                                                                if (val3.valSchema[schemaKey].type === FEATURE_CONTROLS_TYPES.SectionBreak.type) {
                                                                                                                    sections.push(columns);
                                                                                                                    columns = [{}];
                                                                                                                } else
                                                                                                                    if (val3.valSchema[schemaKey].type === FEATURE_CONTROLS_TYPES.ColumnBreak.type) {
                                                                                                                        columns.push({});
                                                                                                                    } else {
                                                                                                                        columns[columns.length - 1][schemaKey] = val3.valSchema[schemaKey];
                                                                                                                    }
                                                                                                                return null;
                                                                                                            }
                                                                                                        );
                                                                                                        sections.push(columns);
                                                                                                        // console.log('--- COLUMNS: ', columns, val3.valSchema);

                                                                                                        return (
                                                                                                            <div key={'s_' + varIndex} className={`cc-column fl-start${val3.forceWidth === 'full' || val3.addBox ? ' grow' : ''}${(val3.addVarBox || val3.allowMultiple) ? ' group-box' : ''}${val3.addVarOutlineBox ? ' group-outline-box' : ''}`} style={{ backgroundColor: (val3.addBox || val3.addVarBox) ? val3.varBoxColour : null, ...varBoxWidthStyle }}>
                                                                                                                {
                                                                                                                    sections.map(
                                                                                                                        (colToRender, rowIndex) => {
                                                                                                                            let gotThemeData = false;
                                                                                                                            return (

                                                                                                                                <div className="fl-row column-breaks equal" key={'row_' + rowIndex}>
                                                                                                                                    {
                                                                                                                                        colToRender.map(
                                                                                                                                            (column, columnIndex) => {
                                                                                                                                                return (
                                                                                                                                                    <div className={`cc-column fl-start`} index={"column_" + columnIndex} key={"col_" + columnIndex}>
                                                                                                                                                        {
                                                                                                                                                            Object.keys(column).map(
                                                                                                                                                                (schemaKey, schemaIndex) => {
                                                                                                                                                                    let realVal = varVal[schemaKey];
                                                                                                                                                                    let isPartOfTheme = false;
                                                                                                                                                                    if (props.editingTheme) {
                                                                                                                                                                        // TabKey, FamilyKey, varKey, 
                                                                                                                                                                        isPartOfTheme = typeof (props?.themeData?.[tabId]?.[val2.varKey]?.[val3.varName]?.[varIndex]?.[schemaKey]) != 'undefined' ? true : false ?? false;
                                                                                                                                                                        if (!isPartOfTheme) {
                                                                                                                                                                            realVal = vals?.[varIndex]?.[schemaKey] ?? val3.valSchema[schemaKey].default;
                                                                                                                                                                        } else {
                                                                                                                                                                            gotThemeData = true;
                                                                                                                                                                        }
                                                                                                                                                                    }

                                                                                                                                                                    let displayControl = true;
                                                                                                                                                                    if (!advancedMode && val3.valSchema[schemaKey].advancedMode) {
                                                                                                                                                                        displayControl = false;
                                                                                                                                                                    }
                                                                                                                                                                    if (displayControl && val3.valSchema[schemaKey].displayCondition) {
                                                                                                                                                                        displayControl = evaluateDisplayConditions(val3.valSchema[schemaKey].displayCondition,
                                                                                                                                                                            (condVal) => {
                                                                                                                                                                                if (valsToMap[varIndex] && typeof (valsToMap[varIndex][condVal.var]) !== 'undefined') {
                                                                                                                                                                                    return valsToMap[varIndex][condVal.var]
                                                                                                                                                                                } else
                                                                                                                                                                                    if (vals[varIndex] && typeof (vals[varIndex][condVal.var]) !== 'undefined') {
                                                                                                                                                                                        return vals[varIndex][condVal.var]
                                                                                                                                                                                    } else {
                                                                                                                                                                                        return (props.editingTheme ? findVal(props.themeData, condVal.var, null, rootVals) : findVal(rootVals, condVal.var))/*findVal(rootVals, condVal.var)*/;
                                                                                                                                                                                    }
                                                                                                                                                                            }
                                                                                                                                                                        );
                                                                                                                                                                        /* val3.valSchema[schemaKey].displayCondition.forEach(
                                                                                                                                                                            (condVal, condI, condArr) => {
                                                                                                                                                                                if (displayControl) {
                                                                                                                                                                                    const displayConditionVal = typeof(vals[varIndex][condVal.var]) !== 'undefined' ? vals[varIndex][condVal.var] : findVal(rootVals, condVal.var);
                                                                                                                                                                                    if (typeof displayConditionVal !== 'undefined') {
                                                                                                                                                                                        // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                                                                                                                                                                        const evalStr = typeof condVal === 'number' && typeof displayConditionVal === 'number' ?
                                                                                                                                                                                            String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                                                                                                                                                            "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                                                                                                                                                                        displayControl = eval(evalStr);
                                                                                                                                                                                    }
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        ) */
                                                                                                                                                                    }

                                                                                                                                                                    const varSettings = findFeatureControlTypeData(val3.valSchema[schemaKey].type);
                                                                                                                                                                    const highlightNoValSet = (((typeof varVal[schemaKey] === 'string' && varVal[schemaKey].length === 0) || varVal[schemaKey] === null || typeof varVal[schemaKey] === 'undefined') && !varSettings.noVal && !varSettings.isCosmetic) || false;

                                                                                                                                                                    if (props.editingTheme && !findFeatureControlTypeData(val3.valSchema[schemaKey].type)?.isCosmetic) {
                                                                                                                                                                        return (
                                                                                                                                                                            <div className={`customisation-control-holder${displayControl ? ' cc-expanded' : ''}`} key={'valsc_' + schemaIndex}>
                                                                                                                                                                                <div className="fl-row grow rel">

                                                                                                                                                                                    <div className="theme-check">
                                                                                                                                                                                        <ToggleCheck currentState={isPartOfTheme} toggleFunc={(e, id) => { isPartOfTheme ? props.removeThemeValue(tabId, val2.varKey, val3.varName, varIndex, schemaKey) : props.addValueToTheme(tabId, val2.varKey, val3.varName, varIndex, schemaKey, realVal/* , val3.valSchema[schemaKey] */) }} />
                                                                                                                                                                                    </div>

                                                                                                                                                                                    <div className={`grow theme-control${/*!isPartOfTheme ? ' undefined-highlight disabled-control' : */''}`}>
                                                                                                                                                                                        {getControl(val3.valSchema[schemaKey], props?.themeData?.[tabId]?.[val2.varKey]?.[val3.varName]?.[varIndex]?.[schemaKey] ?? realVal, valsToMap, varIndex, schemaKey, null, (newVal) => { props.addValueToTheme(tabId, val2.varKey, val3.varName, varIndex, schemaKey, newVal, val3.valSchema[schemaKey]) }, val3)}
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        )
                                                                                                                                                                    }

                                                                                                                                                                    return (
                                                                                                                                                                        <div className={`customisation-control-holder${displayControl ? ' cc-expanded' : ''}`} key={'valsc_' + schemaIndex}>
                                                                                                                                                                            <div className="fl-row grow">
                                                                                                                                                                                {highlightNoValSet &&
                                                                                                                                                                                    <div className="shrink error">
                                                                                                                                                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.error, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                                                                                                                                                    </div>
                                                                                                                                                                                }
                                                                                                                                                                                <div className="grow">
                                                                                                                                                                                    {getControl(val3.valSchema[schemaKey], varVal[schemaKey], vals, varIndex, schemaKey, null, null, val3)}
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                    )
                                                                                                                                                                }
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    {(!props.editingTheme || gotThemeData) && !val3.preventDuplicate && (val3.maxMultiples === -1 || val3.maxMultiples === undefined || vals.length < val3.maxMultiples) && (!props.featureData.isChained || val3.chainedMaxMultiples === undefined || vals.length < val3.chainedMaxMultiples) && val3.allowMultiple &&
                                                                                                                                        <div className={!restrictedCustomisation && !val3.preventDelete && varArr.length > 1 ? 'copy-icon' : 'del-icon'} onClick={(e) => duplicateVal(valsToMap, varIndex, objRef)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                    }
                                                                                                                                    {!restrictedCustomisation && (!props.editingTheme || gotThemeData) && !val3.preventDelete && varArr.length > 1 &&
                                                                                                                                        <div className='del-icon' onClick={(e) => removeVal(valsToMap, varIndex, objRef)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                    }
                                                                                                                                </div>

                                                                                                                            )
                                                                                                                        }
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                            {(val3.maxMultiples === -1 || val3.maxMultiples === undefined || vals.length < val3.maxMultiples) && (!props.featureData.isChained || val3.chainedMaxMultiples === -1 || val3.chainedMaxMultiples === undefined || vals.length < val3.chainedMaxMultiples) && val3.allowMultiple &&
                                                                                                <div className={`round-button square-add-button${/*validateLastVal(vals) ? '' : ' add-button-inactive'*/''}`}
                                                                                                    onClick={
                                                                                                        (e) => props.editingTheme ?
                                                                                                            props.addValueToTheme(tabId, val2.varKey, val3.varName, props.themeData?.[tabId]?.[val2.varKey]?.[val3.varName]?.length ?? vals.length, 'ADD_DEFAULTS', null, val3.valSchema)
                                                                                                            : addVal(vals, val3.valSchema)
                                                                                                    }
                                                                                                >
                                                                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.squareAddButton)}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </div>
                                                        </CollapsibleHeadedSection>
                                                        }
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                    {props.isFullPreview && props.addVarGroup && advancedMode &&
                                        <div className="fl-row grow">
                                            <div className="shrink">
                                                <button className="standard-button tight"
                                                    onClick={
                                                        (e) => {
                                                            e.preventDefault();
                                                            props.addVarGroup(e, index);
                                                        }
                                                    }
                                                >
                                                    Add Var Group
                                                </button>
                                            </div>
                                            <div className="fl-row">
                                                <div className="grow">
                                                    <FormDropDown id={"select_groupType"} label="" showLabel={false} showErrors={false} items={props.predefinedGroupDropDownOptions} currentIndex={props.selectedPredefinedGroupIndex} selectFunc={(e, id, item) => props.selectPredefinedGroup(item)} />
                                                </div>
                                                <div className="shrink">
                                                    <button className="standard-button tight" onClick={(e) => { props.addPredefinedCustomisationGroup(e, index); }}>Add Predefined Group</button>
                                                </div>
                                            </div>
                                            <div className="grow"></div>
                                        </div>
                                    }
                                </div>
                            )
                        }
                        return null;
                    }
                )}
            </div>
        </>
    );
}

export default CustomisationControls;
